.hamburger-nav,
.search-nav {
	display: none;
	position: fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	z-index: $z_index-level6;
	background:rgba($color-grey-dark, 0.9);
}

.nav-open .hamburger-nav {
	display: block;
}

.search-open .search-nav {
	display: block;
}


.hamburger-nav-inner {
	height: calc(100% - 64px);
	margin-top:64px;
	overflow: auto;
	display: block;
	position: relative;
}



.hamburger-nav-inner-nav {
	margin:0;
	list-style: none;
	padding:0 16px;
	li {
		font-size:2em;
		line-height: 1;
		padding:0 0 0 40px;
		font-weight: $weight-bold;
		text-align: left;
		margin:40px 0 0 0;
		position: relative;

		a {
			display: inline-block;
			color:#fff;
			&:hover {
				text-decoration: none;
				transition: color 0.2s ease;
				//color: $color-primary;
			}
		}	
	}
}

.hamburger-nav-inner-nav .sub-menu {
	display: none;
	padding:0;
	margin:0;
	li {
		font-size:18px;
		line-height: 32px;
		padding:0 0 0 16px;
		margin:8px 0 0 0;
		font-weight: $weight-reg;
		&:first-of-type {
			margin-top:16px;
		}
	}
}

.sub-menu-toggle {
	position: absolute;
	width:32px;
	height:32px;
	left:0;
	top:0px;
	cursor: pointer;
	background: transparent;
	&:after,
	&:before {
		content:"";
		position: absolute;
		top:50%;
		left:50%;
		transform: translate(-50%, -50%);
		background:#fff;
		
	}
	&:after {
		width:2px;
		height:24px;
		transition: height 0.2s ease;
	}
	&:before {
		height:2px;
		width:24px;
	}
	&.is-active:after {
		height:2px;
	}
}

#search.search-form {
	padding:0 16px 0 56px;
	max-width: 400px;
	margin:40px 0 0 0;
	border: none;
	input {
		font-size: 24px;
		background:rgba(255,255,255,0.2);
		line-height: 48px;
		height:48px;
	}
	.submit {
		border-radius: 0;
		height:48px;
		padding:12px 16px;
	}
}

.search-nav-search {
	max-width: 1200px;
	margin:40px auto 0 auto;
	padding:0 16px;
	@include respond($bp-md) {
		padding:0 80px;
		margin:80px auto 0 auto;
	}
}

.search-form {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom:2px solid #fff;
	padding-bottom:14px;
	.field {
		width:auto;
		flex: 1 1 auto;
		background: transparent;
		padding:0 16px;
		margin: 0;
		font-size:2em;
		line-height: 2;
		color: #fff;
		max-width: calc(100% - 64px);
		@include respond($bp-md) {
			font-size:4em;	
			line-height: 1;
		}
		&::placeholder {
			opacity: 1;
		}
	}
}

.submit {
	cursor: pointer;
	flex: 0 0 auto;
	height:64px;
	width:64px;
	padding:20px;
	border-radius: 50%;
	background:$color-primary;
	transition: background 0.2s ease;
	svg * {
		fill: #fff;
	}
	&:hover {
		background:rgba($color-primary, 0.7);
	}
}