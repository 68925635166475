.homeSolutions {
   .section-title {
       text-align: center;
       margin: 0 auto 24px auto;
       max-width: 1120px;
       span {
           font-weight: $weight-reg;
       }
   
       
   }
   
   .section-subtitle {
       text-align: center;
       margin: 0 auto 40px auto;
       max-width: 1020px;
        padding-right: 20px;
       padding-left: 20px;
   
       @include respond($bp-padding) {
            margin: 0 auto 64px auto;
       }
   }
   
   
   .container {
       max-width: 1388px;
       padding: 0 24px;
   }
}

.home .homeSolutions {
  padding:  80px 0;
   @include respond($bp-padding) {
       padding: 112px 0 112px 0;
   }
}

.homeSolutions-grid {
    position: relative;
    padding-left: 400px;
    @include respond(840px, "down") {
        padding-left: 240px;
    }
    @include respond(580px, "down") {
        padding-left: 0;   
    }
}

.homeSolutions-nav {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 340px;
    @include respond(840px, "down") {
        width: 200px;   
    }
    @include respond(580px, "down") {
     display: none;
    }
}

.homeSolutions-nav-inner {
    position: sticky;
    padding: 0;
    top: 50%;
    transform: translateY(-50%);

}

.homeSolutions-nav-title {
    padding: 24px 0 24px 24px;
    font-size: 20px;
    font-weight: 600;
    color: #666;
    display: block;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        width: 2px;
        top: 0;
        left: 0;
        background-color: #ccc;
        height: 100%;
    }
    &:hover,
    &.active {
        text-decoration: none;
        color: $color-purple;
        &:before {
          background-color: $color-purple;
        }        
    }
    
    
    @include respond(840px, "down") {
        font-size: 16px;
        padding: 16px 0 16px 24px;   
    }
}

.home .homeSolutions-nav-title {
  color: #fff;
  &:before {
    background-color: #fff;
  }
  &.active,
  &:hover {
    color: $color-purple;
  }
  &.active:before {
    background-color: $color-purple;
  }
}

#title_strategy-creative:hover,
#title_strategy-creative.active {
  color: $color-purple;
}

#title_strategy-creative.active:before {
 background-color: $color-purple;
}


#title_omnichannel-activation:hover,
#title_omnichannel-activation.active {
  color: $color-purple;
}

#title_omnichannel-activation.active:before {
  background-color: $color-purple;
}

.home {
  #title_enabling-technology:hover,
  #title_enabling-technology.active {
    color: $color-purple;
  }
  #title_enabling-technology.active:before {
    background-color: $color-purple;
  }
}

#Freight-Optimization {
	@include min(1200px) {
		background-position-y: 160%;
	}
}

.homeSolutions-block {
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
    width: 100%;
    height: 50vh;    
    min-height: 400px;
    max-height: 500px;
    padding: 140px 0 0 0;
    margin: 0 0 160px 0;
    position: relative;
    @include respond($bp-md) {
      padding: 140px 0;
    }
    &:last-child {
        margin: 0;
    }
    
    .homeSolutions-block-title {

        color: #fff;
        background: rgba(0,0,0,0.9);
        padding: 8px 70px 8px 24px;
        bottom: 0;
        left: 0;
        width: 100%;
        position: absolute;        
        h3 {
            font-size: 18px;
            font-weight: 600;
            line-height: 32px;
            margin: 0 0 0 0;
            
        }
        span {
            font-size: 16px;
            display: block;
            line-height: 24px;
            margin-top: 4px;
            font-weight: 500;
            padding-bottom: 8px;
        }
    }
  @include respond(580px, "down") {
      margin: 0 0 32px 0;  
      background-size: auto 300px;
      background-position: center top;
      background-color: #000;
      padding-top: 300px;
      max-height: none;
      height: auto;
      padding-bottom: 32px;
      .homeSolutions-block-title {
        padding: 16px 0 0 0;
        position: static;
      }
     
  }
}


.home .homeSolutions-block {
  h3 {
    transition: color 0.4s ease;
  }

    &:before {
        content:"";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: background 0.4s ease;
        background: rgba(0,0,0,0.05);
    }
    &:after {
      display: none;
        
    }
    &:hover:before {
        background: rgba(0,0,0,0.3);
    }   
    &:hover {
      .homeSolutions-block-title h3{
        color: $color-purple;
      }
    }
}
.page-strategy-creative-services .homeSolutions,
.page-creative-design .homeSolutions,
.page-enabling-technology .homeSolutions,
.page-omnichannel-activation .homeSolutions,
.page-supply-chain-management .homeSolutions {
  .homeSolutions-block {
    padding-left: 16px;
    padding-right: 16px;
    @include respond($bp-md, "down") {
      padding-bottom: 8px;
    }
  }
}
