.white-cover {
    // display: none;
    background-color: #fff;
    z-index: 997;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0px;
    left: 0px;

    // @include respond(970px) {
    //     display: block;
    // }
}

.splash-intro {
    position: fixed;
    z-index: 999;
    background-color: #fff;
    display: none;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &.active {
        display: block;
        opacity: 1;
    }

    &.is-complete {
        opacity: 0;
        pointer-events: none;
        transition: opacity 1.3s linear;
        -moz-transition: opacity 1.3s linear;
        -webkit-transition: opacity 1.3s linear;
        -o-transition: opacity 1.3s linear;
    }

    video {
        position: absolute;
        max-width: 850px;
        min-height: 100vh;
        width: auto;
        height: auto;
        position: absolute;
        top: 28%;
        left: 50.25%;
        transform: translate(-50%, -50%);

        @include min(600px) {
            max-width: 1100px;
        }


        @include min($bp-md) {
            max-width: 1350px;
            top: 49%;
        }


        @include respond($bp-lg) {
            max-width: 1750px;
        }
    }


}


.splash-intro-mb {
    position: fixed;
    z-index: 999;
    background-color: #fff;
    display: none;
    transition: none;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &.active {
        display: block;
        opacity: 1;
    }

    &.is-complete {
        opacity: 0;
        pointer-events: none;
        transition: opacity 1.3s linear;
        transition: opacity 1.3s linear;
        -moz-transition: opacity 1.3s linear;
        -webkit-transition: opacity 1.3s linear;
        -o-transition: opacity 1.3s linear;
    }

    video {
        position: absolute;
        max-width: 370px;
        min-height: 100%;
        width: auto;
        height: auto;
        position: absolute;
        // top: 60%;

        transform: translate(-50%, -50%);

        @include respond(380px) {
            // max-width: 370px;
        }

        @include respond(475px) {
            max-width: 500px;
            top: 44%;
            left: 48.25%;
        }

        @include respond(600px) {
            max-width: 600px;
        }
    }
}