.search-results-wrapper {
	max-width: 800px;
	padding:0 16px;
	@include respond($bp-md) {
		padding:0 32px;	
	}
}

.search-result {
	margin:0 0 32px 0;
	padding:0 0 32px 0;
	border-bottom:1px solid #ccc;
	a {
		font-size:1.25em;
		font-weight: $weight-bold;
		display: inline-block;
		margin:0 0 8px 0;
	}
}

.search-result-meta {
	margin:0;
	font-size:1em;
}


.pagination {
	display: flex;
	align-items: center;
	justify-content: space-between;
	.hidden {
		visibility: hidden;
	}
	
	a {
		@extend .btn;
		@extend .btn-green;
		padding:8px 16px;
		svg {
			margin-right: 8px;
			display: block;
			width:12px;
			height:12px;
		}
		&.next svg {
			margin-left:8px;
			margin-right:0;
		}
		svg * {
			fill:#fff;
		}
	}
}