// -----------------------------------------------------------------------------
//! Mixin
// -----------------------------------------------------------------------------

@mixin symmetrical-layout($breakpoint, $columns) {
    @include min($breakpoint) {
        display: grid;
        grid-template-columns: repeat($columns, minmax(0, 1fr));
    }
}

// -----------------------------------------------------------------------------
//! Symmetrical layouts
// -----------------------------------------------------------------------------

.grid-v2 {
    display: grid;
    grid-template-columns: 100%;
}

.sm-2s {
    @include symmetrical-layout($bp-sm, 2);
}

.sm-3s {
    @include symmetrical-layout($bp-sm, 3);
}

.sm-4s {
    @include symmetrical-layout($bp-sm, 4);
}

.md-2s {
    @include symmetrical-layout($bp-md, 2);
}

.md-3s {
    @include symmetrical-layout($bp-md, 3);
}

.md-4s {
    @include symmetrical-layout($bp-md, 4);
}

.lg-2s {
    @include symmetrical-layout($bp-lg, 2);
}

.lg-3s {
    @include symmetrical-layout($bp-lg, 3);
}

.lg-4s {
    @include symmetrical-layout($bp-lg, 4);
}

.xl-2s {
    @include symmetrical-layout($bp-xl, 2);
}

.xl-3s {
    @include symmetrical-layout($bp-xl, 3);
}

.xl-4s {
    @include symmetrical-layout($bp-xl, 4);
}

.xl-5s {
    @include symmetrical-layout($bp-xl, 5);
}

// ----------------------------------------o-------------------------------------
//! Parent modifiers
// -----------------------------------------------------------------------------

.grid-v2.align-start {
    align-items: start;
}

// -----------------------------------------------------------------------------
//! Children modifiers
// -----------------------------------------------------------------------------

.gw-100 {
    grid-column: 1 / -1;
}

@include min($bp-lg) {
    .lg-order-last {
        order: 10;
    }
}

// -----------------------------------------------------------------------------
//! Gaps
// -----------------------------------------------------------------------------

@include attr-x("gap", "gap", 4, 30);
@include attr-x("gap-x", "column-gap", 4, 30);
@include attr-x("gap-y", "row-gap", 4, 30);

@include min($bp-md) {
    @include attr-x("md-gap", "gap", 4, 30);
    @include attr-x("md-gap-x", "column-gap", 4, 30);
    @include attr-x("md-gap-y", "row-gap", 4, 30);
}

@include min($bp-xl) {
    @include attr-x("lg-gap", "gap", 4, 30);
    @include attr-x("lg-gap-x", "column-gap", 4, 30);
    @include attr-x("lg-gap-y", "row-gap", 4, 30);
}
