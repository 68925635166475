.landingGrid {
	padding:40px 32px;
	display: flex;
	align-items: stretch;
	justify-content: center;
	flex-wrap: wrap;
	max-width: 1320px;
	margin:0 auto;
	
	&:first-child {
		padding-top:0;	
	}
}

.section-heading + .landingGrid {
	padding-top:32px;
}

.landingGrid + .section-heading {
	margin-top:40px;
}

.landingGrid-header {
	text-align: center;
	flex: 0 0 100%;
	width: 100%;
	-ms-flex: 0 1 auto;
	padding:0 0 32px 0;
	@include respond(900px) {
		padding:0 56px 40px 6px;
		flex:0 0 50%;
		-ms-flex: 0 1 auto;
		width: 50%;
		text-align: left;
	}
	@include respond(1200px) {
		flex: 1 1 33.333333334%;
		-ms-flex: 0 1 auto;
		width: 33.333333334%;	
	}
}

.landingGrid-title {
	font-size: 2em;
	line-height: calc(40/32);
	margin:0 0 16px 0;
	@include respond(900px) {
		font-size: 2.5em;
		line-height: calc(56/40);	
	}
}

.landingGrid-text {
	margin:0;
	font-size: 1.125em;
	line-height: calc(32/18);
}

.landingGrid-main {
	
	
	display: block;
	flex: 0 0 100%;
	width: 100%;
	-ms-flex: 0 1 auto;
	padding:0 0 32px 0;
	@include respond(900px) {
		flex:0 0 50%;
		-ms-flex: 0 1 auto;
		width: 50%;
		padding:0 6px 40px 0;
		max-width: 816px;
	}
	@include respond(1200px) {
		flex:0 0 66.666666666%;
		-ms-flex: 0 1 auto;
		width: 66.666666666%;	
	}
}

.landingGrid-main-image {
	height:204px;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	display: block;
	border-radius: 4px;
	@include respond($bp-md) {
		height: 0;
		padding-top: (320 / 816) * 100%;
	}
}

.landingGrid-main-title {
	font-size: 1.5em;
	line-height: calc(32/24);
	margin:16px 0 8px 0;
	padding:0 16px;
}

.landingGrid-main-text {
	padding:0 16px;
	margin:0;
	font-size: 1em;
	line-height: 1.5;
}





.landing-block {
	display: block;
	padding:16px 8px;
	opacity: 0;
	
	position: relative;
	transform: translateY(40px);
	transition: opacity 0.2s ease, transform 0.2s ease, margin 0.2s ease;
	&.is-animated {
		opacity: 1;
		transform: translateY(0);
	}
}

.landing-block {
	width: 100%;
	flex: 0 0 100%;
	-ms-flex: 0 1 auto;
	@include respond($bp-md) {
		width: 50%;
		flex: 0 0 50%;
		-ms-flex: 0 1 auto;	
	}
	@include respond(900px) {
		width: 33.3333333333%;
		flex: 0 0 33.3333333333%;
		-ms-flex: 0 1 auto;
	}
	@include respond($bp-lg) {
		width: 25%;
		flex: 0 0 25%;
		-ms-flex: 0 1 auto;
	}
}

.landing-block-image {
	background-color: rgba($color-body, 0.1);
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	height:204px;
	display: block;
	border-radius: 4px;
	transition: opacity 0.2s ease;
	@include respond($bp-md) {
		height: 0;
		padding-top: (204 / 302) * 100%;
		
	}
}
	

.landing-block-inner {
	height:100%;
	position: relative;
	overflow: hidden;
	text-decoration: none;
	&:hover {
		text-decoration: none;
		.landing-block-image {
			opacity: 0.75;
		}
		h3 {
			color: $color-primary;
		}
	}

}

.landing-block-info {
	padding:0 8px;
	position: relative;
	top:0;
	left:0;
	width:100%;
	bottom:0;
	text-align: left;
	@include respond($bp-xl) {
		padding:0 16px;	
	}
}

.landing-block-info-header {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	transition: margin 0.2s ease;
	padding:16px 32px 0 0;
	position: relative;
	h3 {
		color: $color-body;
		font-size: 1.125em;
		line-height: calc(28/18);		
		margin: 0 0 8px 0;
		transition: color 0.2s ease;
		@include respond($bp-xl) {
			font-size: 1.25em;
			line-height: calc(28/20);	
		}
		
		.has-arrows {
			position: relative;
			display: inline-block;
			padding-right:34px;
		}
	}
	.landing-arrow-start {
			position: absolute;
			right:16px;
			top: 8px;
			height:13px;
			width:9px;
			opacity: 1;
			transition: opacity 0.2s ease;
			* {
				fill:rgba($color-body,1);
			}
		}
	.landing-block-arrows {
		position: absolute;
		right:0;
		top:8px;
		width:28px;
		height:13px;
		
		.landing-arrow {
			position: absolute;			
			opacity: 0;
			top:0;
			right:8px;
			&:first-of-type {
				right:16px;	
			}
			&:last-of-type {
				right:0;
				opacity: 0;				
			}
			* {
				fill:rgba($color-primary,1);
				transition: fill 0.2s ease;
			}
		}
	}
	
}


.landing-block:hover {
	.landing-arrow-start {
		opacity: 0;
	}
}