.accolade {
	padding:0;
	.container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		// border: 1px solid #7f8f3a;
		max-width: 360px;
		margin: 0 auto;
		padding: 16px;
		gap: 16px;
	}
}

.accolade-image {
	flex: 0 0 56px;
	svg {
		display: block;
	}
}

.accolade-text {
	font-size: 14px;
	line-height: calc(21/14);
	font-weight: bold;
	text-align: left;
	color: $color-body;
}
