$bp-form: 900px;

.section-heading.contact {
    max-width: 910px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px 100px;

	@include respond(840px) {
        flex-wrap: nowrap;
    }

    img,
    .section-subtitle {
        flex: 1;
    }

    .section-subtitle {
        text-align: center;
        margin-top: 30px;
        margin-bottom: 0;
        font-size: 20px;
        font-weight: 700;
        @include respond(840px) {
            font-size: 38px;
            line-height: 54px;
            text-align: left;
            margin-top: 0;
            position: relative;
            top: 10px;
        }
    }
    .contact-right {
        max-width: 530px;
    }
    .contact-left {
        display: flex;
        justify-content: center;
        // border: 1px solid red;
        @include respond($bp-md) {
            position: relative;
            @include respond(840px) {
                &:after {
                    content: "";
                    background-color: #000;
                    position: absolute;
                    width: 3px;
                    height: 81px;
                    top: calc(50% + 11px);
                    transform: translateY(-50%);
                    left: calc(100% + 49px);
                    display: block;
                }
            }
        }
    }
    img {
        // text-align: left;
        max-width: 200px;
        height: auto;
        position: relative;
        // @include respond($bp-md) {
        //     max-width: 260px;
        // }
    }
}
.home .section-heading.contact {
    padding-top: 0px;
    padding-bottom: 0px;
    margin: 80px auto;
}
.form-wrapper {
    max-width: 1400px;

    padding: 0 8px;
    @include respond($bp-md) {
        margin-top: 80px;
    }
    @include respond($bp-form) {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 0;
    }
}

.pre-contact-title {
    max-width: 740px;
    margin: 0 auto;
    text-align: center;
    font-weight: 500;
    line-height: 1.4;
    font-size: 22px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 40px;

    @include respond($bp-md) {
        line-height: 1.4;
        font-size: 26px;
        margin-bottom: 0;
    }
}

.form-wrapper-left {
    padding: 0 8px;
    @include respond($bp-form) {
        padding: 0;
        max-width: 800px;
        flex: 1 1 auto;
        -ms-flex: 0 1 auto;
    }
}

.form-wrapper-right {
    padding-top: 32px;
    padding-left: 8px;
    padding-right: 8px;
    @include respond($bp-form) {
        min-width: 400px;
        max-width: 600px;
        flex: 1 1 600px;
        -ms-flex: 0 1 auto;
        padding-left: 64px;
        padding-right: 16px;
        padding-top: 0;
    }
}

.contact-block {
    padding: 40px 24px;
    border-radius: 4px;
    margin: 0 0 40px 0;
    border: 1px solid $color-primary;
    
	&.no-border {
        border: none;
        padding: 0 24px;
    }
}

.contact-block-address {
    display: inline-flex;
    gap: 20px;
    flex-wrap: wrap;

    img {
        max-width: 120px;
        height: 100%;
    }

    p {
        margin-top: 0;
    }
}

.confirmation-text {
    text-align: center;
}

.apply-link {
    text-decoration: underline;
    display: block;
    margin-bottom: 16px;
    font-size: 1.125em;
}

.contact-block-title {
    font-size: 1.25em;
    line-height: calc(24 / 20);
    font-weight: $weight-bold;
    margin: 0 0 16px 0;
}

.gform_wrapper {
    padding: 24px 8px;
    border: 1px solid $color-primary;
    border-radius: 4px;
    @include respond($bp-form) {
        padding: 4px 20px;
    }
}
.gform_fields {
    list-style: none;
    margin: 0;
    padding: 0;
    @include respond($bp-md) {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
}

// .gform_fields {
// 	list-style: none;
// 	margin:0;
// 	padding:4px 20px;
// 	border:1px solid $color-primary;
// 	border-radius: 4px;
//
// 	@include respond($bp-md) {
// 		display: flex;
// 		align-items: stretch;
// 		justify-content: flex-start;
// 		flex-wrap: wrap;
//
// 	}
// }

.gfield,
.gform_footer {
    padding: 10px 16px;
    margin: 0;
    @include respond($bp-md) {
        padding: 10px 8px;
        width: 50%;
        flex: 0 0 50%;
        -ms-flex: 0 1 auto;
        &.full {
            width: 100%;
            flex: 0 0 100%;
        }
    }
}
.gform_footer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
}

.gfield input,
.gfield select,
.gfield textarea {
    //background:#C4C4C4;
    width: 100%;
    border-radius: 4px;
    //border:1px solid #c4c4c4;
    background: #eee;
    font-family: $font-body;
    &:focus {
        border-color: $color-primary;
    }
}
.gfield textarea {
    padding: 16px;
    font-size: 1.125em;
    line-height: calc(32 / 18);
    font-family: $font-body;
    height: 154px;
    resize: vertical;
}
.gfield input,
.gfield select {
    height: 60px;
    line-height: 60px;
    padding: 0 16px;
    font-size: 1.125em;
    font-family: $font-body;
    &.error {
        border-color: tomato;
    }
}

.gfield select {
    box-shadow: none;
    -webkit-appearance: none;
    background-image: url(../svg/icon-arrow.svg);
    background-repeat: no-repeat;
    background-position: right 16px center;
}

.gfield label.gfield_label {
    font-size: 0.875em;
    font-weight: $weight-reg;
    line-height: calc(24 / 14);
    margin: 0 0 16px 0;
    text-transform: uppercase;
    display: block;
}

.gform_validation_container {
    display: none;
}

.gform_button {
    @extend .btn;
    @extend .btn-green;
}

label.error {
    padding: 4px 0;
    color: tomato;
    display: block;
}

.gfield_error {
    input,
    select,
    textarea {
        border-color: tomato;
    }
    .gfield_description.validation_message {
        color: tomato;
        font-style: italic;
        font-size: 0.875em;
        display: block;
        margin: 8px 0 0 0;
    }
}

body img.gform_ajax_spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 40px;
    width: 40px;
    //background:darken($color-primary, 10%);
}

.form-submit {
    width: 100%;
    padding: 20px 8px;
    text-align: left;
}

.validation_error {
    text-align: center;
    padding: 16px;
}
