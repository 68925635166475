.grid {
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	justify-content: center;
	margin:0 -16px;
}

.block {
	display: block;
	padding:16px;
	opacity: 0;
	transform: translateY(40px);
	transition: opacity 0.2s ease, transform 0.2s ease;
	&.is-animated {
		opacity: 1;
		transform: translateY(0);
	}
}
.block-inner {
	height:100%;
	position: relative;
	overflow: hidden;
}

.block-image {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	position: relative;
	background-color: $color-grey-dark;
	display: block;
	transition: opacity 0.2s ease;
}

a.block-image:hover {
	opacity: 0.8;

}

.block-info {
	//background:rgba(255,255,255,0.75);
	padding:0 24px;
	position: relative;
	top:0;
	left:0;
	width:100%;
	bottom:0;
	text-align: center;
	//transform: translateY(calc( 100% - 80px ));
	transition: transform 0.2s ease;
	@include respond($bp-md) {
	//	transform: translateY(calc( 100% - 160px ));	
	}
	@include respond($bp-xl) {
		padding:0 40px;	
	}
}

.block-inner:hover .block-info {
	transform: translateY(0);
	.block-info-header {
		@include respond($bp-md, "down") {
			margin-top:40px;
		}
	}
}

.block-info-header {
	//height:80px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: margin 0.2s ease;
	padding:24px 0 0 0;
	h2 {
		font-size: 1.125em;
		line-height: calc(24/18);		
		margin:0 0 16px 0;
		@include respond($bp-xl) {
			font-size: 1.5em;
			line-height: calc(32/24);	
		}
	}
}

.block-info-content {
	padding:0 0 24px 0;
}

.block-info-text {
	margin-bottom:24px;
	font-size:1.125em;
	@include respond($bp-xl, "down") {
		font-size:1em;
		line-height: 1.5;
	}
}

.block-bottom {
	text-align: center;
	padding:32px;
	h2 {
		margin:0;
	}
}

// -----------------------------------------------------------------------------
//! Sizes
// -----------------------------------------------------------------------------

.grid-3 { 
	.block {
		width: 100%;
		flex: 0 0 100%;
		-ms-flex: 0 1 auto;
		@include respond($bp-md) {
			width: 50%;
			flex: 0 0 50%;
			-ms-flex: 0 1 auto;	
		}
		@include respond(1200px) {
			width: 33.3333333333%;
			flex: 0 0 33.3333333333%;
			-ms-flex: 0 1 auto;
		}
		
	}
	
	.block-image {
		height: 460px; 
		@include respond($bp-lg) {
			height: 534px;	
		}
		@include respond($bp-xl) {
			height: 700px; 	
		}
	}
}

.grid-4 { 
	.block {
		width: 100%;
		flex: 0 0 100%;
		-ms-flex: 0 1 auto;
		@include respond($bp-md) {
			width: 50%;
			flex: 0 0 50%;
			-ms-flex: 0 1 auto;	
		}
		@include respond(1200px) {
			width: 33.3333333333%;
			flex: 0 0 33.3333333333%;
			-ms-flex: 0 1 auto;
		}
		@include respond($bp-xl) {
			width: 25%;
			flex: 0 0 25%;
			-ms-flex: 0 1 auto;
		}
	}
	.block-image {
		height: 460px; 
	}	
}

.grid-5 {
	.block {
		width: 100%;
		flex: 0 0 100%;
		-ms-flex: 0 1 auto;
		@include respond($bp-md) {
			width: 50%;
			flex: 0 0 50%;
			-ms-flex: 0 1 auto;	
		}
		@include respond(1200px) {
			width: 33.3333333333%;
			flex: 0 0 33.3333333333%;
			-ms-flex: 0 1 auto;
		}
		@include respond($bp-xl) {
			width: 20%;
			flex: 0 0 20%;
			-ms-flex: 0 1 auto;
		}
	}
	.block-image {
		height:460px;	
	}	
	.block-inner {
		height:460px;
	}
	.block-info-content {
		height:100%;
		padding-top:80px;
	}
	
	.block-info {
		padding:0 32px;
		transform: translateY(100%);
	}
	.block-info-text {
		font-size:1.25em;	
	}
}

.grid-constrained {
	max-width: 1600px;
	margin:0 auto;
	.block {
		.block-image {
			height: 460px; 
			@include respond($bp-lg) {
				height: 534px;	
			}
		}
	}
}