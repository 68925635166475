.brandActivation {
	padding: 0 32px 40px 32px;	
	max-width: 1320px;
	margin:0 auto;
	@include respond($bp-lg) {
		padding: 40px 32px 120px 32px;	
	}
	
	
	
	@include respond(900px) {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		gap: 40px;
	}
}

.brandActivation-title {
	margin:0;
	text-transform: capitalize;
	color:$color-body;
		font-size:3em;
		line-height: calc(56/48);	
	flex: 1 1 860px;
	max-width: 860px;
	@include respond($bp-md) {
		font-size:5em;
		line-height: calc(72/64);
	}
	@include respond($bp-lg) {
		font-size:6em;
		line-height: calc(96/80);	
	}
	@include respond($bp-xl) {
		font-size:6.25em;
		line-height: calc(128/100);	
	}
	
	span {
		display: block;
	}
}

.brandActivation p {
	@include respond(900px) {
		max-width: 356px;
	}
}