.content {
	max-width: 1200px;
	padding:0 16px;
	@include respond($bp-sm) {
		padding:0 32px;
	}
	&.full-width {
		max-width: none;
		.container {
			max-width: 1200px;
		}
	}
}