// -----------------------------------------------------------------------------
//! SIZING
// -----------------------------------------------------------------------------

	html { 
		font-size: 16px; 
	}
	body { 
		font-size: 1em;
		font-family: $font-body;  
		color: $color-body;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	

// -----------------------------------------------------------------------------
//! HEADINGS
// -----------------------------------------------------------------------------

	@mixin heading-reset() {
		text-transform: none;
		font-weight: normal;
		font-style: normal;
		font-family: inherit;
		color: inherit;
		letter-spacing: 0;
		margin: 0;
		padding: 0;
	}

	h1, h2, h3, h4, h5, h6 { 
		font-family: $font-body; 
		line-height: 1; 
		font-weight: $weight-xbold;
		margin:1em 0;
		&:first-child {
			margin-top: 0;
		}
	}
	h2 {
		font-weight: 700;
	}
	h3 {
		font-weight: 700;
	}
	
// -----------------------------------------------------------------------------
//! COPY
// -----------------------------------------------------------------------------

	p { 
		line-height: 1.5;
		margin: 1em 0; 
		font-size:1.25em;
		font-family: $font-body;
		font-weight: $weight-light;
		&:first-child {
			margin-top: 0;
		}
	}

	strong {
		font-weight: $weight-bold;
	}
	
	button {
		cursor: pointer;
		font-family: $font-body;
	}

	a {
		color: $color-primary;
		text-decoration: none;
		transition: all 0.2s ease;
		&:hover { 
			text-decoration: underline;
			transition: all 0.2s ease; 
		}
	}

	ul,
	ol {
		margin:1em 0;
		padding:0 0 0 24px;
		li {
			padding:0 0 0 8px;
			margin:0 0 8px 0;
			line-height: 1.5;
			position: relative;
		}
	}
	
	.plainText li {
		font-size:1.25em;
		font-weight: $weight-light;
	}