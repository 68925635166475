.offices {
	max-width: 1400px;
	padding:0 16px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
	margin-top:64px;
	@include respond($bp-md) {
		padding:0 32px;
		margin-top:120px;
	}
}

.office {
	padding:20px;
	width:100%;
	flex: 0 0 100%;
	-ms-flex: 0 1 auto;
	text-align: center;
	@include respond(400px) {
		width:50%;
		flex: 0 0 50%;
		-ms-flex: 0 1 auto;
		text-align: left;
	}
	@include respond($bp-md) {
		padding:20px 40px;
	}
	@include respond($bp-lg) {
		width:33.3333333333%;
		flex: 0 0 33.3333333333%;
		-ms-flex: 0 1 auto;
	}
	@include respond(1200px) {
		width:25%;
		flex: 0 0 25%;
		-ms-flex: 0 1 auto;
	}
}

.office-title {
	font-size:1.25em;
	line-height: calc(32/20);
	margin:0 0 16px 0;
	@include respond($bp-sm) {
		font-size:1.5em;
		line-height: calc(32/24);	
	}
}

.office-info {
	font-size:0.875em;
	line-height: calc(24/14);
	margin:0;
	
	@include respond($bp-sm) {
		font-size:1.125em;
		line-height: calc(32/18);	
	}
}