.modal-trigger {
	cursor: pointer;
	
	&:hover {
		img {
/*
			transform: scale(1.05);
			z-index: 2;		
			box-shadow: 0 0 8px rgba(0,0,0,0.2);	
*/
		}

	}
}

.modal-overlay {
	background: $color-body;
	opacity: 0.8;
	position: fixed;
	top:0;
	left:0;
	bottom:0;
	right:0;
	z-index: $z_index-level6 + 10;
	display: none;
}

.modal {
	position: fixed;
	top:0;
	left:0;
	bottom:0;
	right:0;
	z-index: $z_index-level6 + 11;
	
	display: none;
}

.modal-inner {
	height:100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal-wrap {
	position: relative;
	max-width: 80%;
}

.modal-inner img {
	max-width: 100%;
	max-height: 80vh;
	height:auto;
	width:auto;
	position: relative;
	z-index: $z_index-level6 + 12;
}
.modal-close {
	position: absolute;
	top:-16px;
	right:-16px;
	z-index: $z_index-level6 + 13;
	background: $color-primary;
	width:40px;
	height:40px;
	border-radius: 50%;
	cursor: pointer;
	padding:0;
	transition: background 0.2s ease;
	&:hover {
		background: darken($color-primary, 10%);
	}
	svg {
		height:16px;
		width:16px;
	}
	svg * {
		fill: #fff;
	}
}