$bp-mason: 700px;

.masonry {
	padding:40px 32px 80px 32px;
}

.section-heading + .masonry {
	padding-top:32px;
}


.masonry-grid {
	max-width: 1256px;
	margin:0 auto;
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

.masonry-item{
	padding:4px;
	img {
		display: block;
	}
}

.masonry-sizer {
	width:50%;
}
.masonry-item-25 {
	width:50%;
}

.masonry-item-50 {
	width:100%;
}


@include respond($bp-mason) {
	.masonry-sizer {
		width:25%;
	}
	.masonry-item-25 {
		width:25%;
	}
	
	.masonry-item-50 {
		width:50%;
	}
}


.masonry-item img {
	opacity: 0;
	position: relative;
	top:80px;
	transition: opacity 0.8s ease 0.2s, top 0.8s ease 0.2s, transform 0.2s ease, box-shadow 0.2s ease;
}


.masonry-item.is-animated img {
	opacity: 1;
	top:0;
}