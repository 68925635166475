.values {
	padding-bottom: 80px;
	@include respond($bp-padding) {
		padding-bottom: 160px;
	}
	.container {
		max-width: 1388px;
		padding: 0 24px;
		display: grid;
		grid-template-columns: 1fr;
		gap: 24px 40px;
		@include respond(660px) {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
		@include respond($bp-lg) {
			gap: 24px 100px;
		}
		@include respond($bp-xl) {
			gap: 24px 160px;
		}
	}
	
	.values-box {
		opacity: 0;
		transform: translateY(40px);
	}
	.values-title {
		font-weight: 300;
		position: relative;
		&:after {
			content: "";
			position: absolute;
			top: calc(100% + 0.6em);
			left: 0;
			width: 272px;
			height: 2px;
			background: #AF4EFB;
		}
		@include respond($bp-lg) {
			font-size: 50px;
			margin: 0 0 50px 0;
		}
	}
}