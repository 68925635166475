@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=0d9d4ff0-6f5d-4e9a-95a3-68d7137b2e98");
@font-face {
  font-family: "Avenir Next";
  src: url("../fonts/a80347f9-6450-460c-8cd9-d38d8cfd392e.woff2") format("woff2"), url("../fonts/b773c7d4-8353-42fa-9bd7-a7aaca7291d1.woff") format("woff");
  font-weight: 200;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../fonts/670c4af6-0f8f-4dfa-89d7-0213a1f77b7d.woff2") format("woff2"), url("../fonts/b25f4dd5-5498-47ad-bf13-ed8d085f0ace.woff") format("woff");
  font-weight: 300;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../fonts/c4691e94-080d-4452-8f9f-8b3db6b68d55.woff2") format("woff2"), url("../fonts/a1adabe6-efe5-40b7-b619-48111c8ff883.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../fonts/f07f5ab2-8ecc-416d-afa7-b73dbea95907.woff2") format("woff2"), url("../fonts/3a03e3a3-baa3-4079-aa61-d2ec2a35bea3.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../fonts/6afe4676-059a-4aa8-b891-29856bbcba22.woff2") format("woff2"), url("../fonts/a9817c16-2a79-49bf-8543-6c846d73a52f.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Avenir Next";
  src: url("../fonts/b56355b0-7729-42a7-9f65-62c7211d078c.woff2") format("woff2"), url("../fonts/05537afc-e923-4df9-b365-71798fbd220d.woff") format("woff");
  font-weight: 700;
}
html {
  height: 100%;
  background: #fff;
  overflow-x: hidden;
}

body {
  position: relative;
  min-height: 100%;
}

.container {
  margin: 0 auto;
}

.contain {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.pad-x {
  padding-left: 24px;
  padding-right: 24px;
}

.main {
  padding: 80px 0;
  background: #fff;
}

.home .main {
  padding: 0;
}

.page-about .main {
  padding-top: 120px;
}

.page-strategy-creative-services .main,
.page-creative-design .main,
.page-enabling-technology .main,
.page-omnichannel-activation .main,
.page-supply-chain-management .main {
  padding-bottom: 0;
}

section.is-black {
  background: #000;
}
section.is-black p, section.is-black h1, section.is-black h2, section.is-black h3, section.is-black h4, section.is-black h5 {
  color: #fff;
}

html, body,
header, footer,
h1, h2, h3, h4, h5, h6, hr, p,
ul, ol, li, img,
fieldset, input, textarea, select {
  margin: 0;
  padding: 0;
}

html, body {
  min-height: 100%;
}

body {
  -webkit-text-size-adjust: none;
}

main, article, section, aside {
  display: block;
}

:focus {
  outline: none;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

@media screen {
  .print-only {
    display: none !important;
  }
}
@media print {
  .no-print {
    display: none !important;
  }
}
a img, button, input, textarea {
  border: 0;
}

img, object, embed {
  max-width: 100%;
  height: auto;
}

img {
  -ms-interpolation-mode: bicubic;
}

#map_canvas img, .map_canvas img {
  max-width: none !important;
}

iframe {
  border: 0;
}

html {
  box-sizing: border-box;
}
html * {
  box-sizing: inherit;
}
html :before,
html :after {
  box-sizing: inherit;
}

.alt {
  display: none;
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}

.unlist {
  margin: 0;
  padding: 0;
  list-style: none;
}
.unlist li {
  list-style: inherit;
}
.unlist ul,
.unlist ol {
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
}

body {
  font-size: 1em;
  font-family: "Avenir Next", Arial, Helvetica, Sans-serif;
  color: #252525;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Avenir Next", Arial, Helvetica, Sans-serif;
  line-height: 1;
  font-weight: 400;
  margin: 1em 0;
}
h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
  margin-top: 0;
}

h2 {
  font-weight: 700;
}

h3 {
  font-weight: 700;
}

p {
  line-height: 1.5;
  margin: 1em 0;
  font-size: 1.25em;
  font-family: "Avenir Next", Arial, Helvetica, Sans-serif;
  font-weight: 300;
}
p:first-child {
  margin-top: 0;
}

strong {
  font-weight: 400;
}

button {
  cursor: pointer;
  font-family: "Avenir Next", Arial, Helvetica, Sans-serif;
}

a {
  color: #000;
  text-decoration: none;
  transition: all 0.2s ease;
}
a:hover {
  text-decoration: underline;
  transition: all 0.2s ease;
}

ul,
ol {
  margin: 1em 0;
  padding: 0 0 0 24px;
}
ul li,
ol li {
  padding: 0 0 0 8px;
  margin: 0 0 8px 0;
  line-height: 1.5;
  position: relative;
}

.plainText li {
  font-size: 1.25em;
  font-weight: 300;
}

.about-top {
  padding: 0 32px;
  max-width: 1336px;
  margin: 0 auto;
}
@media print, screen and (min-width: 768px) {
  .about-top {
    padding: 0 32px;
    align-items: center;
    justify-content: space-between;
  }
}
@media print, screen and (min-width: 1150px) {
  .about-top {
    display: flex;
  }
}

.about-top h2 {
  font-weight: 300;
  margin: 0;
  font-size: 1.375rem;
  line-height: 1.8181818182;
}
.about-top h2 span {
  color: #000;
  font-weight: normal;
  font-size: 2.5rem;
  line-height: 1.1;
  margin: 0 0 16px 0;
  display: block;
}
@media screen and (max-width: 767px) {
  .about-top h2 {
    text-align: center;
  }
}
@media print, screen and (min-width: 864px) {
  .about-top h2 {
    font-size: 1.625rem;
    line-height: 1.5;
  }
  .about-top h2 span {
    font-size: 3rem;
    line-height: 1.1666666667;
    margin: 0 0 16px 0;
  }
}

.about-top-left {
  flex: 1 1 auto;
  -ms-flex: 0 1 auto;
  padding: 0 0 40px 0;
}
@media print, screen and (min-width: 1150px) {
  .about-top-left {
    padding: 0 60px 0 0;
  }
}

.about-top-right {
  flex: 1 1 224px;
  -ms-flex: 0 1 auto;
  position: relative;
  min-width: 224px;
  margin: 0 auto;
  max-width: 224px;
}
.about-top-right img {
  display: block;
  border-radius: 5px;
}
@media print, screen and (min-width: 768px) {
  .about-top-right {
    margin: 0;
    max-width: 300px;
    min-width: 0;
  }
}
@media print, screen and (min-width: 864px) {
  .about-top-right {
    flex: 0 0 224px;
  }
}

.top-cards {
  flex: 1 0 692px;
  display: flex;
  gap: 50px;
  flex-direction: column;
}
@media print, screen and (min-width: 768px) {
  .top-cards {
    flex-direction: row;
    gap: 10px;
  }
}

.namecard {
  position: absolute;
  background: #fff;
  left: 16px;
  right: 16px;
  bottom: -16px;
  padding: 8px 16px;
  border: 1px solid #000;
}

.namecard-name {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.3333333333;
  margin: 0 0 4px 0;
}

.namecard-title {
  font-weight: 300;
  font-size: 0.9rem;
}

.about-section-title {
  text-align: center;
  padding: 0 32px;
  margin: 0 0 32px 0;
}
@media print, screen and (min-width: 864px) {
  .about-section-title {
    margin: 0 0 64px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.about-section-title .section-title {
  font-size: 1.5rem;
  line-height: 1.3333333333;
  margin: 24px 0 0 0;
}
@media print, screen and (min-width: 864px) {
  .about-section-title .section-title {
    margin: 0 0 0 24px;
    padding: 6px 0 6px 24px;
    border-left: 2px solid #252525;
  }
}

.about-grid {
  padding: 0 32px;
  max-width: 1336px;
  margin: 0 auto;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.about-grid .bio {
  padding: 8px;
  width: 100%;
  flex: 0 0 100%;
  -ms-flex: 0 1 auto;
}
@media print, screen and (min-width: 480px) {
  .about-grid .bio {
    width: 50%;
    flex: 0 0 50%;
    -ms-flex: 0 1 auto;
  }
}
@media print, screen and (min-width: 768px) {
  .about-grid .bio {
    width: 33.3333333333%;
    flex: 0 0 33.3333333333%;
    -ms-flex: 0 1 auto;
  }
}
@media print, screen and (min-width: 1024px) {
  .about-grid .bio {
    width: 25%;
    flex: 0 0 25%;
    -ms-flex: 0 1 auto;
    padding: 12px;
  }
}

.bio-inner {
  height: 100%;
}

.flip-card {
  background-color: transparent;
  width: 100%;
  height: 0;
  padding-top: 120%;
  perspective: 1000px;
}

.flip-card-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  color: #252525;
  border-radius: 4px;
}
.flip-card-front img {
  border-radius: 4px;
}

.flip-card-back {
  background-color: #000;
  color: #fff;
  transform: rotateY(180deg);
  overflow-y: auto;
  border-radius: 4px;
}

.flip-card-back-inner {
  padding: 16px;
}

@media print, screen and (min-width: 1024px) {
  .about-top-grid .flip-card-back-inner {
    padding: 40px;
  }
}
.about-top-grid .flip-card-back-inner .flip-card-stat-text {
  font-size: 1rem;
  line-height: 1.25;
}
.about-top-grid .flip-card-back-inner .flip-card-stat {
  margin: 0 0 10px 0;
}
.about-top-grid .flip-card-back-inner .flip-card-job {
  margin: 0 0 20px 0;
}
.about-top-grid .flip-card-back-inner .flip-card-title {
  font-size: 1.25rem;
}

.flip-card-title {
  font-size: 1.125rem;
  line-height: 1.2;
  font-weight: 400;
  margin: 0 0 4px 0;
}

.flip-card-job {
  font-size: 0.875rem;
  line-height: 16px;
  margin: 0 0 12px 0;
}

.flip-card-bio {
  font-size: 0.875rem;
  line-height: 1.7142857143;
  margin: 0;
}
@media print, screen and (min-width: 1024px) {
  .flip-card-bio {
    font-size: 1.125rem;
    line-height: 1.7777777778;
  }
}

.flip-card-stat {
  margin: 0 0 8px 0;
}

.flip-card-stat-title {
  font-size: 0.75rem;
  font-weight: 400;
  margin: 0 0 4px 0;
}

.flip-card-stat-text {
  font-size: 0.8125rem;
  line-height: 1.2307692308;
  font-weight: 400;
}

.about-stats {
  max-width: 400px;
  padding: 0 32px;
  margin: 64px auto;
}
@media print, screen and (min-width: 560px) {
  .about-stats {
    margin: 128px auto 128px auto;
    max-width: 1000px;
  }
}

.about-stats-desktop {
  display: none;
}
@media print, screen and (min-width: 560px) {
  .about-stats-desktop {
    display: block;
  }
}

.about-stats-mobile {
  display: block;
}
@media print, screen and (min-width: 560px) {
  .about-stats-mobile {
    display: none;
  }
}

.page-about .main {
  padding-bottom: 0;
}

.about-join {
  padding: 0 0 80px 0;
  text-align: center;
}
@media print, screen and (min-width: 1024px) {
  .about-join {
    padding: 0 0 160px 0;
  }
}
.about-join .container {
  max-width: 800px;
  padding: 0 24px;
}
.about-join .container .section-title {
  margin: 0 0 40px 0;
}

.accolade {
  padding: 0;
}
.accolade .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 360px;
  margin: 0 auto;
  padding: 16px;
  gap: 16px;
}

.accolade-image {
  flex: 0 0 56px;
}
.accolade-image svg {
  display: block;
}

.accolade-text {
  font-size: 14px;
  line-height: 1.5;
  font-weight: bold;
  text-align: left;
  color: #252525;
}

.altblocks {
  padding: 80px 0;
}

.altblock {
  margin-bottom: 120px;
}
.altblock:last-of-type {
  margin-bottom: 0;
}
@media print, screen and (min-width: 860px) {
  .altblock {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}
.altblock:nth-of-type(even) .altblock-content {
  order: 2;
}

.altblock-content {
  padding: 16px;
}
@media print, screen and (min-width: 560px) {
  .altblock-content {
    padding: 24px 32px;
  }
}
@media print, screen and (min-width: 860px) {
  .altblock-content {
    width: 50%;
    flex: 0 0 50%;
    -ms-flex: 0 1 auto;
  }
}
@media print, screen and (min-width: 1024px) {
  .altblock-content {
    padding: 24px 40px;
  }
}
@media print, screen and (min-width: 1500px) {
  .altblock-content {
    padding: 24px 80px;
  }
}
@media print, screen and (min-width: 1600px) {
  .altblock-content {
    padding: 24px 180px;
  }
}

.altblock-image {
  opacity: 0;
  transform: translateY(80px);
  margin: 32px 0 0 0;
  padding: 0 16px;
  transition: all 0.8s ease;
}
@media print, screen and (min-width: 560px) {
  .altblock-image {
    padding: 0 32px;
  }
}
@media print, screen and (min-width: 860px) {
  .altblock-image {
    margin: 0;
    width: 50%;
    flex: 0 0 50%;
    -ms-flex: 0 1 auto;
    padding: 0;
  }
}
.altblock-image img {
  display: block;
}

.is-animated .altblock-image {
  opacity: 1;
  transform: translateY(0);
}

.altblock-title {
  font-size: 2em;
  line-height: 1.25;
  position: relative;
  padding-bottom: 16px;
  border-bottom: 8px solid #000;
  margin: 0;
}
@media print, screen and (min-width: 768px) {
  .altblock-title {
    font-size: 2.5em;
    line-height: 1.2;
  }
}

.altblock-text {
  padding: 24px 0;
}
@media print, screen and (min-width: 1500px) {
  .altblock-text {
    padding: 32px 0;
  }
}
.altblock-text p {
  font-size: 1em;
  line-height: 1.5;
}
@media print, screen and (min-width: 768px) {
  .altblock-text p {
    font-size: 1.125em;
    line-height: 1.7777777778;
  }
}

.brandActivation {
  padding: 0 32px 40px 32px;
  max-width: 1320px;
  margin: 0 auto;
}
@media print, screen and (min-width: 1024px) {
  .brandActivation {
    padding: 40px 32px 120px 32px;
  }
}
@media print, screen and (min-width: 900px) {
  .brandActivation {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 40px;
  }
}

.brandActivation-title {
  margin: 0;
  text-transform: capitalize;
  color: #252525;
  font-size: 3em;
  line-height: 1.1666666667;
  flex: 1 1 860px;
  max-width: 860px;
}
@media print, screen and (min-width: 768px) {
  .brandActivation-title {
    font-size: 5em;
    line-height: 1.125;
  }
}
@media print, screen and (min-width: 1024px) {
  .brandActivation-title {
    font-size: 6em;
    line-height: 1.2;
  }
}
@media print, screen and (min-width: 1500px) {
  .brandActivation-title {
    font-size: 6.25em;
    line-height: 1.28;
  }
}
.brandActivation-title span {
  display: block;
}

@media print, screen and (min-width: 900px) {
  .brandActivation p {
    max-width: 356px;
  }
}

.btn, .pagination a, .gform_button {
  transition: background 0.2s ease, color 0.2s ease;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: "Avenir Next", Arial, Helvetica, Sans-serif;
  font-size: 1.125em;
  font-weight: normal;
  line-height: 1.3333333333;
  padding: 16px 48px;
  font-weight: 400;
}
.btn:hover, .pagination a:hover, .gform_button:hover {
  text-decoration: none;
}

.btn-green, .pagination a, .gform_button,
.btn-black {
  background: #000;
}
.btn-green:hover, .pagination a:hover, .gform_button:hover,
.btn-black:hover {
  background-color: black;
}

.btn-white {
  color: #252525;
  background: #fff;
}
.btn-white:hover {
  background-color: rgba(255, 255, 255, 0.75);
}

.btn-outline {
  color: #fff;
  background: transparent;
  border: 1px solid #fff;
}
.btn-outline:hover {
  background: #fff;
  color: #000;
}

.button-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px 24px;
}
@media print, screen and (min-width: 480px) {
  .button-row {
    gap: 16px 80px;
  }
}

.page-work .hero-media:after {
  background-color: rgba(255, 255, 255, 0.8);
}
.page-work .main {
  padding-top: 80px;
  position: relative;
  min-height: 100%;
  background-size: cover;
}
.page-work .homeSolutions {
  padding-top: 0;
}

.case-grid {
  position: relative;
  z-index: 25;
  padding: 0 24px;
  max-width: 1200px;
  display: grid;
  align-items: strech;
  gap: 48px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  margin: 0 auto;
}
@media screen and (max-width: 1023px) {
  .case-grid {
    gap: 40px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media screen and (max-width: 767px) {
  .case-grid {
    grid-template-columns: 1fr;
  }
}
.case-grid .case-grid-box-text-holder {
  background: #fff;
}
.case-grid .case-grid-box-text-holder .case-grid-box-text {
  color: #000;
}

.home .case-grid .case-grid-box-text-holder {
  background: #000;
  z-index: 2;
}
.home .case-grid .case-grid-box-text-holder .case-grid-box-text {
  color: #fff;
}

.home .case-grid-box-text {
  text-align: center;
  max-width: 280px;
  margin: 0 auto;
}
.home .case-grid-box-text strong {
  font-size: 21px;
  font-weight: 600;
}
.home .case-grid-box-text p {
  margin-top: 4px;
  font-size: 19px;
}

.solutionsWork .case-grid .case-grid-box-text-holder {
  z-index: 2;
}

.home .case-grid .case-grid-box:hover .case-grid-box-text-holder .case-grid-box-text,
.solutionsWork .case-grid .case-grid-box:hover .case-grid-box-text-holder .case-grid-box-text {
  color: #AF4EFB;
}

.home .case-grid .case-grid-box:after,
.solutionsWork .case-grid .case-grid-box:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity 0.4s ease;
  background: rgba(0, 0, 0, 0.3);
}

.home .case-grid .case-grid-box:hover:after,
.solutionsWork .case-grid .case-grid-box:hover:after {
  opacity: 1;
}

.case-grid-box {
  display: flex;
  flex-direction: column;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  position: relative;
  text-decoration: none !important;
}
@media screen and (max-width: 559px) {
  .case-grid-box {
    height: auto;
  }
}
.case-grid-box.color-hover {
  filter: saturate(0%);
}
.case-grid-box.color-hover:hover, .case-grid-box.color-hover:focus {
  filter: none;
}
.case-grid-box:hover .case-grid-box-text {
  color: #AF4EFB;
}

.case-grid-box-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 32px;
  background: rgba(0, 0, 0, 0.9);
  left: 0;
  right: 0;
  transition: opacity 0.4s ease;
}

.case-grid-box-title {
  color: #fff;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  text-decoration: none !important;
}

.case-grid-box-text {
  text-decoration: none !important;
  color: #fff;
  font-size: 14px;
  line-height: 17px;
  transition: color 0.4s ease;
  font-weight: 600;
}
@media print, screen and (min-width: 768px) {
  .case-grid-box-text {
    font-size: 16px;
    line-height: 24px;
    max-width: 100%;
  }
}

.case-grid-image {
  padding-top: 85.2272727273%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.case-grid-box-text-holder {
  position: relative;
  background: #fff;
  flex-grow: 1;
}

.case-grid-page-title {
  color: #fff;
  font-size: 50px;
  line-height: 120%;
  margin: 50px;
  transition: color 0.4s ease;
}

.case-grid-box-text-holder {
  background-color: #000;
  position: relative;
  width: 100%;
  padding: 24px 0 0 0;
}

.case-grid-box-logo {
  position: absolute;
  top: 40%;
  left: 100px;
}
.case-grid-box-logo img {
  max-width: 150px;
  max-height: 150px;
}

.case-grid-box:hover .case-grid-box-overlay {
  opacity: 0;
}

.single-casestudies .main {
  position: relative;
  padding: 100px 32px 100px 32px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 100vh;
}
@media print, screen and (min-width: 560px) {
  .single-casestudies .main {
    padding: 160px 32px 100px 32px;
  }
}
@media print, screen and (min-width: 1200px) {
  .single-casestudies .main {
    padding: 160px 0 100px 32px;
  }
}
@media print, screen and (min-width: 1500px) {
  .single-casestudies .main {
    padding: 160px 0 100px 120px;
  }
}
.single-casestudies .main .container {
  gap: 40px;
  position: relative;
  z-index: 2;
  min-height: calc(100vh - 260px);
}
@media print, screen and (min-width: 1200px) {
  .single-casestudies .main .container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}
@media print, screen and (min-width: 1500px) {
  .single-casestudies .main .container {
    gap: 120px;
  }
}

.caseInfo {
  flex: 1 1 630px;
  max-width: 630px;
}
@media print, screen and (min-width: 1200px) {
  .caseInfo {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 260px);
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.caseInfo-title {
  font-size: 2em;
  line-height: 1.25;
  margin: 0 0 32px 0;
  position: relative;
}
@media print, screen and (min-width: 560px) {
  .caseInfo-title {
    font-size: 3em;
    line-height: 1.1666666667;
    margin: 0 0 40px 0;
  }
}
@media print, screen and (min-width: 768px) {
  .caseInfo-title {
    font-size: 3.5em;
    line-height: 1.1428571429;
    margin: 0 0 48px;
  }
}
.caseInfo-title img {
  max-width: 300px;
  max-height: 160px;
  width: auto;
  height: auto;
}

.caseInfo-services {
  margin: 0;
  font-size: 1.5em;
  line-height: 1.3333333333;
}
@media print, screen and (min-width: 768px) {
  .caseInfo-services {
    font-size: 2em;
    line-height: 1.25;
  }
}
@media print, screen and (min-width: 1024px) {
  .caseInfo-services {
    font-size: 40px;
    line-height: 50px;
  }
}

.caseInfo-bar {
  width: 130px;
  height: 8px;
  margin: 0 0 24px 0;
}

.caseInfo-stats {
  margin: 32px 0 0 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
}
@media screen and (max-width: 399px) {
  .caseInfo-stats {
    gap: 20px;
  }
}

.caseInfo-stats-row {
  flex: 1 1 auto;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  max-width: 150px;
  text-align: center;
}
@media screen and (max-width: 399px) {
  .caseInfo-stats-row {
    max-width: 100%;
    width: 100%;
    flex: 0 0 100%;
    text-align: left;
  }
}
@media print, screen and (min-width: 1500px) {
  .caseInfo-stats-row {
    font-size: 18px;
    line-height: 24px;
  }
}
.caseInfo-stats-row span {
  display: block;
  font-weight: 700;
  font-size: 32px;
  line-height: 1;
  position: relative;
  top: 3px;
  margin-bottom: 16px;
}
@media print, screen and (min-width: 1024px) {
  .caseInfo-stats-row span {
    font-size: 48px;
  }
}
@media print, screen and (min-width: 1500px) {
  .caseInfo-stats-row span {
    font-size: 50px;
  }
}

.caseInfo-quote {
  margin: 20px 0 32px 0;
}

.caseInfo-quote-person {
  margin: 24px 0 0 0;
}

.caseInfo-quote-person-name {
  font-weight: 700;
}

.caseInfo-quote-person-title {
  padding-left: 8px;
  font-size: 15px;
}

.caseInfo-about {
  margin-top: auto;
  padding: 24px;
  width: 100%;
}
.caseInfo-about p {
  color: #fff;
  font-size: 14px;
  line-height: 21px;
}
.caseInfo-about p a {
  color: #fff;
  text-decoration: underline;
}
.caseInfo-about.is-dark p {
  color: #000;
}
.caseInfo-about.is-dark p a {
  color: #000;
}

.caseImg {
  flex: 1 1 auto;
  padding: 40px 0;
}
@media print, screen and (min-width: 1200px) {
  .caseImg {
    padding: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }
}
.caseImg img {
  display: block;
}

.csNav {
  z-index: 100;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: #fff;
  padding: 16px 40px;
}
.csNav .slick-slide > div {
  height: 68px;
  padding: 0 16px;
}
.csNav .slick-slide > div img {
  max-height: 48px;
  max-width: 100%;
  width: auto;
  height: auto;
  display: block;
  margin: 0 auto;
}
.csNav .csNav-slide {
  height: 68px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.csNav .slick-list {
  overflow: visible;
}
.csNav .is-current {
  position: relative;
}
.csNav .is-current:before {
  content: "";
  position: absolute;
  bottom: calc(100% + 16px);
  left: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 8px 10px;
  border-color: transparent transparent #ffffff transparent;
  transform: translateX(-50%);
}

.csNav-slider-arrow {
  width: 40px;
  background: #000;
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 102;
}
.csNav-slider-arrow.prev-arrow {
  left: 0;
}
.csNav-slider-arrow.prev-arrow svg {
  transform: rotate(180deg);
}
.csNav-slider-arrow.next-arrow {
  right: 0;
}
.csNav-slider-arrow svg * {
  fill: #fff;
}

.services-used {
  font-size: 16px;
  line-height: 24px;
}
.services-used strong {
  font-weight: 700;
}
@media print, screen and (min-width: 768px) {
  .services-used {
    font-size: 23px;
    line-height: 32px;
  }
}

.caseInfo-quote-text {
  font-size: 14px;
  line-height: 1.5;
}
@media print, screen and (min-width: 768px) {
  .caseInfo-quote-text {
    font-size: 17px;
    line-height: 27px;
  }
}

.homeWork {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  background-color: #fff;
  padding: 80px 0 80px 0;
}
@media print, screen and (min-width: 1024px) {
  .homeWork {
    padding: 112px 0 112px 0;
  }
}
.homeWork .section-title {
  text-align: center;
  margin: 0 auto 24px auto;
  max-width: 1010px;
}
.homeWork .section-title span {
  font-weight: 400;
}
.homeWork .section-subtitle {
  font-size: 20px;
  text-align: center;
  margin: 0 auto 30px auto;
  padding-right: 20px;
  padding-left: 20px;
}
@media print, screen and (min-width: 1024px) {
  .homeWork .section-subtitle {
    margin: 0 auto 64px auto;
  }
}
.homeWork .case-grid-image {
  padding-top: 85.2272727273%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.homeWork .case-grid-box-text-holder {
  position: relative;
}

.solutionsWork {
  padding: 80px 0;
}
@media print, screen and (min-width: 1024px) {
  .solutionsWork {
    padding: 200px 0;
  }
}
.solutionsWork h2 {
  font-size: 40px;
  text-align: center;
  margin-bottom: 45px;
}
@media print, screen and (min-width: 768px) {
  .solutionsWork h2 {
    font-size: 50px;
    margin-bottom: 55px;
  }
}
.solutionsWork .case-grid-box-text-holder {
  background: #fff;
}
.solutionsWork .case-grid-box-text-holder .case-grid-box-text {
  color: #000;
}
.solutionsWork .case-grid-image {
  padding-top: 85.2272727273%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.solutionsWork .case-grid-box-text-holder {
  position: relative;
}
.solutionsWork .case-grid-box {
  text-decoration: none !important;
}

.conclusion {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  padding: 120px 16px 120px 16px;
  position: relative;
}
.conclusion:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 200px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
}
@media print, screen and (min-width: 1024px) {
  .conclusion {
    padding: 120px 32px 120px 32px;
  }
}
.conclusion .container {
  max-width: 1200px;
  position: relative;
  z-index: 2;
}

.conclusion-title {
  text-align: center;
}

.conclusion-grid {
  margin: 40px 0 0 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
}
@media print, screen and (min-width: 768px) {
  .conclusion-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 80px 40px;
  }
}
@media print, screen and (min-width: 900px) {
  .conclusion-grid {
    gap: 120px 80px;
  }
}
@media print, screen and (min-width: 1024px) {
  .conclusion-grid {
    gap: 120px;
  }
}

.conclusion-pre {
  margin-bottom: 8px;
}

.conclusion-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #252525;
  padding-top: 100%;
  height: 0;
  margin: 0 0 24px 0;
}

.conclusion-box-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin: 0 0 16px 0;
}
@media print, screen and (min-width: 768px) {
  .conclusion-box-title {
    font-size: 24px;
    line-height: 32px;
  }
}

.conclusion-box .btn, .conclusion-box .pagination a, .pagination .conclusion-box a, .conclusion-box .gform_button {
  display: inline-block;
}

.conclusion-box {
  opacity: 0;
  transform: translateY(80px);
}
@media screen and (max-width: 767px) {
  .conclusion-box {
    display: grid;
    grid-template-columns: 200px 1fr;
    gap: 24px;
  }
}
@media screen and (max-width: 559px) {
  .conclusion-box {
    grid-template-columns: 108px 1fr;
    gap: 16px;
  }
}

.content {
  max-width: 1200px;
  padding: 0 16px;
}
@media print, screen and (min-width: 560px) {
  .content {
    padding: 0 32px;
  }
}
.content.full-width {
  max-width: none;
}
.content.full-width .container {
  max-width: 1200px;
}

.cutoutText {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
}

.cutoutText-text {
  padding: 40px 24px 80px;
  background: #fff;
  font-weight: 600;
  mix-blend-mode: screen;
  font-size: 48px;
}
@media print, screen and (min-width: 768px) {
  .cutoutText-text {
    font-size: 60px;
  }
}
@media print, screen and (min-width: 1024px) {
  .cutoutText-text {
    font-size: 80px;
    padding: 80px 24px 160px;
  }
}
@media print, screen and (min-width: 1500px) {
  .cutoutText-text {
    font-size: 100px;
  }
}

.cutoutText-container {
  position: relative;
  width: 100%;
  height: 25vh;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
}
.cutoutText-container.is-large {
  height: auto;
  padding: 100px 0;
}
.cutoutText-container .text-video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: relative;
}
.cutoutText-container .cutoutText-text {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 80px;
  mix-blend-mode: screen;
}
@media print, screen and (min-width: 768px) {
  .cutoutText-container .cutoutText-text {
    font-size: 100px;
  }
}

.highlightText {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
}

.highlightText-text {
  padding: 80px 24px 40px 24px;
  background: #fff;
  font-weight: 600;
  position: relative;
  font-size: 48px;
}
@media print, screen and (min-width: 768px) {
  .highlightText-text {
    font-size: 60px;
  }
}
@media print, screen and (min-width: 1024px) {
  .highlightText-text {
    font-size: 80px;
    padding: 160px 24px 80px;
  }
}
@media print, screen and (min-width: 1500px) {
  .highlightText-text {
    font-size: 115px;
    line-height: 141px;
  }
}

.page-about .highlightText-text {
  padding-top: 0;
}

.highlightText-container {
  position: relative;
  width: 100%;
  max-width: 1388px;
  margin: 0 auto;
  overflow: hidden;
  padding: 0;
}
.highlightText-container.is-block .highlightText-text {
  padding-bottom: 0;
}
.highlightText-container .text-video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.highlightText-container .highlightText-normal {
  background: #fff;
  text-align: left;
  font-weight: 700;
  position: relative;
  font-size: 32px;
}
@media print, screen and (min-width: 470px) {
  .highlightText-container .highlightText-normal {
    font-size: 48px;
  }
}
@media print, screen and (min-width: 620px) {
  .highlightText-container .highlightText-normal {
    font-size: 64px;
  }
}
@media print, screen and (min-width: 768px) {
  .highlightText-container .highlightText-normal {
    font-size: 80px;
  }
}
@media print, screen and (min-width: 1024px) {
  .highlightText-container .highlightText-normal {
    font-size: 100px;
  }
}
@media print, screen and (min-width: 1200px) {
  .highlightText-container .highlightText-normal {
    font-size: 115px;
  }
}
.highlightText-container .highlightText-cutout-wrap {
  background: #fff;
  position: relative;
  overflow: hidden;
  display: block;
  position: relative;
  font-weight: 700;
  font-size: 32px;
  margin-top: 12px;
}
@media print, screen and (min-width: 470px) {
  .highlightText-container .highlightText-cutout-wrap {
    font-size: 48px;
    margin-top: 0;
  }
}
@media print, screen and (min-width: 620px) {
  .highlightText-container .highlightText-cutout-wrap {
    font-size: 64px;
  }
}
@media print, screen and (min-width: 768px) {
  .highlightText-container .highlightText-cutout-wrap {
    font-size: 80px;
  }
}
@media print, screen and (min-width: 1024px) {
  .highlightText-container .highlightText-cutout-wrap {
    font-size: 100px;
  }
}
@media print, screen and (min-width: 1200px) {
  .highlightText-container .highlightText-cutout-wrap {
    font-size: 115px;
    line-height: 141px;
  }
}
.highlightText-container .highlightText-cutout {
  background: #fff;
  mix-blend-mode: screen;
  display: block;
  position: relative;
}
.highlightText-container .highlightText-cutout.is-centered {
  text-align: center;
}

.highlightText-subtitle {
  font-size: 18px;
  line-height: 32px;
  margin: 32px 0 0 0;
  background: #fff;
  font-weight: 400;
  font-weight: 600;
}
@media print, screen and (min-width: 1024px) {
  .highlightText-subtitle {
    font-size: 24px;
    line-height: 40px;
  }
}

.home .highlightText-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.home .highlightText-container .down-arrow {
  position: absolute;
  bottom: -24px;
  left: 234px;
}
@media print, screen and (min-width: 470px) {
  .home .highlightText-container .down-arrow {
    left: 367px;
  }
}
@media print, screen and (min-width: 620px) {
  .home .highlightText-container .down-arrow {
    left: 501px;
  }
}
@media print, screen and (min-width: 768px) {
  .home .highlightText-container .down-arrow {
    left: 636px;
  }
}
@media print, screen and (min-width: 1024px) {
  .home .highlightText-container .down-arrow {
    left: 814px;
    bottom: 0;
  }
}
@media print, screen and (min-width: 1200px) {
  .home .highlightText-container .down-arrow {
    left: 936px;
  }
}

.page-strategy-creative-services .highlightText-container,
.page-creative-design .highlightText-container,
.page-creative-design .highlightText-container {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media print, screen and (min-width: 768px) {
  .page-strategy-creative-services .highlightText-container,
  .page-creative-design .highlightText-container,
  .page-creative-design .highlightText-container {
    height: 70vh;
  }
}
.page-strategy-creative-services .highlightText-container .highlightText-text,
.page-creative-design .highlightText-container .highlightText-text,
.page-creative-design .highlightText-container .highlightText-text {
  padding: 0px 0px 80px 0px;
}
@media print, screen and (min-width: 768px) {
  .page-strategy-creative-services .highlightText-container .highlightText-text,
  .page-creative-design .highlightText-container .highlightText-text,
  .page-creative-design .highlightText-container .highlightText-text {
    padding: 80px 0;
  }
}

.page-strategy-creative-services .solutionsWork,
.page-creative-design .solutionsWork {
  padding-top: 0;
}

.content-demo {
  padding: 64px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #AF4EFB;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
  overflow: hidden;
  position: relative;
}

.content-demo-title.section-title {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 16px;
  height: auto;
  margin: 0 auto;
  flex-wrap: wrap;
  padding: 0 24px;
  position: relative;
  z-index: 3;
  z-index: 2;
  font-size: 64px;
  line-height: 88px;
}
@media screen and (max-width: 559px) {
  .content-demo-title.section-title {
    font-size: 32px;
    line-height: 40px;
    gap: 0 10px;
  }
  .content-demo-title.section-title img {
    max-width: 140px !important;
    height: auto;
  }
}

.content-demo .button-row {
  position: relative;
  z-index: 3;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.section-heading.section-heading-grid {
  padding-top: 48px;
  max-width: 1300px;
}
.section-heading.section-heading-grid .section-subtitle {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  font-size: 32px;
  line-height: 44px;
}
.section-heading.section-heading-grid .section-title {
  font-size: 43px;
  line-height: 59px;
}

.featuredGrid {
  padding: 40px 32px;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1664px;
  margin: 0 auto;
}
.featuredGrid:first-child {
  padding-top: 0;
}

.home .featuredGrid {
  background: #252525;
  padding: 40px 0 40px 0;
}
.home .featuredGrid:first-child {
  padding-top: 0;
}
@media print, screen and (min-width: 768px) {
  .home .featuredGrid {
    padding: 100px 0 80px 0;
  }
}
.home .featuredGrid .featuredGrid-main-title,
.home .featuredGrid .featuredGrid-main-text,
.home .featuredGrid .featuredGrid-title,
.home .featuredGrid .featuredGrid-text,
.home .featuredGrid .featured-block-info-text,
.home .featuredGrid .featured-block-info-header h3 {
  color: #fff;
}

.featuredGrid .container {
  padding: 0 32px;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1320px;
  margin: 0 auto;
}

.section-heading + .featuredGrid {
  padding-top: 32px;
}

.featuredGrid + .section-heading {
  margin-top: 40px;
}

.featuredGrid-header {
  text-align: center;
  flex: 0 0 100%;
  width: 100%;
  -ms-flex: 0 1 auto;
  padding: 0 0 32px 0;
}
@media print, screen and (min-width: 900px) {
  .featuredGrid-header {
    padding: 0 56px 40px 6px;
    flex: 0 0 50%;
    -ms-flex: 0 1 auto;
    width: 50%;
    text-align: left;
  }
}
@media print, screen and (min-width: 1200px) {
  .featuredGrid-header {
    flex: 1 1 33.333333334%;
    -ms-flex: 0 1 auto;
    width: 33.333333334%;
  }
}

.featuredGrid-title {
  font-size: 2em;
  line-height: 1.25;
  margin: 0 0 16px 0;
}
@media print, screen and (min-width: 900px) {
  .featuredGrid-title {
    font-size: 2.5em;
    line-height: 1.4;
  }
}

.featuredGrid-text {
  margin: 0;
  font-size: 1.125em;
  line-height: 1.7777777778;
}

.featuredGrid-main {
  display: block;
  flex: 0 0 100%;
  width: 100%;
  -ms-flex: 0 1 auto;
  padding: 0 0 32px 0;
}
@media print, screen and (min-width: 900px) {
  .featuredGrid-main {
    flex: 0 0 50%;
    -ms-flex: 0 1 auto;
    width: 50%;
    padding: 0 6px 40px 0;
    max-width: 816px;
  }
}
@media print, screen and (min-width: 1200px) {
  .featuredGrid-main {
    flex: 0 0 66.666666666%;
    -ms-flex: 0 1 auto;
    width: 66.666666666%;
  }
}

.featuredGrid-main-image {
  height: 204px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  border-radius: 4px;
}
@media print, screen and (min-width: 768px) {
  .featuredGrid-main-image {
    height: 0;
    padding-top: 39.2156862745%;
  }
}

.featuredGrid-main-title {
  font-size: 1.5em;
  line-height: 1.3333333333;
  margin: 16px 0 8px 0;
  padding: 0 16px;
}

.featuredGrid-main-text {
  padding: 0 16px;
  margin: 0;
  font-size: 1em;
  line-height: 1.5;
}

.featured-block {
  display: block;
  padding: 16px 8px;
  opacity: 0;
  position: relative;
  transform: translateY(40px);
  transition: opacity 0.2s ease, transform 0.2s ease, margin 0.2s ease;
}
.featured-block.is-animated {
  opacity: 1;
  transform: translateY(0);
}

.featured-block {
  width: 100%;
  flex: 0 0 100%;
  -ms-flex: 0 1 auto;
  padding: 16px;
}
@media print, screen and (min-width: 768px) {
  .featured-block {
    width: 50%;
    flex: 0 0 50%;
    -ms-flex: 0 1 auto;
  }
}
@media print, screen and (min-width: 1024px) {
  .featured-block {
    width: 25%;
    flex: 0 0 25%;
    -ms-flex: 0 1 auto;
    padding: 24px;
  }
}
@media print, screen and (min-width: 1500px) {
  .featured-block {
    padding: 40px;
  }
}

.featured-block-image {
  background-color: rgba(37, 37, 37, 0.1);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 204px;
  display: block;
  border-radius: 4px;
}
@media print, screen and (min-width: 768px) {
  .featured-block-image {
    height: 0;
    padding-top: 67.5496688742%;
  }
}

.featured-block-inner {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.featured-block-info {
  padding: 26px 0 0 0;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  text-align: left;
}
@media print, screen and (min-width: 1500px) {
  .featured-block-info {
    padding: 16px 0 0 0;
  }
}

.featured-block-info-header {
  text-align: center;
}
.featured-block-info-header h3 {
  font-size: 1.125em;
  line-height: 1.3333333333;
  margin: 0 0 28px 0;
  font-weight: 400;
}
@media print, screen and (min-width: 1500px) {
  .featured-block-info-header h3 {
    font-size: 27px;
    line-height: 37px;
  }
}

.featured-block-info-content {
  padding: 0;
}
.featured-block-info-content .btn, .featured-block-info-content .pagination a, .pagination .featured-block-info-content a, .featured-block-info-content .gform_button {
  margin-top: 16px;
}

.featured-block-info-text {
  margin: 0;
  font-size: 1em;
  line-height: 1.5;
  font-weight: 400;
}
@media print, screen and (min-width: 1024px) {
  .featured-block-info-text {
    font-size: 18px;
    line-height: 30px;
  }
}

.featuredGrid-link {
  font-weight: 400;
  margin: 16px 0 0 0;
  display: inline-block;
}

.featuredGrid-main-link {
  font-weight: 400;
  margin: 16px 0 0 0;
  display: inline-block;
  padding: 0 16px;
}

.featuredGrid.is-restricted {
  max-width: 1006px;
}
.featuredGrid.is-restricted .featured-block {
  width: 100%;
  flex: 0 0 100%;
  -ms-flex: 0 1 auto;
}
@media print, screen and (min-width: 768px) {
  .featuredGrid.is-restricted .featured-block {
    width: 50%;
    flex: 0 0 50%;
    -ms-flex: 0 1 auto;
  }
}
@media print, screen and (min-width: 900px) {
  .featuredGrid.is-restricted .featured-block {
    width: 33.3333333333%;
    flex: 0 0 33.3333333333%;
    -ms-flex: 0 1 auto;
  }
}

.featured-button,
.featured-tabs-button {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 1em;
  line-height: 24px;
  letter-spacing: 0.5px;
  cursor: pointer;
  color: #fff;
  padding: 0;
  margin-left: 7px;
  padding-left: 16px;
  padding-bottom: 4px;
  border-left: 4px solid #fff;
  margin-top: 24px;
  text-decoration: none !important;
  transition: all 0.2s ease;
  background: transparent;
  text-align: left;
}
.featured-button .explore-arrows .explore-arrow *,
.featured-tabs-button .explore-arrows .explore-arrow * {
  fill: #fff;
}
.featured-button:hover,
.featured-tabs-button:hover {
  color: #fff;
  border-color: #fff;
}
.featured-button:hover svg *,
.featured-tabs-button:hover svg * {
  fill: #fff;
}

.featured-tabs {
  position: relative;
  padding: 0 32px 64px 32px;
}

.featured-block.is-open {
  padding-bottom: 32px;
}

.featured-block:after {
  /*
  	content:"";
  	position: absolute;
  	bottom:0;
  	width: 0;
  	left:50%;
  	transform: translateX(-50%);
  	height: 0;
  	border-style: solid;
  	border-width: 0 12px 8px 12px;
  	border-color: transparent transparent $color-offwhite transparent;
  	opacity: 0;
  	transition: opacity 0.4s ease 0.2s;
  */
}

.featured-block.is-open:after {
  opacity: 1;
  transition: opacity 0s ease 0s;
}

.featured-block.is-open .featured-tabs {
  z-index: 1;
  pointer-events: all;
  opacity: 1;
  transition: opacity 0.4s ease 0.2s;
}

.featured-tabs-inner {
  max-width: 1320px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.page-enabling-technology .featured-tabs-inner {
  align-items: flex-start;
}
.page-enabling-technology .featured-tabs-inner .featured-tabs-nav {
  padding-top: 32px;
}

.featured-tabs-nav {
  flex: 0 0 56px;
  -ms-flex: 0 1 auto;
  max-width: 56px;
  width: 100%;
  z-index: 2;
  position: relative;
  padding: 16px 0 0 0;
}
@media print, screen and (min-width: 620px) {
  .featured-tabs-nav {
    flex: 0 0 200px;
    -ms-flex: 0 1 auto;
    max-width: 200px;
  }
}
@media print, screen and (min-width: 900px) {
  .featured-tabs-nav {
    flex: 0 0 300px;
    -ms-flex: 0 1 auto;
    max-width: 300px;
  }
}

.featured-tabs-nav-button {
  width: 100%;
  background: #fff;
  margin: 0 0 16px 0;
  border-radius: 4px;
  box-shadow: 0 0 8px rgba(37, 37, 37, 0.15);
  font-size: 18px;
  line-height: 1.3333333333;
  font-weight: 400;
  padding: 16px;
  color: #252525;
  text-align: center;
  transition: background 0.2s ease, color 0.2s ease;
}
@media print, screen and (min-width: 620px) {
  .featured-tabs-nav-button {
    text-align: left;
    padding: 24px;
  }
}
.featured-tabs-nav-button.is-active {
  background: #000;
  color: #fff;
}
.featured-tabs-nav-button.is-active svg * {
  fill: #fff;
}
@media print, screen and (min-width: 620px) {
  .featured-tabs-nav-button .number {
    display: none;
  }
}
.featured-tabs-nav-button .title {
  display: none;
}
@media print, screen and (min-width: 620px) {
  .featured-tabs-nav-button .title {
    display: inline;
  }
}
.featured-tabs-nav-button.has-video {
  position: relative;
}
@media print, screen and (min-width: 620px) {
  .featured-tabs-nav-button.has-video {
    padding-right: 32px;
  }
}
.featured-tabs-nav-button.has-video svg {
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}
@media screen and (max-width: 619px) {
  .featured-tabs-nav-button.has-video svg {
    display: none;
  }
}

.featured-tabs-panels {
  background: #fff;
  padding: 16px 16px 16px 32px;
  position: relative;
  z-index: 1;
  margin-left: -16px;
  border-radius: 8px;
}
@media print, screen and (min-width: 620px) {
  .featured-tabs-panels {
    padding: 32px 40px 32px 64px;
    margin-left: -24px;
  }
}

.footer {
  background: #000;
  border-top: 2px solid #000;
  padding: 64px 0 32px 0;
  text-align: center;
}

.footer-top {
  padding: 0 16px 0 16px;
}
@media print, screen and (min-width: 560px) {
  .footer-top {
    padding: 0 80px 0 80px;
  }
}
.footer-top img {
  max-height: 96px;
}

.footer-tagline {
  color: #fff;
  font-size: 1.25em;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
  font-weight: 300;
  margin: 24px 0;
}

.footer-bottom {
  padding: 32px 16px;
}
@media print, screen and (min-width: 560px) {
  .footer-bottom {
    padding: 32px 80px;
  }
}

.footer-bottom-left {
  font-weight: 300;
  color: #fff;
  font-size: 12px;
  line-height: 1.5;
  text-transform: none;
  margin: 24px 0 0 0;
}
.footer-bottom-left a {
  color: #fff;
  text-decoration: underline;
}
.footer-bottom-left a:hover {
  color: #fff;
}

.connect-docs {
  font-weight: 300;
  color: #fff;
  font-size: 12px;
  line-height: 1.5;
  text-transform: none;
  margin: 10px 0 0 0;
}
.connect-docs a {
  color: #fff;
  text-decoration: underline;
}
.connect-docs a:hover {
  color: #fff;
}

.footer-nav {
  list-style: none;
  padding: 0;
  margin: 0 0 24px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.footer-nav .sub-menu {
  display: none;
}

.footer-nav li a {
  color: #fff;
  font-size: 1.25em;
  margin: 0 16px;
  font-weight: 300;
}

.section-heading.contact {
  max-width: 910px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px 100px;
}
@media print, screen and (min-width: 840px) {
  .section-heading.contact {
    flex-wrap: nowrap;
  }
}
.section-heading.contact img,
.section-heading.contact .section-subtitle {
  flex: 1;
}
.section-heading.contact .section-subtitle {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 700;
}
@media print, screen and (min-width: 840px) {
  .section-heading.contact .section-subtitle {
    font-size: 38px;
    line-height: 54px;
    text-align: left;
    margin-top: 0;
    position: relative;
    top: 10px;
  }
}
.section-heading.contact .contact-right {
  max-width: 530px;
}
.section-heading.contact .contact-left {
  display: flex;
  justify-content: center;
}
@media print, screen and (min-width: 768px) {
  .section-heading.contact .contact-left {
    position: relative;
  }
}
@media print, screen and (min-width: 768px) and (min-width: 840px) {
  .section-heading.contact .contact-left:after {
    content: "";
    background-color: #000;
    position: absolute;
    width: 3px;
    height: 81px;
    top: calc(50% + 11px);
    transform: translateY(-50%);
    left: calc(100% + 49px);
    display: block;
  }
}
.section-heading.contact img {
  max-width: 200px;
  height: auto;
  position: relative;
}

.home .section-heading.contact {
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 80px auto;
}

.form-wrapper {
  max-width: 1400px;
  padding: 0 8px;
}
@media print, screen and (min-width: 768px) {
  .form-wrapper {
    margin-top: 80px;
  }
}
@media print, screen and (min-width: 900px) {
  .form-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0;
  }
}

.pre-contact-title {
  max-width: 740px;
  margin: 0 auto;
  text-align: center;
  font-weight: 500;
  line-height: 1.4;
  font-size: 22px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 40px;
}
@media print, screen and (min-width: 768px) {
  .pre-contact-title {
    line-height: 1.4;
    font-size: 26px;
    margin-bottom: 0;
  }
}

.form-wrapper-left {
  padding: 0 8px;
}
@media print, screen and (min-width: 900px) {
  .form-wrapper-left {
    padding: 0;
    max-width: 800px;
    flex: 1 1 auto;
    -ms-flex: 0 1 auto;
  }
}

.form-wrapper-right {
  padding-top: 32px;
  padding-left: 8px;
  padding-right: 8px;
}
@media print, screen and (min-width: 900px) {
  .form-wrapper-right {
    min-width: 400px;
    max-width: 600px;
    flex: 1 1 600px;
    -ms-flex: 0 1 auto;
    padding-left: 64px;
    padding-right: 16px;
    padding-top: 0;
  }
}

.contact-block {
  padding: 40px 24px;
  border-radius: 4px;
  margin: 0 0 40px 0;
  border: 1px solid #000;
}
.contact-block.no-border {
  border: none;
  padding: 0 24px;
}

.contact-block-address {
  display: inline-flex;
  gap: 20px;
  flex-wrap: wrap;
}
.contact-block-address img {
  max-width: 120px;
  height: 100%;
}
.contact-block-address p {
  margin-top: 0;
}

.confirmation-text {
  text-align: center;
}

.apply-link {
  text-decoration: underline;
  display: block;
  margin-bottom: 16px;
  font-size: 1.125em;
}

.contact-block-title {
  font-size: 1.25em;
  line-height: 1.2;
  font-weight: 400;
  margin: 0 0 16px 0;
}

.gform_wrapper {
  padding: 24px 8px;
  border: 1px solid #000;
  border-radius: 4px;
}
@media print, screen and (min-width: 900px) {
  .gform_wrapper {
    padding: 4px 20px;
  }
}

.gform_fields {
  list-style: none;
  margin: 0;
  padding: 0;
}
@media print, screen and (min-width: 768px) {
  .gform_fields {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

.gfield,
.gform_footer {
  padding: 10px 16px;
  margin: 0;
}
@media print, screen and (min-width: 768px) {
  .gfield,
  .gform_footer {
    padding: 10px 8px;
    width: 50%;
    flex: 0 0 50%;
    -ms-flex: 0 1 auto;
  }
  .gfield.full,
  .gform_footer.full {
    width: 100%;
    flex: 0 0 100%;
  }
}

.gform_footer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
}

.gfield input,
.gfield select,
.gfield textarea {
  width: 100%;
  border-radius: 4px;
  background: #eee;
  font-family: "Avenir Next", Arial, Helvetica, Sans-serif;
}
.gfield input:focus,
.gfield select:focus,
.gfield textarea:focus {
  border-color: #000;
}

.gfield textarea {
  padding: 16px;
  font-size: 1.125em;
  line-height: 1.7777777778;
  font-family: "Avenir Next", Arial, Helvetica, Sans-serif;
  height: 154px;
  resize: vertical;
}

.gfield input,
.gfield select {
  height: 60px;
  line-height: 60px;
  padding: 0 16px;
  font-size: 1.125em;
  font-family: "Avenir Next", Arial, Helvetica, Sans-serif;
}
.gfield input.error,
.gfield select.error {
  border-color: tomato;
}

.gfield select {
  box-shadow: none;
  -webkit-appearance: none;
  background-image: url(../svg/icon-arrow.svg);
  background-repeat: no-repeat;
  background-position: right 16px center;
}

.gfield label.gfield_label {
  font-size: 0.875em;
  font-weight: 400;
  line-height: 1.7142857143;
  margin: 0 0 16px 0;
  text-transform: uppercase;
  display: block;
}

.gform_validation_container {
  display: none;
}

label.error {
  padding: 4px 0;
  color: tomato;
  display: block;
}

.gfield_error input,
.gfield_error select,
.gfield_error textarea {
  border-color: tomato;
}
.gfield_error .gfield_description.validation_message {
  color: tomato;
  font-style: italic;
  font-size: 0.875em;
  display: block;
  margin: 8px 0 0 0;
}

body img.gform_ajax_spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 40px;
  width: 40px;
}

.form-submit {
  width: 100%;
  padding: 20px 8px;
  text-align: left;
}

.validation_error {
  text-align: center;
  padding: 16px;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  margin: 0 -16px;
}

.block {
  display: block;
  padding: 16px;
  opacity: 0;
  transform: translateY(40px);
  transition: opacity 0.2s ease, transform 0.2s ease;
}
.block.is-animated {
  opacity: 1;
  transform: translateY(0);
}

.block-inner {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.block-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  background-color: #252525;
  display: block;
  transition: opacity 0.2s ease;
}

a.block-image:hover {
  opacity: 0.8;
}

.block-info {
  padding: 0 24px;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  text-align: center;
  transition: transform 0.2s ease;
}
@media print, screen and (min-width: 1500px) {
  .block-info {
    padding: 0 40px;
  }
}

.block-inner:hover .block-info {
  transform: translateY(0);
}
@media screen and (max-width: 767px) {
  .block-inner:hover .block-info .block-info-header {
    margin-top: 40px;
  }
}

.block-info-header {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: margin 0.2s ease;
  padding: 24px 0 0 0;
}
.block-info-header h2 {
  font-size: 1.125em;
  line-height: 1.3333333333;
  margin: 0 0 16px 0;
}
@media print, screen and (min-width: 1500px) {
  .block-info-header h2 {
    font-size: 1.5em;
    line-height: 1.3333333333;
  }
}

.block-info-content {
  padding: 0 0 24px 0;
}

.block-info-text {
  margin-bottom: 24px;
  font-size: 1.125em;
}
@media screen and (max-width: 1499px) {
  .block-info-text {
    font-size: 1em;
    line-height: 1.5;
  }
}

.block-bottom {
  text-align: center;
  padding: 32px;
}
.block-bottom h2 {
  margin: 0;
}

.grid-3 .block {
  width: 100%;
  flex: 0 0 100%;
  -ms-flex: 0 1 auto;
}
@media print, screen and (min-width: 768px) {
  .grid-3 .block {
    width: 50%;
    flex: 0 0 50%;
    -ms-flex: 0 1 auto;
  }
}
@media print, screen and (min-width: 1200px) {
  .grid-3 .block {
    width: 33.3333333333%;
    flex: 0 0 33.3333333333%;
    -ms-flex: 0 1 auto;
  }
}
.grid-3 .block-image {
  height: 460px;
}
@media print, screen and (min-width: 1024px) {
  .grid-3 .block-image {
    height: 534px;
  }
}
@media print, screen and (min-width: 1500px) {
  .grid-3 .block-image {
    height: 700px;
  }
}

.grid-4 .block {
  width: 100%;
  flex: 0 0 100%;
  -ms-flex: 0 1 auto;
}
@media print, screen and (min-width: 768px) {
  .grid-4 .block {
    width: 50%;
    flex: 0 0 50%;
    -ms-flex: 0 1 auto;
  }
}
@media print, screen and (min-width: 1200px) {
  .grid-4 .block {
    width: 33.3333333333%;
    flex: 0 0 33.3333333333%;
    -ms-flex: 0 1 auto;
  }
}
@media print, screen and (min-width: 1500px) {
  .grid-4 .block {
    width: 25%;
    flex: 0 0 25%;
    -ms-flex: 0 1 auto;
  }
}
.grid-4 .block-image {
  height: 460px;
}

.grid-5 .block {
  width: 100%;
  flex: 0 0 100%;
  -ms-flex: 0 1 auto;
}
@media print, screen and (min-width: 768px) {
  .grid-5 .block {
    width: 50%;
    flex: 0 0 50%;
    -ms-flex: 0 1 auto;
  }
}
@media print, screen and (min-width: 1200px) {
  .grid-5 .block {
    width: 33.3333333333%;
    flex: 0 0 33.3333333333%;
    -ms-flex: 0 1 auto;
  }
}
@media print, screen and (min-width: 1500px) {
  .grid-5 .block {
    width: 20%;
    flex: 0 0 20%;
    -ms-flex: 0 1 auto;
  }
}
.grid-5 .block-image {
  height: 460px;
}
.grid-5 .block-inner {
  height: 460px;
}
.grid-5 .block-info-content {
  height: 100%;
  padding-top: 80px;
}
.grid-5 .block-info {
  padding: 0 32px;
  transform: translateY(100%);
}
.grid-5 .block-info-text {
  font-size: 1.25em;
}

.grid-constrained {
  max-width: 1600px;
  margin: 0 auto;
}
.grid-constrained .block .block-image {
  height: 460px;
}
@media print, screen and (min-width: 1024px) {
  .grid-constrained .block .block-image {
    height: 534px;
  }
}

.show-nav {
  transform: translateY(0) !important;
  background: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  top: 0;
}
.show-nav.second-header .header-nav .sub-menu {
  bottom: auto !important;
  top: 100% !important;
}

.header {
  padding: 8px 16px 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 121;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}
@media print, screen and (min-width: 768px) {
  .header {
    padding: 16px 32px 16px 32px;
  }
}

.header-logo {
  display: block;
}
.header-logo svg {
  display: block;
  width: 94px;
  height: 48px;
}
.header-logo svg * {
  fill: #000;
}

.hamburger-nav {
  display: none;
}

.header-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  flex-grow: 0;
}

.header-nav {
  list-style: none;
  padding: 0;
  margin: 0 0 0 40px;
  display: none;
  align-items: center;
  justify-content: flex-end;
}
@media print, screen and (min-width: 768px) {
  .header-nav {
    display: flex;
  }
}

.header-nav > li {
  margin: 0 0 0 24px;
  padding: 8px 0;
  position: relative;
  font-size: 1em;
  font-weight: 500;
}
@media print, screen and (min-width: 1120px) {
  .header-nav > li {
    margin: 0 0 0 40px;
    font-size: 1.125em;
  }
}
.header-nav > li > a {
  color: #252525;
}
.header-nav > li > a:hover {
  text-decoration: none;
}

.header-nav .sub-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  margin: 0;
  padding: 0;
  list-style: none;
  width: 220px;
}
.header-nav .sub-menu li {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.header-nav .sub-menu li a {
  padding: 12px 16px;
  display: block;
}
.header-nav > li:hover .sub-menu {
  display: block;
}

.search-button {
  padding: 16px;
  background: transparent;
  cursor: pointer;
  border: none;
  display: none;
}
@media print, screen and (min-width: 768px) {
  .search-button {
    display: block;
  }
}
@media print, screen and (min-width: 1120px) {
  .search-button {
    margin-left: 24px;
  }
}
.search-button svg {
  height: 24px;
  width: 24px;
}
.search-button svg.close {
  display: none;
}
.search-button svg.search {
  display: block;
}
.search-button svg * {
  transition: fill 0.2s ease;
  fill: #252525;
}
.search-button:hover svg * {
  fill: #000;
}

.search-open .search-button svg.close {
  display: block;
}
.search-open .search-button svg.search {
  display: none;
}

.hamburger {
  background: #fff;
  width: 48px;
  height: 48px;
  padding: 0 12px;
  margin-right: 0px;
  cursor: pointer;
}
@media print, screen and (min-width: 768px) {
  .hamburger {
    display: none;
  }
}
.hamburger:hover::before, .hamburger:hover::after,
.hamburger:hover div {
  transform: scale(1.1);
  transform-origin: center center;
  background-color: #000;
}
.hamburger::before, .hamburger::after,
.hamburger div {
  content: "";
  background-color: #252525;
  border-radius: 3px;
  display: block;
  height: 3px;
  margin: 5px 0;
  transition: all 0.2s ease-in-out;
}
.hamburger div {
  width: 24px;
}
.hamburger.is-active::before {
  transform: translateY(8px) rotate(135deg);
}
.hamburger.is-active::after {
  transform: translateY(-8px) rotate(-135deg);
}
.hamburger.is-active div {
  transform: scale(0);
}

.header.nav-down {
  top: 0;
}

.header.nav-up {
  top: -75px;
}

/*

.header-fixed .header{
	background-color: #fff;

	padding:8px 16px;
	border-bottom: none;
	.header-logo {
		margin-bottom:0;
	}
	.logo-small {
		display: block;
	}
	.logo-large {
		display: none;
	}
	.hamburger {
		margin-right:-16px;
		padding:0 16px;
		height:48px;
		div {
			width:32px;
		}
	}
	.header-right {
		align-items: center;
	}
	.search-button {
		padding:6px 16px;
		svg {
			width:24px;
			height:24px;
		}
	}
}
*/
.hero {
  position: relative;
  padding: 128px 0 40px 0;
  background-color: #252525;
  background-repeat: no-repeat;
  background-size: cover;
}
@media print, screen and (min-width: 560px) {
  .hero {
    padding: 200px 0 40px 0;
  }
}
@media print, screen and (min-width: 768px) {
  .hero {
    padding: 220px 0 40px 0;
  }
}
@media print, screen and (min-width: 1500px) {
  .hero {
    padding: 270px 0 40px 0;
  }
}

.hero.small-hero {
  padding: 200px 0 128px 0;
}
@media print, screen and (min-width: 560px) {
  .hero.small-hero {
    padding: 200px 0;
  }
}
@media print, screen and (min-width: 768px) {
  .hero.small-hero {
    padding: 200px 0;
  }
}
@media print, screen and (min-width: 1500px) {
  .hero.small-hero {
    padding: 200px 0;
    min-height: none;
    display: block;
  }
}

.hero.aboutHero {
  height: 318px;
}
@media print, screen and (min-width: 560px) {
  .hero.aboutHero {
    height: 400px;
  }
}
@media print, screen and (min-width: 768px) {
  .hero.aboutHero {
    height: 400px;
  }
}
@media print, screen and (min-width: 1500px) {
  .hero.aboutHero {
    height: 590px;
  }
}

.hero.home-hero {
  padding: 200px 0 128px 0;
  position: relative;
  text-align: center;
}
@media print, screen and (min-width: 560px) {
  .hero.home-hero {
    padding: 200px 0;
  }
}
@media print, screen and (min-width: 768px) {
  .hero.home-hero {
    padding: 240px 0 160px;
    min-height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.hero.home-hero:after {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 2;
}
.hero.home-hero .container {
  max-width: 1400px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;
}
@media print, screen and (min-width: 480px) {
  .hero.home-hero .container {
    flex-wrap: nowrap;
    justify-content: center;
  }
}
@media print, screen and (min-width: 680px) {
  .hero.home-hero .container {
    gap: 22px;
  }
}
@media print, screen and (min-width: 1140px) {
  .hero.home-hero .container {
    gap: 26px;
  }
}
.hero.home-hero .container svg {
  height: auto;
  display: block;
  flex: 1 1 88px;
  max-width: 88px;
}
.hero.home-hero .container svg * {
  fill: #fff;
}
@media print, screen and (min-width: 580px) {
  .hero.home-hero .container svg {
    flex: 1 1 108px;
    max-width: 108px;
  }
}
@media print, screen and (min-width: 680px) {
  .hero.home-hero .container svg {
    flex: 1 1 138px;
    max-width: 138px;
  }
}
@media print, screen and (min-width: 880px) {
  .hero.home-hero .container svg {
    flex: 1 1 138px;
    max-width: 138px;
  }
}
@media print, screen and (min-width: 1000px) {
  .hero.home-hero .container svg {
    flex: 1 1 170px;
    max-width: 170px;
  }
}

.home-hero .logo {
  margin: 0 auto;
  width: 400px;
  height: auto;
  position: relative;
  z-index: 2;
  max-width: 40vw;
}

.page-contact .hero:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.hero .container {
  position: relative;
  z-index: 3;
  padding: 0 32px;
  width: 100%;
}
.hero .container .home-text {
  color: #fff;
  flex: 0 0 auto;
  text-align: left;
  font-size: 30.6px;
}
.hero .container .home-text .home-animate {
  display: block;
  opacity: 0;
  transform: translateY(-80px);
}
@media print, screen and (min-width: 400px) {
  .hero .container .home-text {
    font-size: 34px;
  }
}
@media print, screen and (min-width: 480px) {
  .hero .container .home-text {
    font-size: 22px;
  }
}
@media print, screen and (min-width: 680px) {
  .hero .container .home-text {
    font-size: 32px;
  }
}
@media print, screen and (min-width: 780px) {
  .hero .container .home-text {
    font-size: 38px;
  }
}
@media print, screen and (min-width: 1000px) {
  .hero .container .home-text {
    font-size: 47px;
  }
}
.hero .container .home-text .home-normal {
  font-weight: 400;
}
.hero .container .home-text .home-large {
  display: block;
  position: relative;
  font-weight: 700;
  margin-top: 0;
}
@media print, screen and (min-width: 470px) {
  .hero .container .home-text .home-large {
    margin-top: 0;
  }
}

.hero-title {
  font-size: 2em;
  line-height: 1.25;
  margin: 0;
  font-weight: 700;
  color: rgb(255, 255, 255);
}
@media print, screen and (min-width: 560px) {
  .hero-title {
    font-size: 3em;
    line-height: 1.1666666667;
  }
}
@media print, screen and (min-width: 768px) {
  .hero-title {
    font-size: 4em;
    line-height: 1.125;
  }
}
@media print, screen and (min-width: 1024px) {
  .hero-title {
    font-size: 80px;
    line-height: 1.2;
  }
}
.hero-title.is-dark {
  color: #000;
}

.hero-subtitle {
  max-width: 1430px;
  color: rgba(255, 255, 255, 0.75);
  font-size: 1em;
  line-height: 1.5;
}
@media print, screen and (min-width: 560px) {
  .hero-subtitle {
    font-size: 1.5em;
    line-height: 1.3333333333;
  }
}
@media print, screen and (min-width: 768px) {
  .hero-subtitle {
    font-size: 2em;
    line-height: 1.25;
  }
}
@media print, screen and (min-width: 1024px) {
  .hero-subtitle {
    font-size: 3em;
    line-height: 1.1666666667;
  }
}
@media print, screen and (min-width: 1500px) {
  .hero-subtitle {
    font-size: 3.25em;
    line-height: 1.0769230769;
  }
}

.hero-media {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.hero-video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 3;
}
.home .hero-video {
  display: block !important;
}

.hero-more {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 1.25em;
  line-height: 24px;
  letter-spacing: 0.5px;
  cursor: pointer;
  color: rgb(255, 255, 255);
  padding-bottom: 4px;
  margin-top: 24px;
  text-decoration: none !important;
  transition: all 0.2s ease;
  transform: scale(2);
}

.explore-arrows {
  margin-left: 8px;
  height: 28px;
  width: 13px;
  position: relative;
}
.explore-arrows .explore-arrow {
  position: absolute;
  top: 50%;
  margin-top: -4px;
  opacity: 0;
}
.explore-arrows .explore-arrow:first-of-type {
  top: 0;
  margin: 0;
}
.explore-arrows .explore-arrow:last-of-type {
  bottom: 0;
  top: auto;
  margin: 0;
}
.explore-arrows .explore-arrow * {
  fill: rgb(255, 255, 255);
  transition: fill 0.2s ease;
}

.hero-more:hover {
  color: rgba(255, 255, 255, 0.75);
  border-color: rgba(255, 255, 255, 0.75);
}
.hero-more:hover svg * {
  fill: rgba(255, 255, 255, 0.75);
}

.hero-meta {
  color: #fff;
  font-size: 20px;
  line-height: 32px;
  margin: 0 0 8px 0;
}
.hero-meta strong {
  font-weight: 400;
  text-transform: uppercase;
}

.homepage-heading {
  padding-top: 250px;
  padding-bottom: 30px;
  text-align: center;
}
.homepage-heading h1 {
  color: #000;
  font-size: 98px;
  line-height: 134px;
}
.homepage-heading .header-line {
  margin-bottom: 300px;
}
.homepage-heading .header-line:last-child {
  margin-bottom: 0;
}

.page-contact .hero .hero-title {
  max-width: 900px;
}
@media print, screen and (min-width: 1024px) {
  .page-contact .hero .hero-title {
    font-size: 72px;
    line-height: 78px;
  }
}

.hero.serviceHero {
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 64px 0 0 0;
  height: 128px;
}
@media print, screen and (min-width: 768px) {
  .hero.serviceHero {
    padding: 80px 0 0 0;
    height: 160px;
  }
}
.hero.serviceHero .hero-title {
  font-weight: 400;
  font-size: 32px;
  line-height: 1;
  text-align: center;
}

.scroll-down {
  display: none;
  position: absolute;
  left: 50%;
  bottom: 35px;
  text-align: center;
  font-size: 20px;
  z-index: 100;
  text-decoration: none;
  text-shadow: 0;
  width: 13px;
  height: 13px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  z-index: 9;
  left: 50%;
  -webkit-transform: translate(-50%, 0%) rotate(45deg);
  -moz-transform: translate(-50%, 0%) rotate(45deg);
  transform: translate(-50%, 0%) rotate(45deg);
  -webkit-animation: fade_move_down 4s ease-in-out infinite;
  -moz-animation: fade_move_down 4s ease-in-out infinite;
  animation: fade_move_down 4s ease-in-out infinite;
}
@media print, screen and (min-width: 970px) {
  .scroll-down {
    display: block;
  }
}

/*animated scroll arrow animation*/
@-webkit-keyframes fade_move_down {
  0% {
    -webkit-transform: translate(0, -10px) rotate(45deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 10px) rotate(45deg);
    opacity: 0;
  }
}
@-moz-keyframes fade_move_down {
  0% {
    -moz-transform: translate(0, -10px) rotate(45deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -moz-transform: translate(0, 10px) rotate(45deg);
    opacity: 0;
  }
}
@keyframes fade_move_down {
  0% {
    transform: translate(0, -10px) rotate(45deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 10px) rotate(45deg);
    opacity: 0;
  }
}
.homeQuote {
  padding: 80px 0 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}
@media print, screen and (min-width: 1024px) {
  .homeQuote {
    padding: 196px 0 0 0;
  }
}
.homeQuote .section-title {
  text-align: center;
  margin: 0 auto 24px auto;
  max-width: 1010px;
}
.homeQuote .section-title span {
  font-weight: 400;
}
.homeQuote .section-subtitle {
  text-align: center;
  margin: 0 auto 30px auto;
  max-width: 900px;
  padding-right: 20px;
  padding-left: 20px;
}
@media print, screen and (min-width: 1024px) {
  .homeQuote .section-subtitle {
    margin: 0 auto 64px auto;
  }
}

@media print, screen and (min-width: 1024px) {
  .home .homeQuote {
    padding: 112px 0 0 0;
  }
}

.homeQuote .container {
  max-width: 1288px;
  padding: 0 24px;
}

@media print, screen and (min-width: 680px) {
  .homeQuote-slide-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }
}
@media print, screen and (min-width: 1024px) {
  .homeQuote-slide-inner {
    gap: 60px;
  }
}
@media screen and (max-width: 679px) {
  .homeQuote-slide-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
  }
}

.homeQuote-slide-left {
  padding-left: 130px;
  flex: 1 1 716px;
  max-width: 716px;
  position: relative;
}
@media screen and (max-width: 1023px) {
  .homeQuote-slide-left {
    padding-left: 64px;
  }
}
@media screen and (max-width: 679px) {
  .homeQuote-slide-left {
    margin-bottom: 30px;
    flex: 1 1 auto;
    order: 2;
  }
}
.homeQuote-slide-left:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0px;
  width: 80px;
  height: 64px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("../svg/quotes.svg");
}
@media screen and (max-width: 1023px) {
  .homeQuote-slide-left:before {
    width: 49px;
    height: 39px;
    top: 0px;
  }
}

.homeQuote-slide-right {
  max-width: 230px;
  flex: 1 1 230px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.homeQuote-slide-right img {
  display: block;
}
@media print, screen and (min-width: 768px) {
  .homeQuote-slide-right {
    max-width: 329px;
    flex: 1 1 329px;
  }
}

.homeQuote-slide-left-text {
  font-size: 20px;
  line-height: 24px;
}
@media print, screen and (min-width: 768px) {
  .homeQuote-slide-left-text {
    font-size: 24px;
    line-height: 32px;
  }
}

.homeQuote-slider {
  position: relative;
}

.page-about .arrows {
  padding-bottom: 80px;
}

.arrows {
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  gap: 100px;
}

.prev-arrow,
.next-arrow {
  z-index: 4;
  cursor: pointer;
}
@media print, screen and (min-width: 1275px) {
  .prev-arrow,
  .next-arrow {
    position: absolute;
    transform: translateY(-1000%);
  }
}
.prev-arrow #arrow-stroke,
.next-arrow #arrow-stroke {
  stroke: #000;
  transition: all 0.2s ease;
}
.prev-arrow:hover #arrow-stroke, .prev-arrow:focus #arrow-stroke,
.next-arrow:hover #arrow-stroke,
.next-arrow:focus #arrow-stroke {
  stroke: #AF4EFB;
}

@media print, screen and (min-width: 1275px) {
  .prev-arrow {
    left: 90px;
  }
}

@media print, screen and (min-width: 1250px) {
  .next-arrow {
    right: 90px;
  }
}

.homeQuote-slide-left-name {
  font-size: 18px;
  line-height: 28px;
  margin: 24px 0 0 0;
  font-weight: 600;
}

.homeQuote-slide-left-title {
  font-size: 16px;
  line-height: 24px;
}

.homeQuote-btn {
  margin-top: 24px;
}

.page-about .homeQuote {
  padding-top: 0;
}

.homeSolutions .section-title {
  text-align: center;
  margin: 0 auto 24px auto;
  max-width: 1120px;
}
.homeSolutions .section-title span {
  font-weight: 400;
}
.homeSolutions .section-subtitle {
  text-align: center;
  margin: 0 auto 40px auto;
  max-width: 1020px;
  padding-right: 20px;
  padding-left: 20px;
}
@media print, screen and (min-width: 1024px) {
  .homeSolutions .section-subtitle {
    margin: 0 auto 64px auto;
  }
}
.homeSolutions .container {
  max-width: 1388px;
  padding: 0 24px;
}

.home .homeSolutions {
  padding: 80px 0;
}
@media print, screen and (min-width: 1024px) {
  .home .homeSolutions {
    padding: 112px 0 112px 0;
  }
}

.homeSolutions-grid {
  position: relative;
  padding-left: 400px;
}
@media screen and (max-width: 839px) {
  .homeSolutions-grid {
    padding-left: 240px;
  }
}
@media screen and (max-width: 579px) {
  .homeSolutions-grid {
    padding-left: 0;
  }
}

.homeSolutions-nav {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 340px;
}
@media screen and (max-width: 839px) {
  .homeSolutions-nav {
    width: 200px;
  }
}
@media screen and (max-width: 579px) {
  .homeSolutions-nav {
    display: none;
  }
}

.homeSolutions-nav-inner {
  position: sticky;
  padding: 0;
  top: 50%;
  transform: translateY(-50%);
}

.homeSolutions-nav-title {
  padding: 24px 0 24px 24px;
  font-size: 20px;
  font-weight: 600;
  color: #666;
  display: block;
  position: relative;
}
.homeSolutions-nav-title:before {
  content: "";
  position: absolute;
  width: 2px;
  top: 0;
  left: 0;
  background-color: #ccc;
  height: 100%;
}
.homeSolutions-nav-title:hover, .homeSolutions-nav-title.active {
  text-decoration: none;
  color: #AF4EFB;
}
.homeSolutions-nav-title:hover:before, .homeSolutions-nav-title.active:before {
  background-color: #AF4EFB;
}
@media screen and (max-width: 839px) {
  .homeSolutions-nav-title {
    font-size: 16px;
    padding: 16px 0 16px 24px;
  }
}

.home .homeSolutions-nav-title {
  color: #fff;
}
.home .homeSolutions-nav-title:before {
  background-color: #fff;
}
.home .homeSolutions-nav-title.active, .home .homeSolutions-nav-title:hover {
  color: #AF4EFB;
}
.home .homeSolutions-nav-title.active:before {
  background-color: #AF4EFB;
}

#title_strategy-creative:hover,
#title_strategy-creative.active {
  color: #AF4EFB;
}

#title_strategy-creative.active:before {
  background-color: #AF4EFB;
}

#title_omnichannel-activation:hover,
#title_omnichannel-activation.active {
  color: #AF4EFB;
}

#title_omnichannel-activation.active:before {
  background-color: #AF4EFB;
}

.home #title_enabling-technology:hover,
.home #title_enabling-technology.active {
  color: #AF4EFB;
}
.home #title_enabling-technology.active:before {
  background-color: #AF4EFB;
}

@media print, screen and (min-width: 1200px) {
  #Freight-Optimization {
    background-position-y: 160%;
  }
}

.homeSolutions-block {
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  width: 100%;
  height: 50vh;
  min-height: 400px;
  max-height: 500px;
  padding: 140px 0 0 0;
  margin: 0 0 160px 0;
  position: relative;
}
@media print, screen and (min-width: 768px) {
  .homeSolutions-block {
    padding: 140px 0;
  }
}
.homeSolutions-block:last-child {
  margin: 0;
}
.homeSolutions-block .homeSolutions-block-title {
  color: #fff;
  background: rgba(0, 0, 0, 0.9);
  padding: 8px 70px 8px 24px;
  bottom: 0;
  left: 0;
  width: 100%;
  position: absolute;
}
.homeSolutions-block .homeSolutions-block-title h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 32px;
  margin: 0 0 0 0;
}
.homeSolutions-block .homeSolutions-block-title span {
  font-size: 16px;
  display: block;
  line-height: 24px;
  margin-top: 4px;
  font-weight: 500;
  padding-bottom: 8px;
}
@media screen and (max-width: 579px) {
  .homeSolutions-block {
    margin: 0 0 32px 0;
    background-size: auto 300px;
    background-position: center top;
    background-color: #000;
    padding-top: 300px;
    max-height: none;
    height: auto;
    padding-bottom: 32px;
  }
  .homeSolutions-block .homeSolutions-block-title {
    padding: 16px 0 0 0;
    position: static;
  }
}

.home .homeSolutions-block h3 {
  transition: color 0.4s ease;
}
.home .homeSolutions-block:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background 0.4s ease;
  background: rgba(0, 0, 0, 0.05);
}
.home .homeSolutions-block:after {
  display: none;
}
.home .homeSolutions-block:hover:before {
  background: rgba(0, 0, 0, 0.3);
}
.home .homeSolutions-block:hover .homeSolutions-block-title h3 {
  color: #AF4EFB;
}

.page-strategy-creative-services .homeSolutions .homeSolutions-block,
.page-creative-design .homeSolutions .homeSolutions-block,
.page-enabling-technology .homeSolutions .homeSolutions-block,
.page-omnichannel-activation .homeSolutions .homeSolutions-block,
.page-supply-chain-management .homeSolutions .homeSolutions-block {
  padding-left: 16px;
  padding-right: 16px;
}
@media screen and (max-width: 767px) {
  .page-strategy-creative-services .homeSolutions .homeSolutions-block,
  .page-creative-design .homeSolutions .homeSolutions-block,
  .page-enabling-technology .homeSolutions .homeSolutions-block,
  .page-omnichannel-activation .homeSolutions .homeSolutions-block,
  .page-supply-chain-management .homeSolutions .homeSolutions-block {
    padding-bottom: 8px;
  }
}

.landingGrid {
  padding: 40px 32px;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1320px;
  margin: 0 auto;
}
.landingGrid:first-child {
  padding-top: 0;
}

.section-heading + .landingGrid {
  padding-top: 32px;
}

.landingGrid + .section-heading {
  margin-top: 40px;
}

.landingGrid-header {
  text-align: center;
  flex: 0 0 100%;
  width: 100%;
  -ms-flex: 0 1 auto;
  padding: 0 0 32px 0;
}
@media print, screen and (min-width: 900px) {
  .landingGrid-header {
    padding: 0 56px 40px 6px;
    flex: 0 0 50%;
    -ms-flex: 0 1 auto;
    width: 50%;
    text-align: left;
  }
}
@media print, screen and (min-width: 1200px) {
  .landingGrid-header {
    flex: 1 1 33.333333334%;
    -ms-flex: 0 1 auto;
    width: 33.333333334%;
  }
}

.landingGrid-title {
  font-size: 2em;
  line-height: 1.25;
  margin: 0 0 16px 0;
}
@media print, screen and (min-width: 900px) {
  .landingGrid-title {
    font-size: 2.5em;
    line-height: 1.4;
  }
}

.landingGrid-text {
  margin: 0;
  font-size: 1.125em;
  line-height: 1.7777777778;
}

.landingGrid-main {
  display: block;
  flex: 0 0 100%;
  width: 100%;
  -ms-flex: 0 1 auto;
  padding: 0 0 32px 0;
}
@media print, screen and (min-width: 900px) {
  .landingGrid-main {
    flex: 0 0 50%;
    -ms-flex: 0 1 auto;
    width: 50%;
    padding: 0 6px 40px 0;
    max-width: 816px;
  }
}
@media print, screen and (min-width: 1200px) {
  .landingGrid-main {
    flex: 0 0 66.666666666%;
    -ms-flex: 0 1 auto;
    width: 66.666666666%;
  }
}

.landingGrid-main-image {
  height: 204px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  border-radius: 4px;
}
@media print, screen and (min-width: 768px) {
  .landingGrid-main-image {
    height: 0;
    padding-top: 39.2156862745%;
  }
}

.landingGrid-main-title {
  font-size: 1.5em;
  line-height: 1.3333333333;
  margin: 16px 0 8px 0;
  padding: 0 16px;
}

.landingGrid-main-text {
  padding: 0 16px;
  margin: 0;
  font-size: 1em;
  line-height: 1.5;
}

.landing-block {
  display: block;
  padding: 16px 8px;
  opacity: 0;
  position: relative;
  transform: translateY(40px);
  transition: opacity 0.2s ease, transform 0.2s ease, margin 0.2s ease;
}
.landing-block.is-animated {
  opacity: 1;
  transform: translateY(0);
}

.landing-block {
  width: 100%;
  flex: 0 0 100%;
  -ms-flex: 0 1 auto;
}
@media print, screen and (min-width: 768px) {
  .landing-block {
    width: 50%;
    flex: 0 0 50%;
    -ms-flex: 0 1 auto;
  }
}
@media print, screen and (min-width: 900px) {
  .landing-block {
    width: 33.3333333333%;
    flex: 0 0 33.3333333333%;
    -ms-flex: 0 1 auto;
  }
}
@media print, screen and (min-width: 1024px) {
  .landing-block {
    width: 25%;
    flex: 0 0 25%;
    -ms-flex: 0 1 auto;
  }
}

.landing-block-image {
  background-color: rgba(37, 37, 37, 0.1);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 204px;
  display: block;
  border-radius: 4px;
  transition: opacity 0.2s ease;
}
@media print, screen and (min-width: 768px) {
  .landing-block-image {
    height: 0;
    padding-top: 67.5496688742%;
  }
}

.landing-block-inner {
  height: 100%;
  position: relative;
  overflow: hidden;
  text-decoration: none;
}
.landing-block-inner:hover {
  text-decoration: none;
}
.landing-block-inner:hover .landing-block-image {
  opacity: 0.75;
}
.landing-block-inner:hover h3 {
  color: #000;
}

.landing-block-info {
  padding: 0 8px;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  text-align: left;
}
@media print, screen and (min-width: 1500px) {
  .landing-block-info {
    padding: 0 16px;
  }
}

.landing-block-info-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: margin 0.2s ease;
  padding: 16px 32px 0 0;
  position: relative;
}
.landing-block-info-header h3 {
  color: #252525;
  font-size: 1.125em;
  line-height: 1.5555555556;
  margin: 0 0 8px 0;
  transition: color 0.2s ease;
}
@media print, screen and (min-width: 1500px) {
  .landing-block-info-header h3 {
    font-size: 1.25em;
    line-height: 1.4;
  }
}
.landing-block-info-header h3 .has-arrows {
  position: relative;
  display: inline-block;
  padding-right: 34px;
}
.landing-block-info-header .landing-arrow-start {
  position: absolute;
  right: 16px;
  top: 8px;
  height: 13px;
  width: 9px;
  opacity: 1;
  transition: opacity 0.2s ease;
}
.landing-block-info-header .landing-arrow-start * {
  fill: #252525;
}
.landing-block-info-header .landing-block-arrows {
  position: absolute;
  right: 0;
  top: 8px;
  width: 28px;
  height: 13px;
}
.landing-block-info-header .landing-block-arrows .landing-arrow {
  position: absolute;
  opacity: 0;
  top: 0;
  right: 8px;
}
.landing-block-info-header .landing-block-arrows .landing-arrow:first-of-type {
  right: 16px;
}
.landing-block-info-header .landing-block-arrows .landing-arrow:last-of-type {
  right: 0;
  opacity: 0;
}
.landing-block-info-header .landing-block-arrows .landing-arrow * {
  fill: black;
  transition: fill 0.2s ease;
}

.landing-block:hover .landing-arrow-start {
  opacity: 0;
}

.logos-wrap {
  padding: 40px 0;
}
@media print, screen and (min-width: 768px) {
  .logos-wrap {
    padding: 64px 0px 112px 0px;
  }
}
.logos-wrap .section-title {
  font-size: 24px;
  line-height: 30px;
}
@media print, screen and (min-width: 768px) {
  .logos-wrap .section-title {
    font-size: 32px;
    line-height: 42px;
    font-weight: 700;
  }
}

.logos {
  max-width: 1360px;
  margin: 24px auto 0 auto;
  padding: 0;
  position: relative;
  height: 420px;
  width: calc(100% - 64px);
}
@media print, screen and (min-width: 540px) {
  .logos {
    height: 280px;
    margin: 64px auto 0 auto;
  }
}
@media print, screen and (min-width: 960px) {
  .logos {
    height: 140px;
  }
}

.logos-item {
  position: absolute;
  top: 0;
  width: 20%;
  height: 140px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  padding: 40px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  -ms-flex: 0 1 auto;
  background: #fff;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.4s ease;
}
.logos-item img {
  max-height: 128px;
  width: auto;
  display: block;
}
.logos-item.column-1 {
  left: 0;
  border-left: none;
}
.logos-item.column-2 {
  left: 20%;
}
.logos-item.column-3 {
  left: 40%;
}
.logos-item.column-4 {
  left: 60%;
}
.logos-item.column-5 {
  left: 80%;
}
@media screen and (max-width: 959px) {
  .logos-item {
    width: 33.3333333333%;
    border-left: none;
  }
  .logos-item.column-1 {
    left: 0;
  }
  .logos-item.column-2 {
    left: 33.3333333333%;
  }
  .logos-item.column-3 {
    left: 66.6666666666%;
  }
  .logos-item.column-4 {
    top: 140px;
    left: 16.6666666%;
    border-left: none;
  }
  .logos-item.column-5 {
    top: 140px;
    left: 50%;
  }
}
@media screen and (max-width: 539px) {
  .logos-item {
    width: 50%;
  }
  .logos-item.column-1 {
    left: 0;
  }
  .logos-item.column-2 {
    left: 50%;
  }
  .logos-item.column-3 {
    top: 140px;
    left: 0;
    border-left: none;
  }
  .logos-item.column-4 {
    top: 140px;
    left: 50%;
  }
  .logos-item.column-5 {
    top: 280px;
    left: 25%;
    border-left: none;
  }
}

a.logos-item {
  transition: opacity 0.2s ease;
}
a.logos-item:hover {
  opacity: 0.7;
}

.logos-item.is-active {
  opacity: 1;
}

.map {
  margin: 64px 0 0 0;
  width: 100%;
}

.masonry {
  padding: 40px 32px 80px 32px;
}

.section-heading + .masonry {
  padding-top: 32px;
}

.masonry-grid {
  max-width: 1256px;
  margin: 0 auto;
}
.masonry-grid:after {
  content: "";
  display: table;
  clear: both;
}

.masonry-item {
  padding: 4px;
}
.masonry-item img {
  display: block;
}

.masonry-sizer {
  width: 50%;
}

.masonry-item-25 {
  width: 50%;
}

.masonry-item-50 {
  width: 100%;
}

@media print, screen and (min-width: 700px) {
  .masonry-sizer {
    width: 25%;
  }
  .masonry-item-25 {
    width: 25%;
  }
  .masonry-item-50 {
    width: 50%;
  }
}
.masonry-item img {
  opacity: 0;
  position: relative;
  top: 80px;
  transition: opacity 0.8s ease 0.2s, top 0.8s ease 0.2s, transform 0.2s ease, box-shadow 0.2s ease;
}

.masonry-item.is-animated img {
  opacity: 1;
  top: 0;
}

.modal-trigger {
  cursor: pointer;
}
.modal-trigger:hover img {
  /*
  			transform: scale(1.05);
  			z-index: 2;		
  			box-shadow: 0 0 8px rgba(0,0,0,0.2);	
  */
}

.modal-overlay {
  background: #252525;
  opacity: 0.8;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 130;
  display: none;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 131;
  display: none;
}

.modal-inner {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-wrap {
  position: relative;
  max-width: 80%;
}

.modal-inner img {
  max-width: 100%;
  max-height: 80vh;
  height: auto;
  width: auto;
  position: relative;
  z-index: 132;
}

.modal-close {
  position: absolute;
  top: -16px;
  right: -16px;
  z-index: 133;
  background: #000;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
  transition: background 0.2s ease;
}
.modal-close:hover {
  background: black;
}
.modal-close svg {
  height: 16px;
  width: 16px;
}
.modal-close svg * {
  fill: #fff;
}

.hamburger-nav,
.search-nav {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 120;
  background: rgba(37, 37, 37, 0.9);
}

.nav-open .hamburger-nav {
  display: block;
}

.search-open .search-nav {
  display: block;
}

.hamburger-nav-inner {
  height: calc(100% - 64px);
  margin-top: 64px;
  overflow: auto;
  display: block;
  position: relative;
}

.hamburger-nav-inner-nav {
  margin: 0;
  list-style: none;
  padding: 0 16px;
}
.hamburger-nav-inner-nav li {
  font-size: 2em;
  line-height: 1;
  padding: 0 0 0 40px;
  font-weight: 400;
  text-align: left;
  margin: 40px 0 0 0;
  position: relative;
}
.hamburger-nav-inner-nav li a {
  display: inline-block;
  color: #fff;
}
.hamburger-nav-inner-nav li a:hover {
  text-decoration: none;
  transition: color 0.2s ease;
}

.hamburger-nav-inner-nav .sub-menu {
  display: none;
  padding: 0;
  margin: 0;
}
.hamburger-nav-inner-nav .sub-menu li {
  font-size: 18px;
  line-height: 32px;
  padding: 0 0 0 16px;
  margin: 8px 0 0 0;
  font-weight: 400;
}
.hamburger-nav-inner-nav .sub-menu li:first-of-type {
  margin-top: 16px;
}

.sub-menu-toggle {
  position: absolute;
  width: 32px;
  height: 32px;
  left: 0;
  top: 0px;
  cursor: pointer;
  background: transparent;
}
.sub-menu-toggle:after, .sub-menu-toggle:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
}
.sub-menu-toggle:after {
  width: 2px;
  height: 24px;
  transition: height 0.2s ease;
}
.sub-menu-toggle:before {
  height: 2px;
  width: 24px;
}
.sub-menu-toggle.is-active:after {
  height: 2px;
}

#search.search-form {
  padding: 0 16px 0 56px;
  max-width: 400px;
  margin: 40px 0 0 0;
  border: none;
}
#search.search-form input {
  font-size: 24px;
  background: rgba(255, 255, 255, 0.2);
  line-height: 48px;
  height: 48px;
}
#search.search-form .submit {
  border-radius: 0;
  height: 48px;
  padding: 12px 16px;
}

.search-nav-search {
  max-width: 1200px;
  margin: 40px auto 0 auto;
  padding: 0 16px;
}
@media print, screen and (min-width: 768px) {
  .search-nav-search {
    padding: 0 80px;
    margin: 80px auto 0 auto;
  }
}

.search-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #fff;
  padding-bottom: 14px;
}
.search-form .field {
  width: auto;
  flex: 1 1 auto;
  background: transparent;
  padding: 0 16px;
  margin: 0;
  font-size: 2em;
  line-height: 2;
  color: #fff;
  max-width: calc(100% - 64px);
}
@media print, screen and (min-width: 768px) {
  .search-form .field {
    font-size: 4em;
    line-height: 1;
  }
}
.search-form .field::placeholder {
  opacity: 1;
}

.submit {
  cursor: pointer;
  flex: 0 0 auto;
  height: 64px;
  width: 64px;
  padding: 20px;
  border-radius: 50%;
  background: #000;
  transition: background 0.2s ease;
}
.submit svg * {
  fill: #fff;
}
.submit:hover {
  background: rgba(0, 0, 0, 0.7);
}

.offices {
  max-width: 1400px;
  padding: 0 16px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 64px;
}
@media print, screen and (min-width: 768px) {
  .offices {
    padding: 0 32px;
    margin-top: 120px;
  }
}

.office {
  padding: 20px;
  width: 100%;
  flex: 0 0 100%;
  -ms-flex: 0 1 auto;
  text-align: center;
}
@media print, screen and (min-width: 400px) {
  .office {
    width: 50%;
    flex: 0 0 50%;
    -ms-flex: 0 1 auto;
    text-align: left;
  }
}
@media print, screen and (min-width: 768px) {
  .office {
    padding: 20px 40px;
  }
}
@media print, screen and (min-width: 1024px) {
  .office {
    width: 33.3333333333%;
    flex: 0 0 33.3333333333%;
    -ms-flex: 0 1 auto;
  }
}
@media print, screen and (min-width: 1200px) {
  .office {
    width: 25%;
    flex: 0 0 25%;
    -ms-flex: 0 1 auto;
  }
}

.office-title {
  font-size: 1.25em;
  line-height: 1.6;
  margin: 0 0 16px 0;
}
@media print, screen and (min-width: 560px) {
  .office-title {
    font-size: 1.5em;
    line-height: 1.3333333333;
  }
}

.office-info {
  font-size: 0.875em;
  line-height: 1.7142857143;
  margin: 0;
}
@media print, screen and (min-width: 560px) {
  .office-info {
    font-size: 1.125em;
    line-height: 1.7777777778;
  }
}

.search-results-wrapper {
  max-width: 800px;
  padding: 0 16px;
}
@media print, screen and (min-width: 768px) {
  .search-results-wrapper {
    padding: 0 32px;
  }
}

.search-result {
  margin: 0 0 32px 0;
  padding: 0 0 32px 0;
  border-bottom: 1px solid #ccc;
}
.search-result a {
  font-size: 1.25em;
  font-weight: 400;
  display: inline-block;
  margin: 0 0 8px 0;
}

.search-result-meta {
  margin: 0;
  font-size: 1em;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pagination .hidden {
  visibility: hidden;
}
.pagination a {
  padding: 8px 16px;
}
.pagination a svg {
  margin-right: 8px;
  display: block;
  width: 12px;
  height: 12px;
}
.pagination a.next svg {
  margin-left: 8px;
  margin-right: 0;
}
.pagination a svg * {
  fill: #fff;
}

.section-heading {
  max-width: 800px;
  padding: 0 16px;
  text-align: center;
  margin-top: 48px;
  margin-bottom: 32px;
}
@media print, screen and (min-width: 560px) {
  .section-heading {
    padding: 0 32px;
    margin-top: 64px;
    margin-bottom: 48px;
  }
}
.section-heading:first-child {
  margin-top: 0;
}

.section-title {
  margin: 0;
  position: relative;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
}
@media print, screen and (min-width: 768px) {
  .section-title {
    font-size: 53px;
    line-height: 72px;
  }
}
@media print, screen and (min-width: 1024px) {
  .section-title {
    font-size: 72px;
    line-height: 78px;
  }
}
.section-title.has-underline {
  padding-bottom: 32px;
}
.section-title.has-underline:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 636px;
  height: 8px;
  background: #000;
}

.section-subtitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  max-width: 1020px;
  margin-left: auto;
  margin-right: auto;
}

.section-heading-split {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 32px 0 0 0;
}

.section-heading-split-half {
  flex: 1 1 50%;
  -ms-flex: 0 1 auto;
  min-width: 50%;
  text-align: center;
  padding: 0 32px;
  font-size: 1.125em;
  line-height: 1;
}

.servicesCta {
  padding: 100px 20px 80px;
}
@media print, screen and (min-width: 1024px) {
  .servicesCta {
    padding: 160px 20px 100px;
  }
}
.servicesCta h2 {
  font-size: 40px;
  text-align: center;
  margin-bottom: 45px;
}
@media print, screen and (min-width: 768px) {
  .servicesCta h2 {
    font-size: 50px;
    margin-bottom: 55px;
  }
}
.servicesCta .otherServices {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
@media print, screen and (min-width: 768px) {
  .servicesCta .otherServices {
    flex-direction: row;
    gap: 40px;
  }
}
.servicesCta .otherServices .otherService {
  flex: 1;
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  position: relative;
}
.servicesCta .otherServices .otherService:hover h3 {
  color: #AF4EFB;
}
.servicesCta .otherServices .otherService:hover .otherServiceImg:after {
  background: rgba(0, 0, 0, 0.3);
}
.servicesCta .otherServices .otherServiceImg {
  padding-top: 92%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.servicesCta .otherServices .otherServiceImg:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  transition: background 0.2s ease, color 0.2s ease;
}
.servicesCta .otherServices .homeSolutions-block-title {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  background-color: #000;
  width: 100%;
}
.servicesCta .otherServices .homeSolutions-block-title h3 {
  margin-bottom: 0;
  transition: color 0.2s ease, color 0.2s ease;
  padding: 8px 24px;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
}

.homeServices + .servicesCta {
  padding-top: 0;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
  border-radius: 4px;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slider-wrap {
  max-width: 1400px;
  padding: 40px 20px;
  margin: 0 auto;
}
.slider-wrap:last-of-type {
  padding-bottom: 0;
}
@media print, screen and (min-width: 940px) {
  .slider-wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.slider-text {
  padding: 0 0 16px 0;
}
@media print, screen and (min-width: 940px) {
  .slider-text {
    flex: 0 0 auto;
    -ms-flex: 0 1 auto;
    max-width: 360px;
  }
}
@media print, screen and (min-width: 1160px) {
  .slider-text {
    max-width: 460px;
    padding: 0 56px 0 0;
  }
}

@media print, screen and (min-width: 940px) {
  .slider-slider {
    flex: 1 1 900px;
    -ms-flex: 0 1 auto;
    width: 100%;
    max-width: calc(100% - 360px);
  }
}
@media print, screen and (min-width: 1160px) {
  .slider-slider {
    max-width: calc(100% - 460px);
  }
}

.slider-text-title {
  font-size: 2em;
  line-height: 1.25;
  margin: 0 0 16px 0;
}
@media print, screen and (min-width: 900px) {
  .slider-text-title {
    font-size: 2.5em;
    line-height: 1.4;
  }
}

.slider-text p a {
  font-weight: 400;
}

.slider {
  width: 100%;
}

.slide-caption {
  position: relative;
  z-index: 1;
  padding: 16px;
  border: 1px solid #000;
  background: #fff;
  margin: 0;
  border-top: 0;
  box-shadow: 0px 0px 8px rgba(37, 37, 37, 0.15);
}
@media print, screen and (min-width: 600px) {
  .slide-caption {
    padding: 24px 32px;
    max-width: calc(100% - 80px);
    margin: -64px auto 0 auto;
    border-top: 1px solid #000;
  }
}

.slide-caption-title {
  font-size: 1.25em;
  line-height: 1.6;
  margin: 0 0 8px 0;
}

.slide-caption-text {
  font-size: 1em;
  line-height: 1.5;
  margin: 0;
}

.slider-arrows-wrap {
  margin: 40px 0 0 0;
}
@media print, screen and (min-width: 940px) {
  .slider-arrows-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.slider-dots {
  display: none;
}
@media print, screen and (min-width: 940px) {
  .slider-dots {
    display: block;
  }
  .slider-dots ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .slider-dots ul li {
    margin: 0 12px 0 0;
    padding: 0;
  }
  .slider-dots .slick-slider-button {
    width: 16px;
    height: 16px;
    background: #252525;
    border-radius: 50%;
    cursor: pointer;
  }
  .slider-dots .slick-slider-button:hover {
    background: #000;
  }
  .slider-dots .slick-active .slick-slider-button {
    background: #000;
  }
}

.slider-arrows {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media print, screen and (min-width: 940px) {
  .slider-arrows {
    justify-content: flex-end;
    margin: 0 0 0 20px;
  }
}

.slider-arrow {
  flex: 0 0 56px;
  -ms-flex: 0 1 auto;
  width: 56px;
  height: 56px;
  border: 2px solid #000;
  background: #fff;
  border-radius: 50%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.2s ease;
}
.slider-arrow.next-arrow {
  margin-left: 8px;
}
.slider-arrow svg {
  display: block;
}
.slider-arrow svg * {
  fill: #000;
  transition: fill 0.2s ease;
}
.slider-arrow:hover {
  background: #000;
}
.slider-arrow:hover svg * {
  fill: #fff;
}

.white-cover {
  background-color: #fff;
  z-index: 997;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0px;
  left: 0px;
}

.splash-intro {
  position: fixed;
  z-index: 999;
  background-color: #fff;
  display: none;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.splash-intro.active {
  display: block;
  opacity: 1;
}
.splash-intro.is-complete {
  opacity: 0;
  pointer-events: none;
  transition: opacity 1.3s linear;
  -moz-transition: opacity 1.3s linear;
  -webkit-transition: opacity 1.3s linear;
  -o-transition: opacity 1.3s linear;
}
.splash-intro video {
  position: absolute;
  max-width: 850px;
  min-height: 100vh;
  width: auto;
  height: auto;
  position: absolute;
  top: 28%;
  left: 50.25%;
  transform: translate(-50%, -50%);
}
@media print, screen and (min-width: 600px) {
  .splash-intro video {
    max-width: 1100px;
  }
}
@media print, screen and (min-width: 768px) {
  .splash-intro video {
    max-width: 1350px;
    top: 49%;
  }
}
@media print, screen and (min-width: 1024px) {
  .splash-intro video {
    max-width: 1750px;
  }
}

.splash-intro-mb {
  position: fixed;
  z-index: 999;
  background-color: #fff;
  display: none;
  transition: none;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.splash-intro-mb.active {
  display: block;
  opacity: 1;
}
.splash-intro-mb.is-complete {
  opacity: 0;
  pointer-events: none;
  transition: opacity 1.3s linear;
  transition: opacity 1.3s linear;
  -moz-transition: opacity 1.3s linear;
  -webkit-transition: opacity 1.3s linear;
  -o-transition: opacity 1.3s linear;
}
.splash-intro-mb video {
  position: absolute;
  max-width: 370px;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  transform: translate(-50%, -50%);
}
@media print, screen and (min-width: 475px) {
  .splash-intro-mb video {
    max-width: 500px;
    top: 44%;
    left: 48.25%;
  }
}
@media print, screen and (min-width: 600px) {
  .splash-intro-mb video {
    max-width: 600px;
  }
}

.tabs {
  margin: 80px auto;
  padding: 0 16px;
}

.tabs-wrap {
  max-width: 1400px;
}

.tabs-nav {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  background: #000;
}

.tabs-nav-button {
  border-top: 8px solid #000;
  border-bottom: 8px solid transparent;
  background: transparent;
  line-height: 40px;
  font-size: 1.125em;
  font-weight: 400;
  margin: 0 8px 0 8px;
  cursor: pointer;
  padding: 8px 24px 0 24px;
  color: #fff;
}
.tabs-nav-button.is-active {
  color: #000;
  background: #fff;
}

.tabs-container {
  border: 2px solid #000;
  border-top: none;
  padding: 16px;
}
@media print, screen and (min-width: 600px) {
  .tabs-container {
    padding: 48px;
  }
}

.tabs-panel {
  display: none;
}
.tabs-panel.is-active {
  display: block;
}

@media print, screen and (min-width: 1000px) {
  .tabs-panel-inner {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
}

.tabs-panel-text {
  width: 100%;
  max-width: 100%;
  flex: 1 1 50%;
  -ms-flex: 0 1 auto;
}

.tabs-panel-monitor {
  width: 100%;
  max-width: 936px;
  display: block;
}

.tabs-panel-monitor-screen {
  position: relative;
  max-width: 675px;
  width: 100%;
  margin: 32px auto 0 auto;
}
.tabs-panel-monitor-screen img {
  display: block;
}

.tabs-panel-monitor-graphic {
  position: absolute;
  top: 4.630969609%;
  left: 50%;
  transform: translateX(-50%);
  width: 91.85185185%;
  z-index: 75;
  overflow: hidden;
}

.tabs-panel-monitor-graphic-inner {
  width: 100%;
  padding-top: 56.1904761905%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}
.tabs-panel-monitor-graphic-inner video {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.tabs-panel-text p,
.tabs-panel-monitor-text p {
  font-size: 1.125em;
  line-height: 1.5;
  text-align: center;
}
.tabs-panel-text p:last-of-type,
.tabs-panel-monitor-text p:last-of-type {
  margin-bottom: 0;
}

.tabs-panel-monitor-text {
  font-size: 18px;
  line-height: 32px;
  text-align: center;
}

.tabs-panel-media {
  width: 100%;
  -ms-flex: 0 1 auto;
  padding: 40px 0 0 0;
}
@media print, screen and (min-width: 1000px) {
  .tabs-panel-media {
    padding: 0 0 0 40px;
    max-width: 600px;
    flex: 0 0 50%;
  }
}
@media print, screen and (min-width: 1200px) {
  .tabs-panel-media {
    padding: 0 0 0 64px;
  }
}
.tabs-panel-media .tabs-panel-media-video {
  padding-bottom: 56.25%;
  height: 0;
  position: relative;
}
.tabs-panel-media iframe,
.tabs-panel-media object,
.tabs-panel-media embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.tabs-panel-title {
  font-size: 24px;
  line-height: 1.3333333333;
  font-weight: 400;
  margin: 0 0 16px 0;
}
@media print, screen and (min-width: 620px) {
  .tabs-panel-title {
    display: none;
  }
}

.plainText,
.video-player-container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 16px;
}
@media print, screen and (min-width: 560px) {
  .plainText,
  .video-player-container {
    padding: 0 32px;
  }
}

.two-two-grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  padding-left: 32px;
  padding-right: 32px;
  gap: 50px;
}
@media print, screen and (min-width: 768px) {
  .two-two-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.two-two-grid .grid-item {
  text-align: center;
  background-color: #777;
  width: 100%;
  height: 200px;
}

.values {
  padding-bottom: 80px;
}
@media print, screen and (min-width: 1024px) {
  .values {
    padding-bottom: 160px;
  }
}
.values .container {
  max-width: 1388px;
  padding: 0 24px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px 40px;
}
@media print, screen and (min-width: 660px) {
  .values .container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media print, screen and (min-width: 1024px) {
  .values .container {
    gap: 24px 100px;
  }
}
@media print, screen and (min-width: 1500px) {
  .values .container {
    gap: 24px 160px;
  }
}
.values .values-box {
  opacity: 0;
  transform: translateY(40px);
}
.values .values-title {
  font-weight: 300;
  position: relative;
}
.values .values-title:after {
  content: "";
  position: absolute;
  top: calc(100% + 0.6em);
  left: 0;
  width: 272px;
  height: 2px;
  background: #AF4EFB;
}
@media print, screen and (min-width: 1024px) {
  .values .values-title {
    font-size: 50px;
    margin: 0 0 50px 0;
  }
}

.content.video-bg {
  min-height: 300px;
  padding: 120px 0;
  text-align: center;
  position: relative;
}
@media print, screen and (min-width: 768px) {
  .content.video-bg {
    padding: 240px 0;
  }
}
.content.video-bg .container {
  position: relative;
  z-index: 2;
}
@media print, screen and (min-width: 1500px) {
  .content.video-bg .hero-title {
    font-size: 5em;
    line-height: 1.2;
  }
}

.video-block {
  position: relative;
  overflow: hidden;
  padding-bottom: 0;
  padding-top: 56.25%;
}

.sustainability {
  background-position: 25% 50%;
  background-repeat: no-repeat;
  background-size: cover !important;
  background: #252525;
  background-image: url("../images/sustain-mb-xs.jpg");
}
@media print, screen and (min-width: 560px) {
  .sustainability {
    background-image: url("../images/sustain-mb.jpg");
  }
}
@media print, screen and (min-width: 1300px) {
  .sustainability {
    background-image: url("../images/sustain.jpg");
  }
}
.sustainability .container {
  padding: 0 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.sustain-content {
  padding: 70px 0;
  max-width: 750px;
  padding-bottom: 40px;
}
@media print, screen and (min-width: 768px) {
  .sustain-content {
    padding: 100px 0;
  }
}
@media print, screen and (min-width: 1200px) {
  .sustain-content {
    padding: 150px 0;
  }
}
@media print, screen and (min-width: 1300px) {
  .sustain-content {
    padding: 100px 0;
  }
}
@media print, screen and (min-width: 2000px) {
  .sustain-content {
    padding: 175px 0;
  }
}
@media print, screen and (min-width: 2500px) {
  .sustain-content {
    padding: 250px 0;
  }
}
.sustain-content h3 {
  color: #fff;
  font-size: 26px;
  line-height: 45px;
  margin-bottom: 20px;
}
@media print, screen and (min-width: 320px) {
  .sustain-content h3 {
    font-size: 28px;
  }
}
@media print, screen and (min-width: 390px) {
  .sustain-content h3 {
    font-size: 35px;
  }
}
@media print, screen and (min-width: 768px) {
  .sustain-content h3 {
    font-size: 45px;
    line-height: 54px;
  }
}
.sustain-content p {
  color: #fff;
  font-size: 22px;
  margin-bottom: 0;
  margin-top: 0;
  position: relative;
  padding-left: 28px;
}
@media print, screen and (min-width: 768px) {
  .sustain-content p {
    font-size: 28px;
  }
}
.sustain-content p:before {
  content: "";
  position: absolute;
  top: 13px;
  left: 0;
  height: 75%;
  border-left: 5px solid #AF4EFB;
}

.grid-v2 {
  display: grid;
  grid-template-columns: 100%;
}

@media print, screen and (min-width: 560px) {
  .sm-2s {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media print, screen and (min-width: 560px) {
  .sm-3s {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media print, screen and (min-width: 560px) {
  .sm-4s {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media print, screen and (min-width: 768px) {
  .md-2s {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media print, screen and (min-width: 768px) {
  .md-3s {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media print, screen and (min-width: 768px) {
  .md-4s {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media print, screen and (min-width: 1024px) {
  .lg-2s {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media print, screen and (min-width: 1024px) {
  .lg-3s {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media print, screen and (min-width: 1024px) {
  .lg-4s {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media print, screen and (min-width: 1500px) {
  .xl-2s {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media print, screen and (min-width: 1500px) {
  .xl-3s {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media print, screen and (min-width: 1500px) {
  .xl-4s {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

@media print, screen and (min-width: 1500px) {
  .xl-5s {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

.grid-v2.align-start {
  align-items: start;
}

.gw-100 {
  grid-column: 1/-1;
}

@media print, screen and (min-width: 1024px) {
  .lg-order-last {
    order: 10;
  }
}
.gap-0 {
  gap: 0;
}

.gap-4 {
  gap: 4px;
}

.gap-8 {
  gap: 8px;
}

.gap-12 {
  gap: 12px;
}

.gap-16 {
  gap: 16px;
}

.gap-20 {
  gap: 20px;
}

.gap-24 {
  gap: 24px;
}

.gap-28 {
  gap: 28px;
}

.gap-32 {
  gap: 32px;
}

.gap-36 {
  gap: 36px;
}

.gap-40 {
  gap: 40px;
}

.gap-44 {
  gap: 44px;
}

.gap-48 {
  gap: 48px;
}

.gap-52 {
  gap: 52px;
}

.gap-56 {
  gap: 56px;
}

.gap-60 {
  gap: 60px;
}

.gap-64 {
  gap: 64px;
}

.gap-68 {
  gap: 68px;
}

.gap-72 {
  gap: 72px;
}

.gap-76 {
  gap: 76px;
}

.gap-80 {
  gap: 80px;
}

.gap-84 {
  gap: 84px;
}

.gap-88 {
  gap: 88px;
}

.gap-92 {
  gap: 92px;
}

.gap-96 {
  gap: 96px;
}

.gap-100 {
  gap: 100px;
}

.gap-104 {
  gap: 104px;
}

.gap-108 {
  gap: 108px;
}

.gap-112 {
  gap: 112px;
}

.gap-116 {
  gap: 116px;
}

.gap-120 {
  gap: 120px;
}

.gap-x-0 {
  column-gap: 0;
}

.gap-x-4 {
  column-gap: 4px;
}

.gap-x-8 {
  column-gap: 8px;
}

.gap-x-12 {
  column-gap: 12px;
}

.gap-x-16 {
  column-gap: 16px;
}

.gap-x-20 {
  column-gap: 20px;
}

.gap-x-24 {
  column-gap: 24px;
}

.gap-x-28 {
  column-gap: 28px;
}

.gap-x-32 {
  column-gap: 32px;
}

.gap-x-36 {
  column-gap: 36px;
}

.gap-x-40 {
  column-gap: 40px;
}

.gap-x-44 {
  column-gap: 44px;
}

.gap-x-48 {
  column-gap: 48px;
}

.gap-x-52 {
  column-gap: 52px;
}

.gap-x-56 {
  column-gap: 56px;
}

.gap-x-60 {
  column-gap: 60px;
}

.gap-x-64 {
  column-gap: 64px;
}

.gap-x-68 {
  column-gap: 68px;
}

.gap-x-72 {
  column-gap: 72px;
}

.gap-x-76 {
  column-gap: 76px;
}

.gap-x-80 {
  column-gap: 80px;
}

.gap-x-84 {
  column-gap: 84px;
}

.gap-x-88 {
  column-gap: 88px;
}

.gap-x-92 {
  column-gap: 92px;
}

.gap-x-96 {
  column-gap: 96px;
}

.gap-x-100 {
  column-gap: 100px;
}

.gap-x-104 {
  column-gap: 104px;
}

.gap-x-108 {
  column-gap: 108px;
}

.gap-x-112 {
  column-gap: 112px;
}

.gap-x-116 {
  column-gap: 116px;
}

.gap-x-120 {
  column-gap: 120px;
}

.gap-y-0 {
  row-gap: 0;
}

.gap-y-4 {
  row-gap: 4px;
}

.gap-y-8 {
  row-gap: 8px;
}

.gap-y-12 {
  row-gap: 12px;
}

.gap-y-16 {
  row-gap: 16px;
}

.gap-y-20 {
  row-gap: 20px;
}

.gap-y-24 {
  row-gap: 24px;
}

.gap-y-28 {
  row-gap: 28px;
}

.gap-y-32 {
  row-gap: 32px;
}

.gap-y-36 {
  row-gap: 36px;
}

.gap-y-40 {
  row-gap: 40px;
}

.gap-y-44 {
  row-gap: 44px;
}

.gap-y-48 {
  row-gap: 48px;
}

.gap-y-52 {
  row-gap: 52px;
}

.gap-y-56 {
  row-gap: 56px;
}

.gap-y-60 {
  row-gap: 60px;
}

.gap-y-64 {
  row-gap: 64px;
}

.gap-y-68 {
  row-gap: 68px;
}

.gap-y-72 {
  row-gap: 72px;
}

.gap-y-76 {
  row-gap: 76px;
}

.gap-y-80 {
  row-gap: 80px;
}

.gap-y-84 {
  row-gap: 84px;
}

.gap-y-88 {
  row-gap: 88px;
}

.gap-y-92 {
  row-gap: 92px;
}

.gap-y-96 {
  row-gap: 96px;
}

.gap-y-100 {
  row-gap: 100px;
}

.gap-y-104 {
  row-gap: 104px;
}

.gap-y-108 {
  row-gap: 108px;
}

.gap-y-112 {
  row-gap: 112px;
}

.gap-y-116 {
  row-gap: 116px;
}

.gap-y-120 {
  row-gap: 120px;
}

@media print, screen and (min-width: 768px) {
  .md-gap-0 {
    gap: 0;
  }
  .md-gap-4 {
    gap: 4px;
  }
  .md-gap-8 {
    gap: 8px;
  }
  .md-gap-12 {
    gap: 12px;
  }
  .md-gap-16 {
    gap: 16px;
  }
  .md-gap-20 {
    gap: 20px;
  }
  .md-gap-24 {
    gap: 24px;
  }
  .md-gap-28 {
    gap: 28px;
  }
  .md-gap-32 {
    gap: 32px;
  }
  .md-gap-36 {
    gap: 36px;
  }
  .md-gap-40 {
    gap: 40px;
  }
  .md-gap-44 {
    gap: 44px;
  }
  .md-gap-48 {
    gap: 48px;
  }
  .md-gap-52 {
    gap: 52px;
  }
  .md-gap-56 {
    gap: 56px;
  }
  .md-gap-60 {
    gap: 60px;
  }
  .md-gap-64 {
    gap: 64px;
  }
  .md-gap-68 {
    gap: 68px;
  }
  .md-gap-72 {
    gap: 72px;
  }
  .md-gap-76 {
    gap: 76px;
  }
  .md-gap-80 {
    gap: 80px;
  }
  .md-gap-84 {
    gap: 84px;
  }
  .md-gap-88 {
    gap: 88px;
  }
  .md-gap-92 {
    gap: 92px;
  }
  .md-gap-96 {
    gap: 96px;
  }
  .md-gap-100 {
    gap: 100px;
  }
  .md-gap-104 {
    gap: 104px;
  }
  .md-gap-108 {
    gap: 108px;
  }
  .md-gap-112 {
    gap: 112px;
  }
  .md-gap-116 {
    gap: 116px;
  }
  .md-gap-120 {
    gap: 120px;
  }
  .md-gap-x-0 {
    column-gap: 0;
  }
  .md-gap-x-4 {
    column-gap: 4px;
  }
  .md-gap-x-8 {
    column-gap: 8px;
  }
  .md-gap-x-12 {
    column-gap: 12px;
  }
  .md-gap-x-16 {
    column-gap: 16px;
  }
  .md-gap-x-20 {
    column-gap: 20px;
  }
  .md-gap-x-24 {
    column-gap: 24px;
  }
  .md-gap-x-28 {
    column-gap: 28px;
  }
  .md-gap-x-32 {
    column-gap: 32px;
  }
  .md-gap-x-36 {
    column-gap: 36px;
  }
  .md-gap-x-40 {
    column-gap: 40px;
  }
  .md-gap-x-44 {
    column-gap: 44px;
  }
  .md-gap-x-48 {
    column-gap: 48px;
  }
  .md-gap-x-52 {
    column-gap: 52px;
  }
  .md-gap-x-56 {
    column-gap: 56px;
  }
  .md-gap-x-60 {
    column-gap: 60px;
  }
  .md-gap-x-64 {
    column-gap: 64px;
  }
  .md-gap-x-68 {
    column-gap: 68px;
  }
  .md-gap-x-72 {
    column-gap: 72px;
  }
  .md-gap-x-76 {
    column-gap: 76px;
  }
  .md-gap-x-80 {
    column-gap: 80px;
  }
  .md-gap-x-84 {
    column-gap: 84px;
  }
  .md-gap-x-88 {
    column-gap: 88px;
  }
  .md-gap-x-92 {
    column-gap: 92px;
  }
  .md-gap-x-96 {
    column-gap: 96px;
  }
  .md-gap-x-100 {
    column-gap: 100px;
  }
  .md-gap-x-104 {
    column-gap: 104px;
  }
  .md-gap-x-108 {
    column-gap: 108px;
  }
  .md-gap-x-112 {
    column-gap: 112px;
  }
  .md-gap-x-116 {
    column-gap: 116px;
  }
  .md-gap-x-120 {
    column-gap: 120px;
  }
  .md-gap-y-0 {
    row-gap: 0;
  }
  .md-gap-y-4 {
    row-gap: 4px;
  }
  .md-gap-y-8 {
    row-gap: 8px;
  }
  .md-gap-y-12 {
    row-gap: 12px;
  }
  .md-gap-y-16 {
    row-gap: 16px;
  }
  .md-gap-y-20 {
    row-gap: 20px;
  }
  .md-gap-y-24 {
    row-gap: 24px;
  }
  .md-gap-y-28 {
    row-gap: 28px;
  }
  .md-gap-y-32 {
    row-gap: 32px;
  }
  .md-gap-y-36 {
    row-gap: 36px;
  }
  .md-gap-y-40 {
    row-gap: 40px;
  }
  .md-gap-y-44 {
    row-gap: 44px;
  }
  .md-gap-y-48 {
    row-gap: 48px;
  }
  .md-gap-y-52 {
    row-gap: 52px;
  }
  .md-gap-y-56 {
    row-gap: 56px;
  }
  .md-gap-y-60 {
    row-gap: 60px;
  }
  .md-gap-y-64 {
    row-gap: 64px;
  }
  .md-gap-y-68 {
    row-gap: 68px;
  }
  .md-gap-y-72 {
    row-gap: 72px;
  }
  .md-gap-y-76 {
    row-gap: 76px;
  }
  .md-gap-y-80 {
    row-gap: 80px;
  }
  .md-gap-y-84 {
    row-gap: 84px;
  }
  .md-gap-y-88 {
    row-gap: 88px;
  }
  .md-gap-y-92 {
    row-gap: 92px;
  }
  .md-gap-y-96 {
    row-gap: 96px;
  }
  .md-gap-y-100 {
    row-gap: 100px;
  }
  .md-gap-y-104 {
    row-gap: 104px;
  }
  .md-gap-y-108 {
    row-gap: 108px;
  }
  .md-gap-y-112 {
    row-gap: 112px;
  }
  .md-gap-y-116 {
    row-gap: 116px;
  }
  .md-gap-y-120 {
    row-gap: 120px;
  }
}
@media print, screen and (min-width: 1500px) {
  .lg-gap-0 {
    gap: 0;
  }
  .lg-gap-4 {
    gap: 4px;
  }
  .lg-gap-8 {
    gap: 8px;
  }
  .lg-gap-12 {
    gap: 12px;
  }
  .lg-gap-16 {
    gap: 16px;
  }
  .lg-gap-20 {
    gap: 20px;
  }
  .lg-gap-24 {
    gap: 24px;
  }
  .lg-gap-28 {
    gap: 28px;
  }
  .lg-gap-32 {
    gap: 32px;
  }
  .lg-gap-36 {
    gap: 36px;
  }
  .lg-gap-40 {
    gap: 40px;
  }
  .lg-gap-44 {
    gap: 44px;
  }
  .lg-gap-48 {
    gap: 48px;
  }
  .lg-gap-52 {
    gap: 52px;
  }
  .lg-gap-56 {
    gap: 56px;
  }
  .lg-gap-60 {
    gap: 60px;
  }
  .lg-gap-64 {
    gap: 64px;
  }
  .lg-gap-68 {
    gap: 68px;
  }
  .lg-gap-72 {
    gap: 72px;
  }
  .lg-gap-76 {
    gap: 76px;
  }
  .lg-gap-80 {
    gap: 80px;
  }
  .lg-gap-84 {
    gap: 84px;
  }
  .lg-gap-88 {
    gap: 88px;
  }
  .lg-gap-92 {
    gap: 92px;
  }
  .lg-gap-96 {
    gap: 96px;
  }
  .lg-gap-100 {
    gap: 100px;
  }
  .lg-gap-104 {
    gap: 104px;
  }
  .lg-gap-108 {
    gap: 108px;
  }
  .lg-gap-112 {
    gap: 112px;
  }
  .lg-gap-116 {
    gap: 116px;
  }
  .lg-gap-120 {
    gap: 120px;
  }
  .lg-gap-x-0 {
    column-gap: 0;
  }
  .lg-gap-x-4 {
    column-gap: 4px;
  }
  .lg-gap-x-8 {
    column-gap: 8px;
  }
  .lg-gap-x-12 {
    column-gap: 12px;
  }
  .lg-gap-x-16 {
    column-gap: 16px;
  }
  .lg-gap-x-20 {
    column-gap: 20px;
  }
  .lg-gap-x-24 {
    column-gap: 24px;
  }
  .lg-gap-x-28 {
    column-gap: 28px;
  }
  .lg-gap-x-32 {
    column-gap: 32px;
  }
  .lg-gap-x-36 {
    column-gap: 36px;
  }
  .lg-gap-x-40 {
    column-gap: 40px;
  }
  .lg-gap-x-44 {
    column-gap: 44px;
  }
  .lg-gap-x-48 {
    column-gap: 48px;
  }
  .lg-gap-x-52 {
    column-gap: 52px;
  }
  .lg-gap-x-56 {
    column-gap: 56px;
  }
  .lg-gap-x-60 {
    column-gap: 60px;
  }
  .lg-gap-x-64 {
    column-gap: 64px;
  }
  .lg-gap-x-68 {
    column-gap: 68px;
  }
  .lg-gap-x-72 {
    column-gap: 72px;
  }
  .lg-gap-x-76 {
    column-gap: 76px;
  }
  .lg-gap-x-80 {
    column-gap: 80px;
  }
  .lg-gap-x-84 {
    column-gap: 84px;
  }
  .lg-gap-x-88 {
    column-gap: 88px;
  }
  .lg-gap-x-92 {
    column-gap: 92px;
  }
  .lg-gap-x-96 {
    column-gap: 96px;
  }
  .lg-gap-x-100 {
    column-gap: 100px;
  }
  .lg-gap-x-104 {
    column-gap: 104px;
  }
  .lg-gap-x-108 {
    column-gap: 108px;
  }
  .lg-gap-x-112 {
    column-gap: 112px;
  }
  .lg-gap-x-116 {
    column-gap: 116px;
  }
  .lg-gap-x-120 {
    column-gap: 120px;
  }
  .lg-gap-y-0 {
    row-gap: 0;
  }
  .lg-gap-y-4 {
    row-gap: 4px;
  }
  .lg-gap-y-8 {
    row-gap: 8px;
  }
  .lg-gap-y-12 {
    row-gap: 12px;
  }
  .lg-gap-y-16 {
    row-gap: 16px;
  }
  .lg-gap-y-20 {
    row-gap: 20px;
  }
  .lg-gap-y-24 {
    row-gap: 24px;
  }
  .lg-gap-y-28 {
    row-gap: 28px;
  }
  .lg-gap-y-32 {
    row-gap: 32px;
  }
  .lg-gap-y-36 {
    row-gap: 36px;
  }
  .lg-gap-y-40 {
    row-gap: 40px;
  }
  .lg-gap-y-44 {
    row-gap: 44px;
  }
  .lg-gap-y-48 {
    row-gap: 48px;
  }
  .lg-gap-y-52 {
    row-gap: 52px;
  }
  .lg-gap-y-56 {
    row-gap: 56px;
  }
  .lg-gap-y-60 {
    row-gap: 60px;
  }
  .lg-gap-y-64 {
    row-gap: 64px;
  }
  .lg-gap-y-68 {
    row-gap: 68px;
  }
  .lg-gap-y-72 {
    row-gap: 72px;
  }
  .lg-gap-y-76 {
    row-gap: 76px;
  }
  .lg-gap-y-80 {
    row-gap: 80px;
  }
  .lg-gap-y-84 {
    row-gap: 84px;
  }
  .lg-gap-y-88 {
    row-gap: 88px;
  }
  .lg-gap-y-92 {
    row-gap: 92px;
  }
  .lg-gap-y-96 {
    row-gap: 96px;
  }
  .lg-gap-y-100 {
    row-gap: 100px;
  }
  .lg-gap-y-104 {
    row-gap: 104px;
  }
  .lg-gap-y-108 {
    row-gap: 108px;
  }
  .lg-gap-y-112 {
    row-gap: 112px;
  }
  .lg-gap-y-116 {
    row-gap: 116px;
  }
  .lg-gap-y-120 {
    row-gap: 120px;
  }
}
@media print, screen and (min-width: 768px) {
  .hide-md {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .show-md {
    display: none !important;
  }
}

@media print, screen and (min-width: 1024px) {
  .hide-lg {
    display: none !important;
  }
}

@media screen and (max-width: 1023px) {
  .show-lg {
    display: none !important;
  }
}

.is-hidden {
  display: none !important;
}