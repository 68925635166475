
@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=0d9d4ff0-6f5d-4e9a-95a3-68d7137b2e98");
	@font-face{
		font-family:"Avenir Next";
		src:url("../fonts/a80347f9-6450-460c-8cd9-d38d8cfd392e.woff2") format("woff2"),url("../fonts/b773c7d4-8353-42fa-9bd7-a7aaca7291d1.woff") format("woff");
		font-weight: 200;
	}
	
	@font-face{
		font-family:"Avenir Next";
		src:url("../fonts/670c4af6-0f8f-4dfa-89d7-0213a1f77b7d.woff2") format("woff2"),url("../fonts/b25f4dd5-5498-47ad-bf13-ed8d085f0ace.woff") format("woff");
		font-weight: 300;
	}
	@font-face{
		font-family:"Avenir Next";
		src:url("../fonts/c4691e94-080d-4452-8f9f-8b3db6b68d55.woff2") format("woff2"),url("../fonts/a1adabe6-efe5-40b7-b619-48111c8ff883.woff") format("woff");
		font-weight: 400;
	}
	@font-face{
		font-family:"Avenir Next";
		src:url("../fonts/f07f5ab2-8ecc-416d-afa7-b73dbea95907.woff2") format("woff2"),url("../fonts/3a03e3a3-baa3-4079-aa61-d2ec2a35bea3.woff") format("woff");
		font-weight: 400;
		font-style: italic;		
	}
	@font-face{
		font-family:"Avenir Next";
		src:url("../fonts/6afe4676-059a-4aa8-b891-29856bbcba22.woff2") format("woff2"),url("../fonts/a9817c16-2a79-49bf-8543-6c846d73a52f.woff") format("woff");
		font-weight: 600;
	}
	@font-face{
		font-family:"Avenir Next";
		src:url("../fonts/b56355b0-7729-42a7-9f65-62c7211d078c.woff2") format("woff2"),url("../fonts/05537afc-e923-4df9-b365-71798fbd220d.woff") format("woff");
		font-weight: 700;
	}
