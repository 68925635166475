.content-demo {
	padding: 64px 0;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	background-color: $color-purple;
	text-align: center;
	display: grid;
	grid-template-columns: 1fr;
	gap: 40px;
	overflow: hidden;
	position: relative;
}

.content-demo-title.section-title {
	color: #fff;
 	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0 16px;
	height: auto;
	margin: 0 auto;
	flex-wrap: wrap;
	padding: 0 24px;
	 position: relative;
	 z-index: 3;
	 z-index: 2;
		font-size: 64px;
		line-height: 88px;
 
 	@include respond($bp-sm, "down") {
		 font-size: 32px;
		 line-height: 40px;
		 gap: 0 10px;

		 img {
			 max-width: 140px !important;
			 height: auto;
		 }
	 }
	
}

.content-demo .button-row {
	position: relative;
	z-index: 3;
}
