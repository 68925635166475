// -----------------------------------------------------------------------------
//! Utility class generator
// -----------------------------------------------------------------------------

@mixin attr-x(
    $name,
    $attr,
    $attr-steps: 10,
    $attr-count: 10,
    $unit: "px",
    $attr2: false
) {
    $attr-list: null;

    .#{$name}-0 {
        #{$attr}: 0;

        @if $attr2 {
            #{$attr2}: 0;
        }
    }

    @for $i from 1 through $attr-count {
        $attr-value: $attr-steps * $i;

        .#{$name}-#{$attr-value} {
            #{$attr}: #{$attr-value}#{$unit};

            @if $attr2 {
                #{$attr2}: #{$attr-value}#{$unit};
            }
        }

        $attr-list: append(
            $attr-list,
            unquote(".#{$name}-#{$attr-value}"),
            comma
        );
    }

    #{$attr-list} {
        //append style to all classes
    }
}

// -----------------------------------------------------------------------------
//! EASY MEDIA QUERIES
// -----------------------------------------------------------------------------

@mixin respond($breakpoint, $operator: "up", $dimension: "width") {
    @if $operator == "up" {
        @media print, screen and ( min-#{ $dimension }: $breakpoint) {
            @content;
        }
    } @else if $operator == "down" {
        @if unit($breakpoint) == "px" {
            $breakpoint: $breakpoint - 1;
        }
        @media screen and (max-#{ $dimension }: $breakpoint) {
            @content;
        }
    } @else {
        @if unit($operator) == "px" {
            $operator: $operator - 1;
        }
        @media screen and (min-#{ $dimension }: $breakpoint) and (max-#{ $dimension }: $operator) {
            @content;
        }
    }
}

@mixin min($breakpoint) {
    @media print, screen and (min-width: $breakpoint) {
        @content;
    }
}

@mixin max($breakpoint) {
    $breakpoint: $breakpoint - 1;
    @media screen and (max-width: $breakpoint) {
        @content;
    }
}

// -----------------------------------------------------------------------------
//! RESETS
// -----------------------------------------------------------------------------

@mixin unlist {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        list-style: inherit;
    }

    ul,
    ol {
        margin: 0;
        padding: 0;
    }
}

@mixin list-reset($with-children: false) {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
        list-style: inherit;
    }

    @if ($with-children) {
        ul,
        ol {
            margin: 0;
            padding: 0;
        }
    }
}

@mixin clearfix {
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}

// -----------------------------------------------------------------------------
//! PLACEHOLDER COLORS
// -----------------------------------------------------------------------------

@mixin input-placeholder() {
    &.placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &::-webkit-input-placeholder {
        @content;
    }
}
