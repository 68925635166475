.btn {
	transition: background 0.2s ease, color 0.2s ease;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	color:#fff;
	font-family: $font-body;
	font-size:1.125em;
	font-weight: normal;
	//text-transform: uppercase;
	line-height: calc(24/18);
	padding:16px 48px;
	font-weight: $weight-reg;
	//border-radius: 4px;
	&:hover {
		text-decoration: none;
	}
}

.btn-green,
.btn-black {
	background: $color-primary;
	&:hover{
		background-color:darken($color-primary, 10%);
	}
}

.btn-white {
	color: $color-body;
	background: #fff;
	&:hover{
		background-color:rgba(255,255,255,0.75);
	}
}


.btn-outline {
	color: #fff;
	background: transparent;
	border: 1px solid #fff;
	&:hover{
		background: #fff;
		color: $color-primary;
	}
}

.button-row {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	gap: 16px 24px;
	@include respond(480px) {
		gap: 16px 80px;	
	}
}