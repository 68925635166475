
.section-heading.section-heading-grid {
	padding-top: 48px;
	max-width: 1300px;
	.section-subtitle {
		max-width: 1000px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 0;
		font-size: 32px;
		line-height: 44px;
		
	}
	
	.section-title {
		font-size: 43px;
		line-height: 59px;
	}
}



.featuredGrid {
	padding:40px 32px;
	display: flex;
	align-items: stretch;
	justify-content: center;
	flex-wrap: wrap;
	max-width: 1664px;
	margin:0 auto;
	
	&:first-child {
		padding-top:0;	
	}
}

.home .featuredGrid {
	background: $color-body;
	padding: 40px 0 40px 0;
	&:first-child {
		padding-top:0;	
	}
	@include respond($bp-md) {
		padding: 100px 0 80px 0;
	}
	.featuredGrid-main-title,
	.featuredGrid-main-text,
	.featuredGrid-title,
	.featuredGrid-text,
	.featured-block-info-text,
	.featured-block-info-header h3 {
		color: #fff;
	}
}

.featuredGrid .container {
	padding:0 32px;
	display: flex;
	align-items: stretch;
	justify-content: center;
	flex-wrap: wrap;
	max-width: 1320px;
	margin:0 auto;	
}

.section-heading + .featuredGrid {
	padding-top:32px;
}

.featuredGrid + .section-heading {
	margin-top:40px;
}

.featuredGrid-header {
	text-align: center;
	flex: 0 0 100%;
	width: 100%;
	-ms-flex: 0 1 auto;
	padding:0 0 32px 0;
	@include respond(900px) {
		padding:0 56px 40px 6px;
		flex:0 0 50%;
		-ms-flex: 0 1 auto;
		width: 50%;
		text-align: left;
	}
	@include respond(1200px) {
		flex: 1 1 33.333333334%;
		-ms-flex: 0 1 auto;
		width: 33.333333334%;	
	}
}




.featuredGrid-title {
	font-size: 2em;
	line-height: calc(40/32);
	margin:0 0 16px 0;
	@include respond(900px) {
		font-size: 2.5em;
		line-height: calc(56/40);	
	}
}

.home {
	
}

.featuredGrid-text {
	margin:0;
	font-size: 1.125em;
	line-height: calc(32/18);
}

.featuredGrid-main {
	
	
	display: block;
	flex: 0 0 100%;
	width: 100%;
	-ms-flex: 0 1 auto;
	padding:0 0 32px 0;
	@include respond(900px) {
		flex:0 0 50%;
		-ms-flex: 0 1 auto;
		width: 50%;
		padding:0 6px 40px 0;
		max-width: 816px;
	}
	@include respond(1200px) {
		flex:0 0 66.666666666%;
		-ms-flex: 0 1 auto;
		width: 66.666666666%;	
	}
}

.featuredGrid-main-image {
	height:204px;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	display: block;
	border-radius: 4px;
	@include respond($bp-md) {
		height: 0;
		padding-top: (320 / 816) * 100%;
	}
}

.featuredGrid-main-title {
	font-size: 1.5em;
	line-height: calc(32/24);
	margin:16px 0 8px 0;
	padding:0 16px;
	}

.featuredGrid-main-text {
	padding:0 16px;
	margin:0;
	font-size: 1em;
	line-height: 1.5;
}


.featured-block {
	display: block;
	padding:16px 8px;
	opacity: 0;
	position: relative;
	transform: translateY(40px);
	transition: opacity 0.2s ease, transform 0.2s ease, margin 0.2s ease;
	&.is-animated {
		opacity: 1;
		transform: translateY(0);
	}
}

.featured-block {
	width: 100%;
	flex: 0 0 100%;
	-ms-flex: 0 1 auto;
	padding: 16px;
	@include respond($bp-md) {
		width: 50%;
		flex: 0 0 50%;
		-ms-flex: 0 1 auto;	
		
	}
	@include respond($bp-lg) {
		width: 25%;
		flex: 0 0 25%;
		-ms-flex: 0 1 auto;
		padding: 24px;
	}
	@include respond($bp-xl) {		
		padding: 40px;
	}
}

.featured-block-image {
	background-color: rgba($color-body, 0.1);
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	height:204px;
	display: block;
	border-radius: 4px;
	@include respond($bp-md) {
		height: 0;
		padding-top: (204 / 302) * 100%;
		
	}
}
	

.featured-block-inner {
	height:100%;
	position: relative;
	overflow: hidden;
}

.featured-block-info {
	padding:26px 0 0 0;
	position: relative;
	top:0;
	left:0;
	width:100%;
	bottom:0;
	text-align: left;
	@include respond($bp-xl) {
		padding:16px 0 0 0;	
	}
}

.featured-block-info-header {
	text-align: center;
	h3 {
		font-size: 1.125em;
		line-height: calc(24/18);		
		margin:0 0 28px 0;
		font-weight: $weight-xbold;
		@include respond($bp-xl) {
			font-size: 27px;
			line-height: 37px;	
		}
	}
}

.featured-block-info-content {
	
	padding: 0;
	.btn {
		margin-top:16px;
	}
	
}

.featured-block-info-text {
	margin:0;
	font-size:1em;
	line-height: 1.5;
	font-weight: $weight-reg;
	@include respond($bp-padding) {
		font-size: 18px;
		line-height: 30px;
		
	}
}


.featuredGrid-link {
	font-weight: $weight-reg;
	margin:16px 0 0 0;
	display: inline-block;
}


.featuredGrid-main-link {
	font-weight: $weight-reg;
	margin:16px 0 0 0;
	display: inline-block;
	padding:0 16px;
}


.featuredGrid.is-restricted {
	max-width: 1006px;
	.featured-block {
		width: 100%;
		flex: 0 0 100%;
		-ms-flex: 0 1 auto;
		@include respond($bp-md) {
			width: 50%;
			flex: 0 0 50%;
			-ms-flex: 0 1 auto;	
		}
		@include respond(900px) {
			width: 33.3333333333%;
			flex: 0 0 33.3333333333%;
			-ms-flex: 0 1 auto;
		}
		
	}
}


.featured-button,
.featured-tabs-button {
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	font-weight: $weight-bold;
	text-transform: uppercase;
	font-size: 1em;
	line-height: 24px;
	letter-spacing: 0.5px;
	cursor: pointer;
	color:#fff;
	padding:0;
	margin-left:7px;
	padding-left:16px;
	padding-bottom:4px;
	border-left: 4px solid #fff;
	margin-top:24px;
	text-decoration: none !important;
	transition: all 0.2s ease;
	background:transparent;
	text-align: left;
	.explore-arrows .explore-arrow * {
		fill: #fff;
	}
	&:hover {
		color:#fff;
		border-color:#fff;
		svg * {
			fill:#fff;
		}
	}
}

