.hero {
    position: relative;
    padding: 128px 0 40px 0;
    background-color: $color-grey-dark;
    background-repeat: no-repeat;
    background-size: cover;

    @include respond($bp-sm) {
        padding: 200px 0 40px 0;
    }

    @include respond($bp-md) {
        padding: 220px 0 40px 0;
    }

    @include respond($bp-xl) {
        padding: 270px 0 40px 0;
    }
}

.hero.small-hero {
    padding: 200px 0 128px 0;

    @include respond($bp-sm) {
        padding: 200px 0;
    }

    @include respond($bp-md) {
        padding: 200px 0;
    }

    @include respond($bp-xl) {
        padding: 200px 0;
        min-height: none;
        display: block;
    }
}

.hero.aboutHero {
    height: 318px;

    @include respond($bp-sm) {
        height: 400px;
    }

    @include respond($bp-md) {
        height: 400px;
    }

    @include respond($bp-xl) {
        height: 590px;
    }
}

.hero.home-hero {
    padding: 200px 0 128px 0;
    position: relative;
	text-align: center;

    @include respond($bp-sm) {
        padding: 200px 0;
    }

    @include respond($bp-md) {
        padding: 240px 0 160px;
        min-height: 90vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &:after {
        // content: ""; // turning off cause new hero video has a gradient on it
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.75);
        z-index: $z_index-level1 + 1;
    }

    .container {
        max-width: 1400px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;

        @include respond(480px) {
            flex-wrap: nowrap;
            justify-content: center;
        }

        gap: 16px;

        @include respond(680px) {
            gap: 22px;
        }

        @include respond(1140px) {
            gap: 26px;
        }

        svg {
            * {
                fill: #fff;
            }

            height: auto;
            display: block;
            flex: 1 1 88px;
            max-width: 88px;

            @include respond(580px) {
                flex: 1 1 108px;
                max-width: 108px;
            }

            @include respond(680px) {
                flex: 1 1 138px;
                max-width: 138px;
            }

            @include respond(880px) {
                flex: 1 1 138px;
                max-width: 138px;
            }

            @include respond(1000px) {
                flex: 1 1 170px;
                max-width: 170px;
            }

        }
    }
}

.home-hero .logo {
	margin: 0 auto;
	width: 400px;
	height: auto;
	position: relative;
	z-index: 2;
	max-width: 40vw;
}

.page-contact .hero:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2;
}

.hero .container {
    position: relative;
    z-index: $z_index-level1 + 2;
    //max-width: 1600px;
    padding: 0 32px;
    width: 100%;

    @include respond($bp-md) {
        //padding: 0 70px 0 134px;
    }
}

.hero .container .home-text {
    color: #fff;
    flex: 0 0 auto;
	text-align: left;

    .home-animate {
        display: block;
        opacity: 0;
        transform: translateY(-80px);
    }

    font-size: 30.6px;

    @include respond(400px) {
        font-size: 34px;
    }

    @include respond(480px) {
        font-size: 22px;
    }

    @include respond(680px) {
        font-size: 32px;
    }

    @include respond(780px) {
        font-size: 38px;
    }

    @include respond(1000px) {
        font-size: 47px;
    }

    // @include respond($bp-lg) {
    //     font-size: 100px;
    // }

    .home-normal {
        font-weight: 400;
    }

    .home-large {
        display: block;
        position: relative;
        font-weight: 700;
        margin-top: 0;

        @include respond(470px) {
            margin-top: 0;
        }
    }
}

.hero-title {
    font-size: 2em;
    line-height: calc(40 / 32);
    margin: 0;
    font-weight: 700;
    // text-transform: capitalize;
    //max-width: 900px;
    color: rgba(255, 255, 255, 1);

    @include respond($bp-sm) {
        font-size: 3em;
        line-height: calc(56 / 48);
    }

    @include respond($bp-md) {
        font-size: 4em;
        line-height: calc(72 / 64);
    }

    @include respond($bp-lg) {
        font-size: 80px;
        line-height: calc(96 / 80);
    }

    &.is-dark {
        color: $color-primary;
    }
}

.hero-subtitle {
    max-width: 1430px;
    color: rgba(255, 255, 255, 0.75);
    font-size: 1em;
    line-height: 1.5;

    @include respond($bp-sm) {
        font-size: 1.5em;
        line-height: calc(32 / 24);
    }

    @include respond($bp-md) {
        font-size: 2em;
        line-height: calc(40 / 32);
    }

    @include respond($bp-lg) {
        font-size: 3em;
        line-height: calc(56 / 48);
    }

    @include respond($bp-xl) {
        font-size: 3.25em;
        line-height: calc(56 / 52);
    }
}

.hero-media {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $z_index-level1;

    &:after {
        // content: "";
        // position: absolute;
        // top: 0;
        // left: 0;
        // right: 0;
        // bottom: 0;
        // z-index: $z_index-level1 + 3;
        // background-color: rgba(0, 0, 0, 0.3);
    }
}

.hero-video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: $z_index-level1 + 2;

    @include respond(480px, "down") {
        //	display: none;
    }
}

.home .hero-video {
    display: block !important;
}

.hero-more {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: $weight-bold;
    text-transform: uppercase;
    font-size: 1.25em;
    line-height: 24px;
    letter-spacing: 0.5px;
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    //margin-left:7px;
    //padding-left:16px;
    padding-bottom: 4px;
    //border-left: 4px solid rgba(255,255,255,1);
    margin-top: 24px;
    text-decoration: none !important;
    transition: all 0.2s ease;
    transform: scale(2);
}

.explore-arrows {
    margin-left: 8px;
    height: 28px;
    width: 13px;
    position: relative;

    .explore-arrow {
        position: absolute;
        top: 50%;
        margin-top: -4px;
        opacity: 0;

        &:first-of-type {
            top: 0;
            margin: 0;
        }

        &:last-of-type {
            bottom: 0;
            top: auto;
            margin: 0;
        }

        * {
            fill: rgba(255, 255, 255, 1);
            transition: fill 0.2s ease;
        }
    }
}

.hero-more:hover {
    color: rgba(255, 255, 255, 0.75);
    border-color: rgba(255, 255, 255, 0.75);

    svg * {
        fill: rgba(255, 255, 255, 0.75);
    }
}

// -----------------------------------------------------------------------------
//! Case Studies
// -----------------------------------------------------------------------------

.hero-meta {
    color: #fff;
    font-size: 20px;
    line-height: 32px;
    margin: 0 0 8px 0;

    strong {
        font-weight: $weight-xbold;
        text-transform: uppercase;
    }
}

.homepage-heading {
    padding-top: 250px;
    padding-bottom: 30px;
    text-align: center;

    h1 {
        color: $color-primary;
        font-size: 98px;
        line-height: 134px;
    }

    .header-line {
        margin-bottom: 300px;
    }

    .header-line:last-child {
        margin-bottom: 0;
    }
}

// -----------------------------------------------------------------------------
//! Contact
// -----------------------------------------------------------------------------

.page-contact .hero .hero-title {
    max-width: 900px;

    @include respond($bp-lg) {
        font-size: 72px;
        line-height: 78px;
    }
}

// -----------------------------------------------------------------------------
//! Services Hero
// -----------------------------------------------------------------------------

.hero.serviceHero {
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 64px 0 0 0;
    height: 128px;

    @include respond($bp-md) {
        padding: 80px 0 0 0;
        height: 160px;
    }

    .hero-title {
        font-weight: 400;
        font-size: 32px;
        line-height: 1;
        text-align: center;
    }
}

.scroll-down {
    display: none;
    position: absolute;
    left: 50%;
    bottom: 35px;
    text-align: center;
    font-size: 20px;
    z-index: 100;
    text-decoration: none;
    text-shadow: 0;
    width: 13px;
    height: 13px;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    z-index: 9;
    left: 50%;
    -webkit-transform: translate(-50%, 0%) rotate(45deg);
    -moz-transform: translate(-50%, 0%) rotate(45deg);
    transform: translate(-50%, 0%) rotate(45deg);
    -webkit-animation: fade_move_down 4s ease-in-out infinite;
    -moz-animation: fade_move_down 4s ease-in-out infinite;
    animation: fade_move_down 4s ease-in-out infinite;

    @include respond(970px) {
        display: block;
    }
}


/*animated scroll arrow animation*/
@-webkit-keyframes fade_move_down {
    0% {
        -webkit-transform: translate(0, -10px) rotate(45deg);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        -webkit-transform: translate(0, 10px) rotate(45deg);
        opacity: 0;
    }
}

@-moz-keyframes fade_move_down {
    0% {
        -moz-transform: translate(0, -10px) rotate(45deg);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        -moz-transform: translate(0, 10px) rotate(45deg);
        opacity: 0;
    }
}

@keyframes fade_move_down {
    0% {
        transform: translate(0, -10px) rotate(45deg);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: translate(0, 10px) rotate(45deg);
        opacity: 0;
    }
}