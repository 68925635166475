$bp-cs: 1200px;

.page-work {
	.hero-media:after {
		background-color: rgba(255, 255, 255, 0.8);
	}

	.main {
		padding-top: 80px;
		position: relative;
		min-height: 100%;
		background-size: cover;

		.hero-media:after {
			//background-color:rgba(255,255,255,0.5);
		}
	}

	.homeSolutions {
		padding-top: 0;
	}
}

.case-grid {
	position: relative;
	z-index: $z_index-level2;
	padding: 0 24px;
	max-width: 1200px;
	display: grid;
	align-items: strech;
	gap: 48px;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	margin: 0 auto;

	@include respond($bp-lg, "down") {
		gap: 40px;
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}

	@include respond($bp-md, "down") {
		grid-template-columns: 1fr;
	}

	.case-grid-box-text-holder {
		background: #fff;

		.case-grid-box-text {
			color: #000;
		}
	}
}

.home .case-grid .case-grid-box-text-holder {
	background: #000;
	z-index: 2;

	.case-grid-box-text {
		color: #fff;
	}
}

.home .case-grid-box-text {
	text-align: center;
	max-width: 280px;
	margin: 0 auto;

	strong {
		font-size: 21px;
		font-weight: 600;
	}

	p {
		margin-top: 4px;
		font-size: 19px;
	}
}

.solutionsWork .case-grid .case-grid-box-text-holder {
	z-index: 2;
}

.home .case-grid .case-grid-box:hover .case-grid-box-text-holder .case-grid-box-text,
.solutionsWork .case-grid .case-grid-box:hover .case-grid-box-text-holder .case-grid-box-text {
	color: $color-purple;
}


.home .case-grid .case-grid-box:after,
.solutionsWork .case-grid .case-grid-box:after {
	content: "";
	position: absolute;
	z-index: 1;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	opacity: 0;
	transition: opacity 0.4s ease;
	background: rgba(0, 0, 0, 0.3);
}

.home .case-grid .case-grid-box:hover:after,
.solutionsWork .case-grid .case-grid-box:hover:after {
	opacity: 1;
}

// .case-grid-left,
// .case-grid-right {
//     border: 1px solid red;
// }


.case-grid-box {
	display: flex;
	flex-direction: column;
	background-size: cover !important;
	background-repeat: no-repeat !important;
	background-position: center center !important;
	// height: 400px;
	//height: 300px;
	position: relative;

	//background: #000;
	text-decoration: none !important;

	@include respond($bp-sm, "down") {
		height: auto;
	}

	&.color-hover {
		filter: saturate(0%);

		&:hover,
		&:focus {
			filter: none;
		}
	}

	&:hover {
		.case-grid-box-text {
			color: $color-purple;
		}
	}
}

.case-grid-box-overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	padding: 32px;
	background: rgba(0, 0, 0, 0.9);
	left: 0;
	right: 0;
	transition: opacity 0.4s ease;
	// @include respond($bp-sm, "down") {
	// 	position: static !important;
	// }
}

.case-grid-box-title {
	color: #fff;
	font-weight: $weight-xbold;
	font-size: 24px;
	line-height: 32px;
	text-decoration: none !important;
}

.case-grid-box-text {
	text-decoration: none !important;
	// padding: 8px 0 0 0;
	color: #fff;
	font-size: 14px;
	line-height: 17px;
	transition: color 0.4s ease;
	font-weight: 600;

	@include respond($bp-md) {
		font-size: 16px;
		line-height: 24px;
		max-width: 100%;
	}
}

.case-grid-image {
	padding-top: (300 / 352) * 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}

.case-grid-box-text-holder {
	position: relative;
	background: #fff;
	flex-grow: 1;
}

.case-grid-page-title {
	color: #fff;
	font-size: 50px;
	line-height: 120%;
	margin: 50px;
	transition: color 0.4s ease;
}

.case-grid-box-text-holder {
	background-color: #000;
	position: relative;
	width: 100%;
	padding: 24px 0 0 0;
	// display: none;
	//
	// @include respond($bp-sm, "down") {
	// 	display: block !important;
	// 	height: auto !important;
	// }
}

.case-grid-box-logo {
	position: absolute;
	top: 40%;
	left: 100px;
	// top: 50%;
	// padding: 30px 65px;

	img {
		max-width: 150px;
		max-height: 150px;
	}
}

.case-grid-box:hover .case-grid-box-overlay {
	opacity: 0;
}

// -----------------------------------------------------------------------------
//! Single
// -----------------------------------------------------------------------------

.single-casestudies .main {
	position: relative;
	padding: 100px 32px 100px 32px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	min-height: 100vh;

	@include respond($bp-sm) {
		padding: 160px 32px 100px 32px;
	}

	@include respond($bp-cs) {
		padding: 160px 0 100px 32px;
	}

	@include respond($bp-xl) {
		padding: 160px 0 100px 120px;
	}

	.container {
		gap: 40px;
		position: relative;
		z-index: $z_index-level1 + 1;
		min-height: calc(100vh - 260px);

		@include respond($bp-cs) {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
		}

		@include respond($bp-xl) {
			gap: 120px;
		}
	}
}

.caseInfo {
	flex: 1 1 630px;
	max-width: 630px;

	@include respond($bp-cs) {
		display: flex;
		flex-direction: column;
		min-height: calc(100vh - 260px);
		align-items: flex-start;
		justify-content: flex-start;
	}
}

.caseInfo-title {
	font-size: 2em;
	line-height: calc(40 / 32);
	margin: 0 0 32px 0;
	position: relative;

	@include respond($bp-sm) {
		font-size: 3em;
		line-height: calc(56 / 48);
		margin: 0 0 40px 0;
	}

	@include respond($bp-md) {
		font-size: 3.5em;
		line-height: calc(64 / 56);
		margin: 0 0 48px;
	}

	img {
		max-width: 300px;
		max-height: 160px;
		width: auto;
		height: auto;
		// max-height: 200px;
	}
}

.caseInfo-services {
	margin: 0;
	font-size: 1.5em;
	line-height: calc(32 / 24);

	@include respond($bp-md) {
		font-size: 2em;
		line-height: calc(40 / 32);
	}

	@include respond($bp-lg) {
		font-size: 40px;
		line-height: 50px;
	}
}

.caseInfo-bar {
	width: 130px;
	height: 8px;
	margin: 0 0 24px 0;
}

.caseInfo-stats {
	margin: 32px 0 0 0;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 30px;

	@include respond(400px, "down") {
		gap: 20px;
	}
}

.caseInfo-stats-row {
	flex: 1 1 auto;
	text-align: center;
	font-size: 16px;
	line-height: 24px;
	max-width: 150px;
	text-align: center;

	@include respond(400px, "down") {
		max-width: 100%;
		width: 100%;
		flex: 0 0 100%;
		text-align: left;
	}

	@include respond($bp-xl) {
		font-size: 18px;
		line-height: 24px;
	}

	span {
		display: block;
		font-weight: 700;
		font-size: 32px;
		line-height: 1;
		position: relative;
		top: 3px;
		margin-bottom: 16px;

		@include respond($bp-lg) {
			font-size: 48px;
		}

		@include respond($bp-xl) {
			font-size: 50px;
		}
	}
}

.caseInfo-quote {
	margin: 20px 0 32px 0;
}

.caseInfo-quote-person {
	margin: 24px 0 0 0;
}

.caseInfo-quote-person-name {
	font-weight: 700;
}

.caseInfo-quote-person-title {
	padding-left: 8px;
	font-size: 15px;
}

.caseInfo-about {
	margin-top: auto;
	padding: 24px;
	width: 100%;

	p {
		color: #fff;
		font-size: 14px;
		line-height: 21px;

		a {
			color: #fff;
			text-decoration: underline;
		}
	}

	&.is-dark p {
		color: #000;

		a {
			color: #000;
		}
	}
}

.caseImg {
	flex: 1 1 auto;
	padding: 40px 0;

	@include respond($bp-cs) {
		padding: 0;
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;
	}

	img {
		display: block;
	}
}

.csNav {
	z-index: $z_index-level5;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100px;
	background: #fff;
	padding: 16px 40px;

	.slick-slide>div {
		height: 68px;

		padding: 0 16px;

		img {
			max-height: 48px;
			max-width: 100%;
			width: auto;
			height: auto;
			display: block;
			margin: 0 auto;
		}
	}

	.csNav-slide {
		height: 68px;
		display: flex !important;
		align-items: center;
		justify-content: center;
		text-align: center;
	}

	.slick-list {
		overflow: visible;
	}

	.is-current {
		position: relative;

		&:before {
			content: "";
			position: absolute;
			bottom: calc(100% + 16px);
			left: 50%;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 10px 8px 10px;
			border-color: transparent transparent #ffffff transparent;
			transform: translateX(-50%);
		}
	}
}

.csNav-slider-arrow {
	width: 40px;
	background: #000;
	position: absolute;
	top: 0;
	bottom: 0;
	padding: 0;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: $z_index-level5 + 2;

	&.prev-arrow {
		left: 0;

		svg {
			transform: rotate(180deg);
		}
	}

	&.next-arrow {
		right: 0;
	}

	svg * {
		fill: #fff;
	}
}

.services-used {
	font-size: 16px;
	line-height: 24px;

	strong {
		font-weight: 700;
	}

	@include respond($bp-md) {
		font-size: 23px;
		line-height: 32px;
	}
}

.caseInfo-quote-text {
	font-size: 14px;
	line-height: 1.5;

	@include respond($bp-md) {
		font-size: 17px;
		line-height: 27px;
	}
}

// -----------------------------------------------------------------------------
//! Homepage Grid
// -----------------------------------------------------------------------------

.homeWork {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center top;
	background-color: #fff;
	// background-image: url(../webp/blackink-bg.webp);
	padding: 80px 0 80px 0;

	@include respond($bp-padding) {
		padding: 112px 0 112px 0;
	}

	.section-title {
		text-align: center;
		margin: 0 auto 24px auto;
		max-width: 1010px;

		span {
			font-weight: $weight-reg;
		}
	}

	.section-subtitle {
		font-size: 20px;
		text-align: center;
		margin: 0 auto 30px auto;
		padding-right: 20px;
		padding-left: 20px;

		@include respond($bp-padding) {
			margin: 0 auto 64px auto;
		}
	}

	.case-grid-image {
		padding-top: (300 / 352) * 100%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
	}

	.case-grid-box-text-holder {
		position: relative;
	}

}

// -----------------------------------------------------------------------------
//! Solutions Grid
// -----------------------------------------------------------------------------

.solutionsWork {
	padding: 80px 0;

	@include respond($bp-padding) {
		padding: 200px 0;
	}

	h2 {
		font-size: 40px;
		text-align: center;
		margin-bottom: 45px;

		@include respond($bp-md) {
			font-size: 50px;
			margin-bottom: 55px;
		}
	}

	.case-grid-box-text-holder {
		background: #fff;

		.case-grid-box-text {
			color: #000;
		}
	}

	.case-grid-image {
		padding-top: (300 / 352) * 100%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
	}

	.case-grid-box-text-holder {
		position: relative;
	}

	.case-grid-box {
		text-decoration: none !important;
	}
}
