.conclusion {
    // background-image: url(../webp/ink-bg-1.webp);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    padding: 120px 16px 120px 16px;
    position: relative;
    &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 200px;
        background: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
        );
    }
    @include respond($bp-lg) {
        padding: 120px 32px 120px 32px;
    }
    .container {
        max-width: 1200px;
        position: relative;
        z-index: 2;
    }
}

.conclusion-title {
    text-align: center;
    //color:#fff;
}

.conclusion-grid {
    margin: 40px 0 0 0;
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;
    @include respond($bp-md) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 80px 40px;
    }
    @include respond(900px) {
        gap: 120px 80px;
    }
    @include respond($bp-lg) {
        gap: 120px;
    }
}

.conclusion-pre {
    margin-bottom: 8px;
    //color: #fff;
}

.conclusion-image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: $color-body;
    padding-top: 100%;
    height: 0;
    margin: 0 0 24px 0;
}

.conclusion-box-title {
    font-weight: $weight-bold;
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 16px 0;
    //color: #fff;
    @include respond($bp-md) {
        font-size: 24px;
        line-height: 32px;
    }
}

.conclusion-box .btn {
    display: inline-block;
}

.conclusion-box {
    opacity: 0;
    transform: translateY(80px);
    @include respond($bp-md, "down") {
        display: grid;
        grid-template-columns: 200px 1fr;
        gap: 24px;
    }
    @include respond($bp-sm, "down") {
        grid-template-columns: 108px 1fr;
        gap: 16px;
    }
}
