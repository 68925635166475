.sustainability {
    background-position: 25% 50%;
    background-repeat: no-repeat;
    background-size: cover !important;
    background: $color-grey-dark;
    background-image: url("../images/sustain-mb-xs.jpg");

    @include respond($bp-sm) {
        background-image: url("../images/sustain-mb.jpg");
    }

    @include respond(1300px) {
        background-image: url("../images/sustain.jpg");
    }

    .container {
        padding: 0 30px;
        max-width: 1200px;
        margin: 0 auto;
    }
}

.sustain-content {
    padding: 70px 0;
    max-width: 750px;
    padding-bottom: 40px;

    @include respond($bp-md) {
        padding: 100px 0;
    }

    @include respond(1200px) {
        padding: 150px 0;
    }

    @include respond(1300px) {
        padding: 100px 0;
    }

    @include respond(2000px) {
        padding: 175px 0;
    }

    @include respond(2500px) {
        padding: 250px 0;
    }

    h3 {
        color: #fff;
        font-size: 26px;
        line-height: 45px;
        margin-bottom: 20px;

        @include respond(320px) {
            font-size: 28px;
        }

        @include respond(390px) {
            font-size: 35px;
        }

        @include respond($bp-md) {
            font-size: 45px;
            line-height: 54px;
        }
    }

    p {
        color: #fff;
        font-size: 22px;
        margin-bottom: 0;
        margin-top: 0;
        position: relative;
        padding-left: 28px;
        // border-left: 2px solid $color-purple;

        @include respond($bp-md) {
            font-size: 28px;
        }

        &:before {
            content: "";
            position: absolute;
            top: 13px;
            left: 0;
            // width: 10px;
            height: 75%;
            border-left: 5px solid $color-purple;
        }
    }
}
