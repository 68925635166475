.hide-md {
    @include min($bp-md) {
        display: none !important;
    }
}

.show-md {
    @include max($bp-md) {
        display: none !important;
    }
}

.hide-lg {
    @include min($bp-lg) {
        display: none !important;
    }
}

.show-lg {
    @include max($bp-lg) {
        display: none !important;
    }
}

.is-hidden {
    display: none !important;
}
