$bp-altstack: 860px;

.altblocks {
	padding: 80px 0;
}

.altblock {
	
	margin-bottom:120px;
	&:last-of-type {
		margin-bottom: 0;
	}
	@include respond($bp-altstack) {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;	
	}
	
	&:nth-of-type(even) {
		.altblock-content {
			order:2;
		}
	}
}

.altblock-content {
	padding: 16px;
	@include respond($bp-sm) {
		padding:24px 32px;
	}
	@include respond($bp-altstack) {
		width: 50%;
		flex: 0 0 50%;
		-ms-flex: 0 1 auto;
	}
	@include respond($bp-lg) {
		padding:24px 40px;
	}
	@include respond($bp-xl) {
		padding:24px 80px;
	}
	@include respond(1600px) {
		padding:24px 180px;	
	}
}

.altblock-image {
	opacity: 0;
	transform: translateY(80px);
	margin:32px 0 0 0;
	padding:0 16px;
	transition: all 0.8s ease;
	@include respond($bp-sm) {
		padding:0 32px;
	}
	@include respond($bp-altstack) {
		margin:0;
		width: 50%;
		flex: 0 0 50%;
		-ms-flex: 0 1 auto;
		padding:0;
	}
	
	img {
		display: block;
	}
}

.is-animated .altblock-image {
	opacity: 1;
	transform: translateY(0);
}

.altblock-title {
	font-size:2em;
	line-height: calc(40/32);	
	position: relative;
	padding-bottom:16px;
	border-bottom: 8px solid $color-primary;
	margin:0;
	@include respond($bp-md) {
		font-size:2.5em;
		line-height: calc(48/40);
	}
}

.altblock-text {
	padding:24px 0;
	@include respond($bp-xl) {
		padding:32px 0;	
	}
	p {
		font-size:1em;
		line-height: 1.5;
		@include respond($bp-md) {
			font-size: 1.125em;
			line-height: calc(32/18);	
		}
	}
}