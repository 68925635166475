.servicesCta {
	padding: 100px 20px 80px;

	@include respond($bp-padding) {
		padding: 160px 20px 100px;
	}

	h2 {
		font-size: 40px;
		text-align: center;
		margin-bottom: 45px;

		@include respond($bp-md) {
			font-size: 50px;
			margin-bottom: 55px;
		}
	}

	.otherServices {
		display: flex;
		flex-direction: column;
		gap: 25px;

		@include respond($bp-md) {
			flex-direction: row;
			gap: 40px;
		}

		.otherService {
			flex: 1;
			display: block;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;
			width: 100%;
			position: relative;

			&:hover {
				h3 {
					color: $color-purple;
				}


				.otherServiceImg:after {
					background: rgba(0, 0, 0, 0.30);
				}
			}
		}

		.otherServiceImg {
			padding-top: 92%;
			position: relative;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center center;

			&:after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background: transparent;
				transition: background 0.2s ease, color 0.2s ease;
			}
		}

		.homeSolutions-block-title {
			position: absolute;
			bottom: 0;
			left: 0;
			color: #fff;
			background-color: #000;
			width: 100%;

			h3 {
				margin-bottom: 0;
				transition: color 0.2s ease, color 0.2s ease;
				padding: 8px 24px;
				font-size: 18px;
				font-weight: 600;
				line-height: normal;
			}
		}
	}
}

.homeServices+.servicesCta {
	padding-top: 0;
}
