.section-heading {
    max-width: 800px;
    padding: 0 16px;
    text-align: center;
    margin-top: 48px;
    margin-bottom: 32px;
    @include respond($bp-sm) {
        padding: 0 32px;
        margin-top: 64px;
        margin-bottom: 48px;
    }
    &:first-child {
        margin-top: 0;
    }
}

.section-title {
    margin: 0;
    position: relative;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    @include respond($bp-md) {
        font-size: 53px;
        line-height: 72px;
    }
    @include respond($bp-lg) {
        font-size: 72px;
        line-height: 78px;
    }

    &.has-underline {
        padding-bottom: 32px;
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            max-width: 636px;
            height: 8px;
            background: $color-primary;
        }
    }
}

.section-subtitle {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
   max-width: 1020px;
    margin-left: auto;
    margin-right: auto;
}

.section-heading-split {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 32px 0 0 0;
}

.section-heading-split-half {
    flex: 1 1 50%;
    -ms-flex: 0 1 auto;
    min-width: 50%;
    text-align: center;
    padding: 0 32px;
    font-size: 1.125em;
    line-height: 1;
}
