// -----------------------------------------------------------------------------
//! Fonts
// -----------------------------------------------------------------------------

$font-body: "Avenir Next", Arial, Helvetica, Sans-serif;

$weight-xlight: 300;
$weight-light: 300;
$weight-reg: 400;
$weight-bold: 400;
$weight-xbold: 400;

// -----------------------------------------------------------------------------
//! Colors
// -----------------------------------------------------------------------------

$color-primary: #000;
$color-secondary: #000;
$color-body: #252525;
$color-grey-dark: #252525;
$color-offwhite: #f1f1fa;
$color-purple: #AF4EFB;

// -----------------------------------------------------------------------------
//! Z-indexes
// -----------------------------------------------------------------------------

$z_index-level6: 120;
$z_index-level5: 100;
$z_index-level4: 75;
$z_index-level3: 50;
$z_index-level2: 25;
$z_index-level1: 1;
$z_index-level0: -1;

// -----------------------------------------------------------------------------
//! Global Breakpoints
// -----------------------------------------------------------------------------

$bp-xl: 1500px;
$bp-lg: 1024px;
$bp-padding: 1024px;
$bp-md: 768px;
$bp-sm: 560px;
