.homeQuote {
    padding: 80px 0 0 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    // background-image: url(../webp/quote-bg.webp);

    @include respond($bp-padding) {
        padding: 196px 0 0 0;
    }

    .section-title {
        text-align: center;
        margin: 0 auto 24px auto;
        max-width: 1010px;
        span {
            font-weight: $weight-reg;
        }
    }

    .section-subtitle {
        text-align: center;
        margin: 0 auto 30px auto;
        max-width: 900px;
        padding-right: 20px;
        padding-left: 20px;

        @include respond($bp-padding) {
            margin: 0 auto 64px auto;
        }
    }
}

.home .homeQuote {
    @include respond($bp-padding) {
        padding: 112px 0 0 0;
    }
}

.homeQuote .container {
    max-width: 1288px;
    padding: 0 24px;
}

.homeQuote-slide-inner {
    @include respond(680px) {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
    }
    @include respond($bp-padding) {
        gap: 60px;
    }
    @include respond(680px, "down") {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 30px;
    }
}

.homeQuote-slide-left {
    padding-left: 130px;
    flex: 1 1 716px;
    max-width: 716px;
    position: relative;
    @include respond($bp-padding, "down") {
        padding-left: 64px;
    }
    @include respond(680px, "down") {
        margin-bottom: 30px;
        flex: 1 1 auto;
        order: 2;
    }
    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0px;
        width: 80px;
        height: 64px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        background-image: url("../svg/quotes.svg");
        @include respond($bp-padding, "down") {
            width: 49px;
            height: 39px;
            top: 0px;
        }
    }
}

.homeQuote-slide-right {
    max-width: 230px;
    flex: 1 1 230px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    img {
        display: block;
    }

    @include respond($bp-md) {
        max-width: 329px;
        flex: 1 1 329px;
    }
}

.homeQuote-slide-left-text {
    font-size: 20px;
    line-height: 24px;

    @include respond($bp-md) {
        font-size: 24px;
        line-height: 32px;
    }
    // @include respond($bp-lg) {
    //     font-size: 24px;
    //     line-height: 40px;
    // }
}

.homeQuote-slider {
    position: relative;
    // overflow: visible;
}

.page-about {
    .arrows {
        padding-bottom: 80px;
    }
}

.arrows {
    // padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    gap: 100px;
}

.prev-arrow,
.next-arrow {
    // top: 50%;

    z-index: $z_index-level1 + 3;
    cursor: pointer;

    @include respond(1275px) {
        position: absolute;
        transform: translateY(-1000%);
    }

    #arrow-stroke {
        stroke: #000;
        transition: all 0.2s ease;
    }
    &:hover,
    &:focus {
        #arrow-stroke {
            stroke: $color-purple;
        }
    }
}

.prev-arrow {
    @include respond(1275px) {
        left: 90px;
    }
}

.next-arrow {
    @include respond(1250px) {
        right: 90px;
    }
}

.homeQuote-slide-left-name {
    font-size: 18px;
    line-height: 28px;
    margin: 24px 0 0 0;
    font-weight: 600;
}

.homeQuote-slide-left-title {
    font-size: 16px;
    line-height: 24px;
}

.homeQuote-btn {
    margin-top: 24px;
}

.page-about .homeQuote {
    padding-top: 0;
}
