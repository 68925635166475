.two-two-grid {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    padding-left: 32px;
    padding-right: 32px;

    gap: 50px;

    @include respond($bp-md) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .grid-item {
        text-align: center;
        background-color: #777;
        width: 100%;
        height: 200px;
    }
}
