.content.video-bg {
	min-height: 300px;
	padding: 120px 0;
	text-align: center;
	position: relative;
	@include respond($bp-md) {
		padding: 240px 0;
	}
	
	
	.container {
		position: relative;
		z-index: $z_index-level1 + 1;
	}
	
	.hero-title {
		@include respond($bp-xl) {
			font-size:5em;
			line-height: calc(96/80);	
		}
	}
	
}



.video-block {
	position: relative;
	overflow: hidden;
	padding-bottom: 0;
	padding-top: (9 / 16) * 100%;
	
}