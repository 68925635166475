$bp-slider-xl: 1160px;
$bp-slider-stack: 940px;


.slider-wrap {
	
	max-width: 1400px;
	padding:40px 20px;
	margin:0 auto;
	&:last-of-type {
		padding-bottom:0;
	}
	@include respond($bp-slider-stack) {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;	
	}
}


.slider-text {
	
	padding:0 0 16px 0;	

	@include respond($bp-slider-stack) {
		flex: 0 0 auto;
		-ms-flex: 0 1 auto;
		max-width: 360px;
	}
	@include respond($bp-slider-xl) {
		max-width: 460px;
		padding:0 56px 0 0;	
	}
}

.slider-slider {
	@include respond($bp-slider-stack) {
		flex: 1 1 900px;
		-ms-flex: 0 1 auto;
		width: 100%;
		max-width: calc(100% - 360px);
	}
	@include respond($bp-slider-xl) {
		max-width: calc(100% - 460px);	
	}
}

.slider-text-title {
	font-size: 2em;
	line-height: calc(40/32);
	margin:0 0 16px 0;
	@include respond(900px) {
		font-size: 2.5em;
		line-height: calc(56/40);	
	}
}

.slider-text p a {
	font-weight: $weight-reg;
}
.slider {
		width: 100%;
}

.slide-caption {
	position: relative;
	z-index: $z_index-level1;
	padding:16px;
	border: 1px solid $color-primary;
	background:#fff;
	margin:0;
	border-top:0;
	box-shadow: 0px 0px 8px rgba($color-body, 0.15);
	@include respond(600px) {
		padding:24px 32px;
		max-width: calc(100% - 80px);
		margin:-64px auto 0 auto;	
		border-top: 1px solid $color-primary;

	}
}

.slide-caption-title {
	font-size: 1.25em;
	line-height: calc(32/20);
	margin:0 0 8px 0;
}

.slide-caption-text {
	font-size:1em;
	line-height: 1.5;
	margin:0;
}

.slider-arrows-wrap {
	
	margin:40px 0 0 0;
	@include respond($bp-slider-stack) {
		display: flex;
		align-items: center;
		justify-content: space-between;	
	}
}

.slider-dots {
	display: none;
	@include respond($bp-slider-stack) {
		display: block;
		ul {
			display: flex;	
			align-items: center;
			justify-content: flex-start;
			list-style: none;
			margin:0;
			padding:0;
			li {
				margin:0 12px 0 0;
				padding:0;
			}
		}
		.slick-slider-button {
			width:16px;
			height:16px;
			background: $color-grey-dark;
			border-radius: 50%;
			cursor: pointer;
			&:hover {
				background: $color-primary;
			}
		}
		.slick-active .slick-slider-button {
			background: $color-primary;
		}
	}
}
.slider-arrows {
	display: flex;
	align-items: center;
	justify-content: space-between;
	@include respond($bp-slider-stack) {
		justify-content: flex-end;
		margin:0 0 0 20px;	
	}
}

.slider-arrow {
	flex: 0 0 56px;
	-ms-flex: 0 1 auto;
	width: 56px;
	height:56px;
	border: 2px solid $color-primary;
	background:#fff;
	border-radius: 50%;
	padding:0;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: background 0.2s ease;
	&.next-arrow {
		margin-left:8px;
	}
	svg {
		display: block;
	}
	svg * {
		fill: $color-primary;
		transition: fill 0.2s ease;
	}
	&:hover {
		background: $color-primary;
		svg * {
			fill:#fff;
		}
	}
}
