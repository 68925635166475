$bp-ftabs: 620px;

.featured-tabs {
    position: relative;
    padding: 0 32px 64px 32px;
    // //background: $color-offwhite;
    // position: absolute;
    // left:0;
    // top:100%;
    // width:100vw;
    // pointer-events: none;
    // opacity: 0;
    // z-index: -1;
    // transition: opacity 0.4s ease 0s;
}


.featured-block.is-open {
    padding-bottom: 32px;
}
.featured-block:after {
    /*
	content:"";
	position: absolute;
	bottom:0;
	width: 0;
	left:50%;
	transform: translateX(-50%);
	height: 0;
	border-style: solid;
	border-width: 0 12px 8px 12px;
	border-color: transparent transparent $color-offwhite transparent;
	opacity: 0;
	transition: opacity 0.4s ease 0.2s;
*/
}

.featured-block.is-open:after {
    opacity: 1;
    transition: opacity 0s ease 0s;
}

.featured-block.is-open .featured-tabs {
    z-index: 1;
    pointer-events: all;
    opacity: 1;
    transition: opacity 0.4s ease 0.2s;
}

.featured-tabs-inner {
    max-width: 1320px;
    margin: 0 auto;
    display: flex;
    // align-items: stretch;
    align-items: center;
    justify-content: flex-start;
}

.page-enabling-technology .featured-tabs-inner {
    align-items: flex-start;
    .featured-tabs-nav {
        padding-top: 32px;
    }
}

.featured-tabs-nav {
    flex: 0 0 56px;
    -ms-flex: 0 1 auto;
    max-width: 56px;
    width: 100%;
    z-index: $z_index-level1 + 1;
    position: relative;
    padding: 16px 0 0 0;
    @include respond($bp-ftabs) {
        flex: 0 0 200px;
        -ms-flex: 0 1 auto;
        max-width: 200px;
    }
    @include respond(900px) {
        flex: 0 0 300px;
        -ms-flex: 0 1 auto;
        max-width: 300px;
    }
}

.featured-tabs-nav-button {
    width: 100%;
    background: #fff;
    margin: 0 0 16px 0;
    border-radius: 4px;
    box-shadow: 0 0 8px rgba($color-body, 0.15);
    font-size: 18px;
    line-height: calc(24 / 18);
    font-weight: $weight-bold;
    padding: 16px;
    color: $color-body;
    text-align: center;
    transition: background 0.2s ease, color 0.2s ease;
    @include respond($bp-ftabs) {
        text-align: left;
        padding: 24px;
    }
    &.is-active {
        background: $color-primary;
        color: #fff;
        svg * {
            fill: #fff;
        }
    }
    .number {
        @include respond($bp-ftabs) {
            display: none;
        }
    }

    .title {
        display: none;
        @include respond($bp-ftabs) {
            display: inline;
        }
    }
    &.has-video {
        position: relative;

        @include respond($bp-ftabs) {
            padding-right: 32px;
        }
        svg {
            @include respond($bp-ftabs, "down") {
                display: none;
            }
            position: absolute;
            top: 50%;
            right: 8px;
            transform: translateY(-50%);
        }
    }
}
.featured-tabs-panels {
    background: #fff;
    padding: 16px 16px 16px 32px;
    // border: 2px solid $color-primary;
    position: relative;
    z-index: $z_index-level1;
    margin-left: -16px;
    border-radius: 8px;
    @include respond($bp-ftabs) {
        padding: 32px 40px 32px 64px;
        margin-left: -24px;
    }
}
