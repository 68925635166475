// -----------------------------------------------------------------------------
//! GENERAL
// -----------------------------------------------------------------------------

html {
    height: 100%;
    background: #fff;
    overflow-x: hidden;
}

body {
    position: relative;
    min-height: 100%;
}

.container {
    margin: 0 auto;
}

.contain {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.pad-x {
    padding-left: 24px;
    padding-right: 24px;
}

.main {
    padding: 80px 0;
    background: #fff;
}
.home .main {
    
    padding: 0;
}
.page-about .main {
    padding-top: 120px;
}


.page-strategy-creative-services .main,
.page-creative-design .main,
.page-enabling-technology .main,
.page-omnichannel-activation .main,
.page-supply-chain-management .main {
    padding-bottom: 0; 
}


section.is-black {
    background: #000;
    p, h1, h2,h3,h4,h5 {
        color: #fff;
    }
}