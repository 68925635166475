.footer {
    background: $color-primary;
    border-top: 2px solid $color-primary;
    padding: 64px 0 32px 0;
    text-align: center;
}

.footer-top {
    padding: 0 16px 0 16px;
    @include respond($bp-sm) {
        padding: 0 80px 0 80px;
    }
    
    img {
        max-height: 96px;
    }
}

.footer-tagline {
    color: #fff;
    font-size: 1.25em;
    line-height: calc(24 / 20);
    text-transform: uppercase;
    text-align: center;
    font-weight: $weight-light;
    margin: 24px 0;
}

.footer-bottom {
    padding: 32px 16px;
    @include respond($bp-sm) {
        padding: 32px 80px;
    }
}

.footer-bottom-left {
    font-weight: $weight-light;
    color: #fff;
    font-size: 12px;
    line-height: 1.5;
    text-transform: none;
    margin: 24px 0 0 0;
    a {
        color: #fff;
        text-decoration: underline;
        &:hover {
            color: #fff;
        }
    }
}

.connect-docs {
    font-weight: $weight-light;
    color: #fff;
    font-size: 12px;
    line-height: 1.5;
    text-transform: none;
    margin: 10px 0 0 0;
    a {
        color: #fff;
        text-decoration: underline;
        &:hover {
            color: #fff;
        }
    }
}

.footer-nav {
    list-style: none;
    padding: 0;
    margin: 0 0 24px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.footer-nav .sub-menu {
    display: none;
}

.footer-nav li a {
    color: #fff;
    font-size: 1.25em;
    margin: 0 16px;
    font-weight: $weight-light;
}
