// .home:not(.show-nav) .header {
//    
//     @include respond($bp-md) {
//         transform: translateY(-100%);
//         display: none;    
//     }
// 
//     &.second-header {
//         background-color: #fff;
//         transform: none;
//         position: absolute;
//         bottom: 0;
//         left: 0;
//         box-shadow: none;        
//         display: none;
//         &.is-white {
//             background-color: #fff;
//             svg * {
//                 fill: $color-primary;
//             }
//             li a {
//                 color: $color-body;
//             }
//             &.show-nav {
//                 position: fixed;
//                 width: 100%;
//                 bottom: auto;
//                 box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
//                 svg * {
//                     fill: $color-primary;
//                 }
//                 
//                 li a {
//                     color: $color-body;
//                 }
//             }
//         }
// 
//         @include respond($bp-md) {
//             //background-color: transparent;
//             display: flex;
//             
//         }
// 
//         .header-nav .sub-menu {
//             top: auto;
//             bottom: 100%;
// 
//             a {
//                 color: $color-body;
//             }
//         }
// 
//         li {
//             & > a {
//                 color: $color-body;
//                 &:hover {
//                     text-decoration: none;
//                     //color: $color-body;
//                 }
//             }
// 
//             @include respond($bp-md) {
//                 & > a {
//                     //color: #fff;
// 
//                     &:hover {
//                         color: $color-body;
//                     }
//                 }
//             }
//         }
// 
//         svg {
//             * {
//                 fill: $color-body;
//             }
// 
//             @include respond($bp-md) {
//                 * {
//                    // fill: #fff;
//                 }
//             }
//         }
//     }
// }

.show-nav {
    transform: translateY(0) !important;
    background: #fff;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    top: 0;

    &.second-header {
    //    display: none;
    
        .header-nav .sub-menu {
            bottom: auto !important;
            top: 100% !important;   
        }
    }
}

.header {
    padding: 8px 16px 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    // top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: $z_index-level6 + 1;
    @include respond($bp-md) {
        padding: 16px 32px 16px 32px;
    }

    //&.is-scrolled {

    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    //}
    // transform: translateY(0);
    //transition: 0.2s;
}

.header-logo {
    display: block;
    svg {
        display: block;
        width: 94px;
        height: 48px;
        * {
            fill: #000;
        }
    }
}

.hamburger-nav {
    display: none;
}

.header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0;
    flex-grow: 0;
}

.header-nav {
    list-style: none;
    padding: 0;
    margin: 0 0 0 40px;
    display: none;
    align-items: center;
    justify-content: flex-end;
    @include respond($bp-md) {
        display: flex;
    }
}

.header-nav > li {
    margin: 0 0 0 24px;
    padding: 8px 0;
    position: relative;
    font-size: 1em;
    font-weight: 500;
    @include respond(1120px) {
        margin: 0 0 0 40px;
        font-size: 1.125em;
    }
    & > a {
        color: $color-body;
        &:hover {
            text-decoration: none;
           // color: $color-body;
        }
    }
}
//
// .header.is-scrolled {
//
// 	.header-logo svg {
// 		// width: 91px;
// 		// height: 32px;
// 		* {
// 			fill: #000;
// 		}
// 	}
// 	.header-nav > li > a {
// 		color: $color-body;
// 		&:hover {
// 			color: $color-body;
// 		}
// 	}
// }

.header-nav .sub-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background: #fff;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    margin: 0;
    padding: 0;
    list-style: none;
    width: 220px;
    li {
        margin: 0;
        padding: 0;
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        a {
            padding: 12px 16px;
            display: block;
            &:hover {
               //color: $color-primary;
            }
        }
    }
}

.header-nav > li:hover .sub-menu {
    display: block;
}

.search-button {
    padding: 16px;
    background: transparent;
    cursor: pointer;
    border: none;

    display: none;
    @include respond($bp-md) {
        display: block;
    }
    @include respond(1120px) {
        margin-left: 24px;
    }
    svg {
        &.close {
            display: none;
        }
        &.search {
            display: block;
        }
        height: 24px;
        width: 24px;
        * {
            transition: fill 0.2s ease;
            fill: $color-grey-dark;
        }
    }
    &:hover {
        svg * {
            fill: $color-primary;
        }
    }
}

.search-open .search-button svg {
    &.close {
        display: block;
    }
    &.search {
        display: none;
    }
}

// -----------------------------------------------------------------------------
//! Hamburger
// -----------------------------------------------------------------------------

.hamburger {
    background: #fff;
    width: 48px;
    height: 48px;
    padding: 0 12px;
    margin-right: 0px;
    //background:transparent;
    cursor: pointer;
    @include respond($bp-md) {
        display: none;
    }
    &:hover {
        &::before,
        &::after,
        div {
            transform: scale(1.1);
            transform-origin: center center;
            background-color: $color-primary;
        }
    }
    &::before,
    &::after,
    div {
        content: "";
        background-color: $color-grey-dark;
        border-radius: 3px;
        display: block;
        height: 3px;
        margin: 5px 0;
        transition: all 0.2s ease-in-out;
    }
    div {
        width: 24px;
    }
    &.is-active {
        &::before {
            transform: translateY(8px) rotate(135deg);
        }

        &::after {
            transform: translateY(-8px) rotate(-135deg);
        }
        div {
            transform: scale(0);
        }
    }
}

// -----------------------------------------------------------------------------
//! Scrolly Header
// -----------------------------------------------------------------------------

.header.nav-down {
    top: 0;
}

.header.nav-up {
    top: -75px;
}

/*

.header-fixed .header{
	background-color: #fff;
	
	padding:8px 16px;
	border-bottom: none;
	.header-logo {
		margin-bottom:0;
	}
	.logo-small {
		display: block;
	}
	.logo-large {
		display: none;
	}
	.hamburger {
		margin-right:-16px;
		padding:0 16px;
		height:48px;
		div {
			width:32px;
		}
	}
	.header-right {
		align-items: center;
	}
	.search-button {
		padding:6px 16px;
		svg {
			width:24px;
			height:24px;
		}
	}
}
*/
