$bp-a-top-stack: 864px;
$bp-a-top-single: 480px;
$bp-about-stack: 768px;

.about-top {
    padding: 0 32px;
    max-width: 1336px;
    margin: 0 auto;
    @include respond($bp-about-stack) {
        padding: 0 32px;
        align-items: center;
        justify-content: space-between;
    }

    @include respond(1150px) {
        display: flex;
    }
}

.about-top h2 {
    font-weight: $weight-xlight;
    margin: 0;
    font-size: 1.375rem;
    line-height: calc(40 / 22);
    span {
        color: $color-primary;
        font-weight: normal;
        font-size: 2.5rem;
        line-height: calc(44 / 40);
        margin: 0 0 16px 0;
        display: block;
    }
    @include respond($bp-about-stack, "down") {
        text-align: center;
    }
    @include respond($bp-a-top-stack) {
        font-size: 1.625rem;
        line-height: calc(48 / 32);
        span {
            font-size: 3rem;
            line-height: calc(56 / 48);
            margin: 0 0 16px 0;
        }
    }
}

.about-top-left {
    flex: 1 1 auto;
    -ms-flex: 0 1 auto;
    padding: 0 0 40px 0;

    @include respond(1150px) {
        padding: 0 60px 0 0;
    }
}

.about-top-right {
    flex: 1 1 224px;
    -ms-flex: 0 1 auto;
    position: relative;
    min-width: 224px;
    margin: 0 auto;
    max-width: 224px;
    img {
        display: block;
        border-radius: 5px;
    }
    @include respond($bp-about-stack) {
        margin: 0;
        max-width: 300px;
        min-width: 0;
    }
    @include respond($bp-a-top-stack) {
        flex: 0 0 224px;
    }
}

.top-cards {
    flex: 1 0 692px;
    display: flex;
    gap: 50px;
    flex-direction: column;

    @include respond($bp-about-stack) {
        flex-direction: row;
        gap: 10px;
    }
}

.namecard {
    position: absolute;
    background: #fff;
    left: 16px;
    right: 16px;
    bottom: -16px;
    padding: 8px 16px;
    border: 1px solid $color-primary;
}

.namecard-name {
    font-weight: $weight-bold;
    font-size: 1.25rem;
    line-height: calc(32 / 24);
    margin: 0 0 4px 0;
}

.namecard-title {
    font-weight: $weight-light;
    font-size: 0.9rem;
}

// -----------------------------------------------------------------------------
//! Main Grid
// -----------------------------------------------------------------------------
$bp-about-4: 1024px;
$bp-about-3: 768px;
$bp-about-2: 480px;

.about-section-title {
    text-align: center;
    padding: 0 32px;
    margin: 0 0 32px 0;
    @include respond($bp-a-top-stack) {
        margin: 0 0 64px 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    svg {
    }

    .section-title {
        font-size: 1.5rem;
        line-height: calc(32 / 24);
        margin: 24px 0 0 0;
        @include respond($bp-a-top-stack) {
            margin: 0 0 0 24px;
            padding: 6px 0 6px 24px;
            border-left: 2px solid $color-body;
        }
    }
}
.about-grid {
    padding: 0 32px;
    max-width: 1336px;
    margin: 0 auto;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.about-grid .bio {
    padding: 8px;
    width: 100%;
    flex: 0 0 100%;
    -ms-flex: 0 1 auto;
    @include respond($bp-about-2) {
        width: 50%;
        flex: 0 0 50%;
        -ms-flex: 0 1 auto;
    }
    @include respond($bp-about-3) {
        width: 33.3333333333%;
        flex: 0 0 33.3333333333%;
        -ms-flex: 0 1 auto;
    }
    @include respond($bp-about-4) {
        width: 25%;
        flex: 0 0 25%;
        -ms-flex: 0 1 auto;
        padding: 12px;
    }
}

// -----------------------------------------------------------------------------
//! Bios
// -----------------------------------------------------------------------------

.bio {
}

.bio-inner {
    height: 100%;
}

.flip-card {
    background-color: transparent;
    width: 100%;
    height: 0;
    padding-top: (480 / 400) * 100%;
    perspective: 1000px;
}

.flip-card-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-front {
    color: $color-body;
    border-radius: 4px;
    img {
        border-radius: 4px;
    }
}

.flip-card-back {
    background-color: $color-primary;
    color: #fff;
    transform: rotateY(180deg);
    overflow-y: auto;
    border-radius: 4px;
}

.flip-card-back-inner {
    padding: 16px;
}

.about-top-grid .flip-card-back-inner {
    @include respond($bp-lg) {
        padding: 40px;
    }
    .flip-card-stat-text {
        font-size: 1rem;
        line-height: calc(20 / 16);
    }
    .flip-card-stat {
        margin: 0 0 10px 0;
    }
    .flip-card-job {
        margin: 0 0 20px 0;
    }
    .flip-card-title {
        font-size: 1.25rem;
    }
}

.flip-card-title {
    font-size: 1.125rem;
    line-height: calc(24 / 20);
    font-weight: $weight-bold;
    margin: 0 0 4px 0;
}

.flip-card-job {
    font-size: 0.875rem;
    line-height: 16px;
    margin: 0 0 12px 0;
}

.flip-card-bio {
    font-size: 0.875rem;
    line-height: calc(24 / 14);
    margin: 0;
    @include respond($bp-lg) {
        font-size: 1.125rem;
        line-height: calc(32 / 18);
    }
}

.flip-card-stat {
    margin: 0 0 8px 0;
}

.flip-card-stat-title {
    font-size: 0.75rem;
    font-weight: $weight-bold;
    margin: 0 0 4px 0;
}

.flip-card-stat-text {
    font-size: 0.8125rem;
    line-height: calc(16 / 13);
    font-weight: $weight-reg;
}

// -----------------------------------------------------------------------------
//! Stats
// -----------------------------------------------------------------------------

.about-stats {
    max-width: 400px;
    padding: 0 32px;
    margin: 64px auto;
    @include respond($bp-sm) {
        margin: 128px auto 128px auto;
        max-width: 1000px;
    }
}

.about-stats-desktop {
    display: none;
    @include respond($bp-sm) {
        display: block;
    }
}

.about-stats-mobile {
    display: block;
    @include respond($bp-sm) {
        display: none;
    }
}


// -----------------------------------------------------------------------------
//! New About stuff
// -----------------------------------------------------------------------------

.page-about .main {
    padding-bottom: 0;
}

.about-join {
    padding: 0 0 80px 0;
    text-align: center;
    @include respond($bp-padding) {
        padding: 0 0 160px 0;
    }
    
    .container {
        max-width: 800px;
        padding: 0 24px;
        
        .section-title {
            margin: 0 0 40px 0;
        }
    }
}