.cutoutText {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    text-align: center;
}

.cutoutText-text {
    padding: 40px 24px 80px;
    background: #fff;
    font-weight: 600;

    mix-blend-mode: screen;
    font-size: 48px;
    @include respond($bp-md) {
        font-size: 60px;
    }
    @include respond($bp-lg) {
        font-size: 80px;
        padding: 80px 24px 160px;
    }
    @include respond($bp-xl) {
        font-size: 100px;
    }
}

.cutoutText-container {
    position: relative;
    width: 100%;
    height: 25vh;
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;

    &.is-large {
        height: auto;
        padding: 100px 0;
    }
    .text-video {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: relative;
        // z-index: $z_index-level1 + 2;
        // @include respond(480px, "down") {
        //     display: none;
        // }
    }

    .cutoutText-text {
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        background: #fff;
        height: 100%;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        font-weight: 600;
        font-size: 80px;
        // /* Mix Blend Mode does the all magic */
        mix-blend-mode: screen;
        // user-select: none;

        @include respond($bp-md) {
            font-size: 100px;
        }
    }
}

// -----------------------------------------------------------------------------
//! Highlight
// -----------------------------------------------------------------------------

.highlightText {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    text-align: center;
}

.highlightText-text {
    padding: 80px 24px 40px 24px;
    background: #fff;
    font-weight: 600;
    position: relative;

    font-size: 48px;
    @include respond($bp-md) {
        font-size: 60px;
    }
    @include respond($bp-lg) {
        font-size: 80px;
        padding: 160px 24px 80px;
    }
    @include respond($bp-xl) {
        font-size: 115px;
        line-height: 141px;
    }
}

.page-about .highlightText-text {
    padding-top: 0;
}

.highlightText-container {
    position: relative;
    width: 100%;
    max-width: 1388px;
    margin: 0 auto;
    overflow: hidden;
    padding: 0;
    &.is-block .highlightText-text {
        padding-bottom: 0;
    }
    .text-video {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        // z-index: $z_index-level1 + 2;
        // @include respond(480px, "down") {
        //     display: none;
        // }
    }
    .highlightText-normal {
        background: #fff;
        text-align: left;
        font-weight: 700;
        position: relative;
        font-size: 32px;
        @include respond(470px) {
            font-size: 48px;
        }
        @include respond(620px) {
            font-size: 64px;
        }
        @include respond($bp-md) {
            font-size: 80px;
        }
        @include respond($bp-lg) {
            font-size: 100px;
        }
        @include respond(1200px) {
            font-size: 115px;
        }
    }
    .highlightText-cutout-wrap {
        background: #fff;
        position: relative;
        overflow: hidden;
        display: block;
        position: relative;
        font-weight: 700;
        font-size: 32px;
        margin-top: 12px;
        @include respond(470px) {
            font-size: 48px;
            margin-top: 0;
        }
        @include respond(620px) {
            font-size: 64px;
        }

        @include respond($bp-md) {
            font-size: 80px;
        }
        @include respond($bp-lg) {
            font-size: 100px;
        }
        @include respond(1200px) {
            font-size: 115px;
            line-height: 141px;
        }
    }
    .highlightText-cutout {
        background: #fff;
        mix-blend-mode: screen;
        display: block;
        position: relative;
        &.is-centered {
            text-align: center;
        }
    }
}

.highlightText-subtitle {
    font-size: 18px;
    line-height: 32px;
    margin: 32px 0 0 0;
    background: #fff;
    //max-width: 800px;
    font-weight: 400;
    font-weight: 600;
    @include respond($bp-padding) {
        font-size: 24px;
        line-height: 40px;
    }
}

.home .highlightText-container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.home .highlightText-container .down-arrow {
    position: absolute;
    bottom: -24px;
    left: 234px;
    @include respond(470px) {
        left: 367px;
    }

    @include respond(620px) {
        left: 501px;
    }
    @include respond($bp-md) {
        left: 636px;
    }
    @include respond($bp-lg) {
        left: 814px;
        bottom: 0;
    }
    @include respond(1200px) {
        left: 936px;
    }
}

.page-strategy-creative-services .highlightText-container,
.page-creative-design .highlightText-container,
.page-creative-design .highlightText-container, {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    @include respond($bp-md) {
        height: 70vh;
    }
    .highlightText-text {
        padding: 0px 0px 80px 0px;
        @include respond($bp-md) {
            padding: 80px 0;
        }
    }
}

.page-strategy-creative-services .solutionsWork,
.page-creative-design .solutionsWork, {
    padding-top: 0;
}
