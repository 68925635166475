.logos-wrap {
	//background:$color-offwhite;
	padding: 40px 0;
	@include respond($bp-md) {
		padding: 64px 0px 112px 0px;	
	}
	.section-title {
		font-size: 24px;
		line-height: 30px;
		@include respond($bp-md) {
			font-size: 32px;
			line-height: 42px;
			font-weight: 700;
		}
	}
}
.logos {
	max-width: 1360px;
	margin:24px auto 0 auto;
	padding:0;
	position: relative;
	height: 420px;
	width: calc(100% - 64px);
	@include respond(540px) {
		height: 280px;	
		margin:64px auto 0 auto;
	}
	@include respond(960px) {
		height: 140px;	
	}
}

.logos-item {
	position: absolute;
	top: 0;
	width: 20%;
	height: 140px;
	//border-top: 1px solid rgba(0,0,0,0.2);
	border-left: 1px solid rgba(0,0,0,0.2);
	//border-bottom: 1px solid rgba(0,0,0,0.2);
	padding: 40px 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 0 0 auto;
	-ms-flex: 0 1 auto;
	background:#fff;
	z-index: 1;
	opacity: 0;
	transition: opacity 0.4s ease;
	img {
		max-height: 128px;
		width:auto;
		display: block;
	}
	&.column-1 {
		left: 0;
		border-left: none;
	}
	&.column-2 {
		left: 20%;
	}
	&.column-3 {
		left: 40%;
	}
	&.column-4 {
		left: 60%;
	}
	&.column-5 {
		left: 80%;
	}
	 
	
	@include respond(960px, "down") {
		width: 33.3333333333%;
		border-left: none;
		&.column-1 {
			left: 0;
		}
		&.column-2 {
			left: 33.3333333333%;
		}
		&.column-3 {
			left: calc(33.3333333333 * 2%);
		}
		&.column-4 {
			top: 140px;
			left: 16.6666666%;		
			border-left: none;
		}
		&.column-5 {
			top: 140px;
			left: 50%;
		}
		
	}
	
	@include respond(540px, "down") {
		width: 50%;
		&.column-1 {
			left: 0;
		}
		&.column-2 {
			left: 50%;
		}
		&.column-3 {
			top: 140px;
			left: 0;
			border-left: none;
		}
		&.column-4 {
			top: 140px;
			left: 50%;
		}
		&.column-5 {
			top: 280px;
			left: 25%;
 			border-left: none;
			 
		}
		
	}
	
}


a.logos-item {
	transition: opacity 0.2s ease;
	&:hover {
		opacity: 0.7;
	}
}



.logos-item.is-active {
	opacity: 1;
}