$bp-tabs-stack: 1000px;

.tabs {
	margin:80px auto;
	padding:0 16px;

}

.tabs-wrap {
	max-width: 1400px;
}

.tabs-nav {
	display: flex;
	align-items: stretch;
	justify-content: center;
	flex-wrap: wrap;
	background:$color-primary;
}

.tabs-nav-button {
	border-top:8px solid $color-primary;
	border-bottom:8px solid transparent;
	background: transparent;
	line-height: 40px;
	font-size: 1.125em;
	font-weight: $weight-reg;
	margin:0 8px 0 8px;
	cursor: pointer;
	padding:8px 24px 0 24px;
	color:#fff;
	&.is-active {
		//border-bottom:2px solid #fff;
		color: $color-primary;
		background:#fff;
	}
}

.tabs-container {
	border: 2px solid $color-primary;
	border-top:none;
	padding:16px;
	@include respond(600px) {
		padding:48px;		
	}
}

.tabs-panel {
	display: none;
	&.is-active {
		display: block;
	}
}

.tabs-panel-inner {
	@include respond($bp-tabs-stack) {
		display: flex;
		align-items: flex-start;
		justify-content: center;		
	}
}

.tabs-panel-text {
	width:100%;
	max-width:100%;
	flex: 1 1 50%;
	-ms-flex: 0 1 auto;	
}

.tabs-panel-monitor {
	width: 100%;
	max-width: 936px;
	display: block;
}

.tabs-panel-monitor-screen {
	position: relative;
	max-width: 675px;
	width: 100%;
	margin: 32px auto 0 auto;
	img {
		display: block;
	}
}

.tabs-panel-monitor-graphic {
	position: absolute;
	top:4.630969609%;
	left:50%;
	transform: translateX(-50%);
	width: 91.85185185%;
	z-index: $z_index-level4;
	overflow: hidden;
}

.tabs-panel-monitor-graphic-inner {
	width: 100%;
	padding-top: (413 / 735) * 100%;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	position: relative;
	video {
		object-fit: cover;
		position: absolute;
		top:0;
		left:0;
		width: 100%;
	}
}


.tabs-panel-text p,
.tabs-panel-monitor-text p {
	font-size: 1.125em;
	line-height: 1.5;
	&:last-of-type {
		margin-bottom:0;
	}
	text-align: center;
}

.tabs-panel-monitor-text {
	font-size: 18px;
	line-height: 32px;
	text-align: center;
}

.tabs-panel-media {
	width:100%;

	-ms-flex: 0 1 auto;
	padding:40px 0 0 0;
	@include respond($bp-tabs-stack) {
		padding:0 0 0 40px;
		max-width:600px;
		flex: 0 0 50%;
	}
	@include respond(1200px) {
		padding:0 0 0 64px;	
	}
	
	.tabs-panel-media-video {
		padding-bottom: 56.25%;
		height:0;
		position: relative;
	}
	iframe,
	object,
	embed {
		position: absolute; 
		top: 0; 
		left: 0; 
		width: 100%; 
		height: 100%; 
	}
}

.tabs-panel-title {
	font-size: 24px;
	line-height: calc(32/24);
	font-weight: $weight-bold;
	margin:0 0 16px 0;
	@include respond(620px) {
		display: none;
	}
}

